import React, {useEffect, useState, useContext} from "react";
import {Layout, Menu, Image} from 'antd';
import {useNavigate} from "react-router-dom";

const {Sider} = Layout;


const CVSidebar = (props) => {

    const sidebarWidthCollapsed = 67  // 67
    const sidebarWidthExpanded = 145

    return (
        <div style={{
            display: 'flex',
            height: "100vh",
        }}>
            <Sider
                collapsible={true}
                defaultCollapsed={true}
                width={sidebarWidthExpanded}
                collapsedWidth={sidebarWidthCollapsed}
                style={{
                    height: "100vh",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "100%"
                }}>
                    <div style={{
                        padding: 16,
                        width: '100%',
                        display: "flex",
                        justifyContent: 'center'
                    }}>
                        <Image src={props.logo} preview={false} style={{height: "32px", width: "auto"}}/>
                    </div>
                    <div style={{
                        // marginTop: 64,
                    }}>
                        <Menu
                            theme={"dark"}
                            className="custom-menu"
                            defaultSelectedKeys={[props.selectedKey]}
                            items={props.menuItems}
                        />
                    </div>
                    <div style={{
                        // verticalAlign: 'bottom'
                    }}>
                        <Menu
                            theme="dark"
                            defaultSelectedKeys={[]}
                            style={{
                                marginTop: 'auto',
                            }}
                            onClick={props.onClickSettings}
                            items={props.settingsItems}
                        />
                    </div>
                </div>
            </Sider>
        </div>
    );
}


export default CVSidebar;