import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "react-oidc-context";

export const AuthCallback = () => {

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      const path = auth.user?.state || '/';
      navigate(path);
    }
  }, [auth.isAuthenticated]);

}

