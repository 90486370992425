import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Form, Input, Space, Select, Tooltip, DatePicker} from 'antd';
import {useForm} from 'antd/lib/form/Form';

const {Option} = Select;

const FilterForm = (props) => {

    const [form] = useForm();

    const selectOptions = props.conditionOptions;
    const booleanFields = ["alert", "reactive", "matching", "proactive"];

    function findExample(value) {
        for (let i = 0; i < props.fieldOptions.length; i++) {
            if (props.fieldOptions[i].value === value) {
                return props.fieldOptions[i].example || null;
            }
        }
        return null;
    }

    const getInputComponent = (fields, name) => {

        const formValues = form.getFieldsValue();
        const fieldValue = formValues.filters?.[name]?.field || 'undefined';

        const placeholderField = findExample(fieldValue)

        if (booleanFields.includes(fieldValue)) {
            return (
                <Select placeholder={`Select value for ${fieldValue}`}>
                    <Option value={'true'}>true</Option>
                    <Option value={'false'}>false</Option>
                </Select>
            );
        } else if (fieldValue === 'event_ts') {
            return <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"/>;
        } else if (fieldValue === 'assignment_status') {
            return (
                <Select placeholder={`Select value for ${fieldValue}`}>
                    <Option value={'matched'}>matched</Option>
                    <Option value={'no_match'}>no_match</Option>
                    <Option value={'in_analysis'}>in_analysis</Option>
                    <Option value={'reactive'}>reactive</Option>
                    <Option value={'proactive'}>proactive</Option>
                </Select>
            );
        } else {
            return <Input placeholder={placeholderField ? placeholderField : `Input value for ${fieldValue}`}/>;
        }
    };

    return (
        <>
            <Form
                form={form}
                name="dynamic_form_nest_item"
                onFinish={props.onFinish}
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
            >
                <Form.List name="filters">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'field']}
                                        style={{marginBottom: "4px", marginTop: "4px", width: "180px"}}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing Field',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Field"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            onChange={(value) => {
                                                const newFields = form.getFieldsValue().filters || [];
                                                newFields[name] = {...newFields[name], field: value};
                                                form.setFieldsValue({filters: newFields});
                                            }}
                                            options={props.fieldOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'condition']}
                                        style={{marginBottom: "4px", marginTop: "4px", width: "120px"}}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing Condition',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                        >
                                            {selectOptions.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    <Tooltip title={option.tooltipContent}>
                                                        <span>{option.label}</span>
                                                    </Tooltip>
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        style={{marginBottom: "4px", marginTop: "4px", width: "180px"}}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing Value',
                                            },
                                        ]}
                                    >
                                        {getInputComponent(fields, name)}
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)}/>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item style={{marginBottom: "4px", marginTop: "4px"}}>
                    <Button type="primary" htmlType="submit" style={{width: "110px"}}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default FilterForm;
