import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal, Image, Card
} from 'antd';
import axiosInstance from "../../services/axios";

import {FaCarCrash, FaFile, FaImage, FaInfo, FaSearch} from "react-icons/fa";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const SearchInfo = (props) => {

    const infoData = props.infoData
    const plotData = props.plotData
    const searchValue = props.searchValue
    const eventID = props.eventID
    const UAID = props.UAID
    const customerId = props.customerId
    const searchType = props.searchType

    return (
        <Card
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: 8,
                minHeight: 260,
            }}
            styles={{
                body: {
                    padding: '4px',
                    display: 'flex',
                    flexDirection: "column",
                    flex: "1 1 auto"
                }
            }}
        >
            <Row>
                <Col span={24}>
                    <Descriptions bordered size={'middle'}>
                        <Descriptions.Item label="Customer">{infoData?.customer}</Descriptions.Item>
                        {/*<Descriptions.Item label="Status">{infoData?.anomaly_type}</Descriptions.Item>*/}
                    </Descriptions>
                    <Descriptions bordered size={'middle'} column={1}>
                        <Descriptions.Item label="Vehicle ID">{infoData?.vehicle_id}</Descriptions.Item>
                        <Descriptions.Item label="VTI">{infoData?.vehicle_type}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered size={'middle'}>
                        <Descriptions.Item label="Time">{infoData?.time}</Descriptions.Item>
                        <Descriptions.Item label="Date">{infoData?.date}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row style={{marginTop: 16}}>
                <Popover
                    title={'Event Info'}
                    trigger="click"
                    placement="bottom"
                    content={
                        <div style={{maxWidth: 500, whiteSpace: 'normal', wordWrap: 'break-word'}}>
                            {props.infoData?.event_info_text}
                        </div>
                    }
                >
                    <Button style={{marginLeft: 0}} size={'medium'} icon={<FaCarCrash/>}
                            disabled={!props.infoData?.event_info_text}/>
                </Popover>
                <Popover
                    title={'Inspection Result'}
                    trigger="click"
                    placement="bottom"
                    content={
                        <div style={{maxWidth: 500, whiteSpace: 'normal', wordWrap: 'break-word'}}>
                            {props.infoData?.inspection_result}
                        </div>
                    }
                >
                    <Button size={'medium'} icon={<FaSearch/>} disabled={!props.infoData?.inspection_result}/>
                </Popover>
                <Button type="default"
                        href={`https://webapp.carvaloo.com/${searchType === 'inspection' ? 'inspektion' : 'ereignisse'}/${searchType === 'inspection' ? encodeURIComponent(searchValue) : encodeURIComponent(eventID)}?organization=${encodeURIComponent(customerId)}`}
                        target="_blank" icon={<FaFile/>}
                >
                    {'\xa0'}Webapp
                </Button>
                {UAID &&
                    <Button type="default"
                            href={`https://matching.carvaloo.com/anomaly_tool/${UAID}`}
                            target="_blank" icon={<FaInfo/>}
                    >
                        {'\xa0'}Anomaly
                    </Button>
                }
                {UAID &&
                    <Popover
                        title={'Anomaly Plot'}
                        trigger="click"
                        placement="bottom"
                        content={
                            <Image
                                style={{height: 400}}
                                src={plotData?.uaid_image}
                                name={'uaid_image'}
                            />
                        }
                    >
                        <Button size={'medium'} icon={<FaImage/>}/>
                    </Popover>
                }
            </Row>
            {/*<Row style={{marginTop: 24}}>*/}
            {/*    <Col span={16}>*/}
            {/*        <div style={{*/}
            {/*            display: "flex",*/}
            {/*            // justifyContent: "center",*/}
            {/*            // alignItems: "center",*/}
            {/*        }}>*/}
            {/*            <Image*/}
            {/*                style={{height: "100px", marginLeft: 32}}*/}
            {/*                src={plotData?.uaid_image}*/}
            {/*                name={'uaid_image'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*    <Col span={8}>*/}
            {/*        <Row>*/}

            {/*            <div style={{*/}
            {/*                height: "100px",*/}
            {/*                display: "flex",*/}
            {/*                // justifyContent: "center",*/}
            {/*                // alignItems: "center",*/}
            {/*            }}>*/}
            {/*                <Image*/}
            {/*                    src={plotData?.direction_image}*/}
            {/*                    name={'direction_image'}*/}
            {/*                    style={{height: 100}}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </Row>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Card>
    );
};

export default SearchInfo;