import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Dropdown,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    ConfigProvider,
    Divider,
    Popover,
    Checkbox,
    Tag,
    Collapse,
    InputNumber, Descriptions, Image, Timeline, Tooltip, Radio, Table, Tabs, Alert, DatePicker
} from 'antd';
import Icon, {
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    FilterOutlined,
    PictureOutlined,
    TagOutlined,
    EllipsisOutlined,
    DatabaseOutlined,
    ReloadOutlined,
    ZoomOutOutlined,
    EditFilled,
    ToolOutlined,
    CarOutlined,
    CheckOutlined,
    CloseOutlined,
    SearchOutlined,
    WarningOutlined, CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";


import axiosInstance from "services/axios";

import CarPartLabels from "./CarPartLabels";
import ImageLabels from "./ImageLabels";
import EventLabels from "./EventLabels";
import DamageReportTable from "./DamageReportTable";
import MultiImageRenderer from "./MultiImageRenderer";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import MultiImageRendererV2 from "../MultiImageRendererV2/MultiImageRendererV2";
import {useNavigate} from "react-router-dom";
import {getColumnDefs} from "../MonitoringDashboard/MonitoringDashboardColumns";
import {FaCarCrash, FaFile, FaInfo, FaSearch, FaStickyNote} from "react-icons/fa";
import SearchInfo from "./SearchInfo";
import FeedbackInfo from "./FeedbackInfo";
import EventList from "./EventList";
import VehicleImages from "./VehicleImages"
import dayjs from "dayjs";
import moment from "moment";
import InspectionInfo from "./InspectionInfo";
import PredictionInfo from "./PredictionsInfo";
import HistoryInfo from "./HistoryInfo";

const {Search} = Input;
const {Text, Paragraph, Link} = Typography;
const {Option} = Select;
const {RangePicker} = DatePicker;

const statusStyles = {
    pending: {
        backgroundColor: '#ffffff',
        textAlign: 'center',
        fontSize: '16px',
        alignItems: 'center',
    },
    todo: {
        backgroundColor: '#ff9696',
        textAlign: 'center',
        fontSize: '16px',
        alignItems: 'center',
    },
    in_progress: {
        backgroundColor: '#59c5ff',
        textAlign: 'center',
        fontSize: '16px',
        alignItems: 'center',
    },
    review: {
        backgroundColor: '#eee0ac',
        textAlign: 'center',
        fontSize: '16px',
        alignItems: 'center',
    },
    done: {
        backgroundColor: '#97cf88',
        textAlign: 'center',
        fontSize: '16px',
        alignItems: 'center',
    },
};

const ImageTool = (props) => {

    const [showEventStatus, setShowEventStatus] = useState(true);
    const [eventStatusReload, setEventStatusReload] = useState(0);
    const [searchValue, setSearchValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('1');
    const [activeLabelTab, setActiveLabelTab] = useState('1');
    const [labelTarget, setLabelTarget] = useState(null);
    const [labelData, setLabelData] = useState(null);
    const [labelReload, setLabelReload] = useState(0);
    const [eventID, setEventID] = useState(null);
    const [UAID, setUAID] = useState(null);
    const [searchType, setSearchType] = useState(null);
    const [assignmentStatus, setAssignmentStatus] = useState('all');

    const [allPredictions, setAllPredictions] = useState(false);
    const [allImages, setAllImages] = useState(false);
    const [customerSelect, setCustomerSelect] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [defaultChecked, setDefaultChecked] = useState(true);

    const [infoData, setInfoData] = useState(null);
    const [plotData, setPlotData] = useState(null);
    const [showPredictionModal, setShowPredictionModal] = useState(false);

    const [feedbackHelper, setFeedbackHelper] = useState(null);
    const [feedbackImages, setFeedbackImages] = useState(null);
    const [feedbackImagesAI, setFeedbackImagesAI] = useState(null);
    const [feedbackImagesLabels, setFeedbackImagesLabels] = useState(null);
    const [labelFeedback, setLabelFeedback] = useState(false);

    const [selectedFeedbacks, setSelectedFeedbacks] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [inspectionData, setInspectionData] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [predictionData, setPredictionData] = useState(null);
    const [AIData, setAIData] = useState([]);

    const [queryStartDate, setQueryStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [queryEndDate, setQueryEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [countData, setCountData] = useState(null);
    const [statusFilter, setStatusFilter] = useState('all');
    const [statusValue, setStatusValue] = useState('pending');
    const [statusValueReload, setStatusValueReload] = useState(0);
    const [claimsReady, setClaimsReady] = useState(false);
    const [showClaimsReady, setShowClaimsReady] = useState(false);

    const containerRefLeft = useRef(null); // Ref to the container element
    const containerRefRight = useRef(null); // Ref to the container element

    const rangePresets = [
        {label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()]},
        {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
        {label: 'Last 180 Days', value: [dayjs().add(-180, 'd'), dayjs()]},
    ];

    useEffect(() => {
        document.title = 'Image Tool';
        const getParams = new URLSearchParams(window.location.search);

        // extract UAID from URL path
        const path = window.location.pathname;

        const customer = getParams.get('customer');
        if (customer) {
            const customerArray = customer.includes(",") ? customer.split(",") : [customer];
            setCustomerSelect(customerArray);  // Update the multi-select component's value
            setCustomer(customerArray);        // Update other state if needed
        }

        const regex = /image_tool\/([^/?]+)/;
        const match = path.match(regex);
        if (match) {
            setSearchValue(match[1])
            setShowEventStatus(false)
        }

    }, []);

    useEffect(() => {
        axiosInstance.post(
            '/api/sidebar/customers')
            .then(res => {
                const customerList = res.data.customers
                setCustomerOptions(customerList.map(str => ({
                    value: str,    // Assign the string to the value property
                    label: str     // Assign the string to the label property
                })))
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in get customers',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }, []);

    useEffect(() => {
        if (infoData && customer) {
            axiosInstance.post(
                '/api/image_tool/event_status/current',
                {
                    customer: customer,
                    event_id: infoData?.anomaly_type !== 'inspection' ? searchValue : null,
                    inspection_id: infoData?.anomaly_type === 'inspection' ? searchValue : null,
                })
                .then(res => {
                    setStatusValue(res.data.status)
                    setClaimsReady(res.data.claims_ready)
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in getStatus',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        }
    }, [infoData, customer, statusValueReload, labelReload]);

    useEffect(() => {
        if (labelTarget && customer) {
            axiosInstance.post(
                '/api/image_tool/labels',
                {
                    customer: customer,
                    search_value: labelTarget,
                })
                .then(res => {
                    console.log(res.data)
                    setLabelData(res.data)
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in getLabels',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        }
    }, [labelTarget, customer, labelReload]);

    useEffect(() => {
        if (detailData?.length === 1) {
            setLabelTarget(searchValue)
            setLabelFeedback(false)
        } else if (selectedFeedbacks.length === 1 && !defaultChecked) {
            setLabelTarget(selectedFeedbacks[0])
            setLabelFeedback(true)
        } else {
            setLabelTarget(searchValue)
            setLabelFeedback(false)
        }
    }, [selectedFeedbacks]);


    useEffect(() => {
        if (searchValue !== null) {
            if (customer) {
                getPredictions()
            }
        }
    }, [labelReload, allPredictions, labelFeedback])

    useEffect(() => {
        if (UAID) {
            if (customer) {
                getPlots()
            }
        }
        if (UAID) {
            setLabelTarget(UAID)
        }
    }, [UAID])

    useEffect(() => {
        if (searchValue !== null) {
            if (searchValue.includes("UAID")) {
                getInfo()
            } else if (customer !== null) {
                getInfo()
            }
            setLabelTarget(searchValue)
        } else {
            setInfoData(null)
            setLabelTarget(null)
        }
    }, [searchValue, allImages])


    const getInfo = () => {
        axiosInstance.post(
            '/api/image_tool/info',
            {
                search_value: searchValue,
                customer: customerSelect,
            })
            .then(res => {
                console.log(res.data)
                setInfoData(res.data)
                setCustomer(res.data.customer)
                setCustomerId(res.data.customer_id)
                setEventID(res.data.event_id)
                setUAID(res.data.uaid)
                setSearchType(res.data.search_type)
                navigate(`/image_tool/${searchValue}?customer=${res.data.customer}`);
                setCustomerSelect([res.data.customer])
            })
            .catch(error => {
                    console.log(error);
                    setInfoData(null)
                    notification['error']({
                        message: 'Error in get AnomalyInfo',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    const getPlots = () => {
        axiosInstance.post(
            '/api/anomaly_tool/plots',
            {
                UAID: UAID,
            })
            .then(res => {
                console.log(res.data)
                setPlotData(res.data)
            })
            .catch(error => {
                console.log(error);
                setPlotData(null)
                notification['error']({
                    message: 'Error in get AnomalyPlots',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (searchValue !== null && customer) {
            getImages()
            if (infoData?.anomaly_type === 'inspection') {
                getInspection()
            } else {
                getFeedbacks()
            }
            getPredictions()
        }
    }, [infoData])

    const getImages = () => {
        axiosInstance.post(
            '/api/image_tool/images',
            {
                search_value: searchValue,
                all_images: allImages,
                customer: customer,
            })
            .then(res => {
                console.log(res.data)

                // res.data.vehicle_images.push(res.data.vehicle_images[0])
                // res.data.vehicle_images.push(res.data.vehicle_images[0])
                // res.data.vehicle_images.push(res.data.vehicle_images[0])
                // res.data.vehicle_images.push(res.data.vehicle_images[0])

                setCustomerId(res.data.customer_id)

                setInspectionData(res.data.vehicle_images)

                setFeedbackHelper(res.data.feedback_images)
                const feedback_images = res.data.feedback_images;
                const uriSasFeedbackImages = feedback_images.map(image => image.uri_sas);
                setFeedbackImages(uriSasFeedbackImages);
                const uriSasFeedbackImagesAI = feedback_images.map(image => image.ai_image);
                setFeedbackImagesAI(uriSasFeedbackImagesAI)
                const labelsFeedbackImages = feedback_images.map(image => image.id);
                setFeedbackImagesLabels(labelsFeedbackImages)
                const allDocIds = feedback_images.map(image => image.id); // Flatten all doc_ids into a single array
                setSelectedDocs(allDocIds);
            })
            .catch(error => {
                console.log(error);
                setInspectionData(null)
                notification['error']({
                    message: 'Error in get AnomalyImages',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const getInspection = () => {
        axiosInstance.post(
            '/api/image_tool/inspection',
            {
                search_value: searchValue,
                customer: customer,
            })
            .then(res => {

                let data = res.data.data
                setDetailData(data)
                // setSelectedFeedbacks(res.data.data.map(feedback => feedback.uuid));
                // setDetailData([])
                console.log(res.data.data)
            })
            .catch(error => {
                    console.log(error);
                    setDetailData(null)
                    notification['error']({
                        message: 'Error in get Inspection Details',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    const getFeedbacks = () => {
        axiosInstance.post(
            '/api/image_tool/user_feedbacks',
            {
                search_value: searchValue,
                customer: customer,
            })
            .then(res => {
                setDetailData(res.data.data)
                setSelectedFeedbacks(res.data.data.map(feedback => feedback.uuid));
                // setDetailData([])
                console.log(res.data.data)
            })
            .catch(error => {
                    console.log(error);
                    setDetailData(null)
                    notification['error']({
                        message: 'Error in get Feedback Details',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    const getPredictions = () => {
        axiosInstance.post(
            '/api/image_tool/image_ai_predictions',
            {
                search_value: searchValue,
                event_id: eventID,
                customer: customer,
                type: searchType,
                show_all: allPredictions,
                feedback: labelFeedback ? labelTarget : null,
            })
            .then(res => {
                setPredictionData(res.data.damages)
                setAIData(res.data.ai_info)
                console.log(res.data)
            })
            .catch(error => {
                    console.log(error);
                    setPredictionData(null)
                    notification['error']({
                        message: 'Error in get AnomalyAIPredictions',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    const onSearch = (value) => {
        if (value) {
            const valueStr = value.trim()
            const parts = valueStr.split("-");
            if (customerSelect && !valueStr.includes("UAID-")) {
                setCustomer(customerSelect)
            } else {
                setCustomer(null)
            }

            if (valueStr.includes("UAID") && parts.length === 3 && parts[2].length === 12) {
                setShowEventStatus(false)
                setSearchValue(valueStr)
                setInfoData(null)
                setPredictionData(null)
                setFeedbackImages(null)
                setFeedbackImagesAI(null)
                setInspectionData(null)
                setLabelData(null)
                setPlotData(null)
                navigate(`/image_tool/${valueStr}`);
            } else if (customerSelect !== null) {
                setShowEventStatus(false)
                setSearchValue(valueStr)
                setInfoData(null)
                setPredictionData(null)
                setFeedbackImages(null)
                setFeedbackImagesAI(null)
                setInspectionData(null)
                setLabelData(null)
                setPlotData(null)
                navigate(`/image_tool/${valueStr}?customer=${customerSelect}`);
            } else if (customerSelect === null && value) {
                notification['warning']({
                    message: 'Please set a customer if not searching for a UAID',
                });
                navigate(`/image_tool/${valueStr}`);
            } else {
                setSearchValue(null)
                setShowEventStatus(true)
                navigate('/image_tool');
            }
        } else {
            setSearchValue(null)
            setShowEventStatus(true)
            navigate('/image_tool');
            setEventStatusReload(prevCounter => prevCounter + 1);
        }
    }

    const onSelectFeedback = (uuid, isChecked) => {
        const item = detailData.find(i => i.uuid === uuid);
        if (!item) return; // Exit if no item is found

        setSelectedDocs(prevSelectedDocs => {
            let updatedSelectedDocs = [...prevSelectedDocs];

            if (isChecked) {
                setSelectedFeedbacks(prevArr => [...prevArr, uuid])
                item.doc_ids?.forEach(docId => {
                    if (!updatedSelectedDocs.includes(docId)) {
                        updatedSelectedDocs.push(docId);
                    }
                });
            } else {
                setDefaultChecked(false)
                // Remove docIds from selectedDocs
                updatedSelectedDocs = updatedSelectedDocs.filter(docId => !item.doc_ids?.includes(docId));
                setSelectedFeedbacks(prevArr => prevArr.filter(item => item !== uuid))
            }

            setFeedbackImages(feedbackHelper.filter(image => updatedSelectedDocs.includes(image.id)).map(image => image.uri_sas))
            setFeedbackImagesAI(feedbackHelper.filter(image => updatedSelectedDocs.includes(image.id)).map(image => image.ai_image))
            setFeedbackImagesLabels(feedbackHelper.filter(image => updatedSelectedDocs.includes(image.id)).map(image => image.id))

            return updatedSelectedDocs;
        });
    }

    const onTabSelect = (value) => {
        setActiveTab(value)
    }

    const onLabelTabSelect = (value) => {
        setActiveLabelTab(value)
    }

    const handleUpdate = (id, id_label, state, column, value) => {

        axiosInstance.post(
            '/api/image_tool/labels/doc_id',
            {
                customer: customer,
                row_id: id,
                row_id_label: id_label,
                column: column,
                value: value
            })
            .then(res => {
                getPredictions()
                getImages()
                setLabelReload(prevCounter => prevCounter + 1);
            })
            .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in updating DocID',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }


    const handleUpdateStatus = (value) => {

        const data = {
            customer: customer,
            event_id: infoData?.anomaly_type !== 'inspection' ? searchValue : null,
            inspection_id: infoData?.anomaly_type === 'inspection' ? searchValue : null,
            status: value,
            comment: null,
        };

        const payload = {
            data: data,
            update_editor: true
        }

        axiosInstance.post('/api/image_tool/event_status/update', payload)
            .then(response => {
                //params.success(response.values);
                setStatusValueReload(prevCounter => prevCounter + 1);
                console.log('Success updating entry');
                // console.log(response);
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error updating', description: error.message});
            })
    }

    const onClickClaimsReady = () => {

        const payload = {
            customer: customer,
            event_id: infoData?.anomaly_type !== 'inspection' ? searchValue : null,
            inspection_id: infoData?.anomaly_type === 'inspection' ? searchValue : null,
            vehicle_uuid: infoData.vehicle_uuid,
        };

        axiosInstance.post('/api/image_tool/event_status/claims_ready', payload)
            .then(response => {
                //params.success(response.values);
                setStatusValueReload(prevCounter => prevCounter + 1);
                console.log('Success setting claims ready for entry');
                notification['success']({message: 'Success setting claims ready'});
                setShowClaimsReady(false)
                // console.log(response);
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error setting claims ready', description: error.message});
            })
    }

    const onChangeTimeSwitch = (checked) => {
        setAllImages(checked);
    }

    const onChangePredictionsSwitch = (checked) => {
        setAllPredictions(checked);
    }

    const handleSelectChange = (value) => {
        console.log(value)
        setCustomerSelect(value)
        navigate(`/image_tool?customer=${value}`);
    };

    const onChangeDateRange = (value) => {
        if (value) {
            setQueryStartDate(value[0].format('YYYY-MM-DD'))
            setQueryEndDate(value[1].format('YYYY-MM-DD'))
        } else {
            setQueryStartDate("2000-01-01")
            setQueryEndDate("2100-01-01")
        }
    }

    const onChangeFilter = (value) => {
        setStatusFilter(value)
    }

    const handleCancel = () => {
        setShowClaimsReady(false)
    };

    const onSetClaimsReady = () => {
        setShowClaimsReady(true)
    }

    return (<>
            <Modal
                title="Set Claims Ready"
                open={showClaimsReady}
                onCancel={handleCancel}
                width={450}
                footer={null}
            >
                <Row justify="center" style={{marginBottom: 8}}>
                    <p>Are you sure you want to set this event as claims ready?</p>
                </Row>
                <Row style={{marginBottom: 16}}>
                    <WarningOutlined style={{color: 'red', fontSize: 20}}/>
                    <Text style={{color: 'red', fontSize: 16, paddingLeft: 30, paddingRight: 30}}>
                        This will set the event as visible for the customer and result in API and email notifications.
                    </Text>
                </Row>
                <Row justify="center">
                    <Button type="primary" onClick={onClickClaimsReady} style={{marginRight: 8}}>
                        Yes
                    </Button>
                    <Button onClick={handleCancel}>
                        No
                    </Button>
                </Row>
            </Modal>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                styles={{body: {backdropFilter: 'blur(3px)'}}}
            >
                <div>
                    <Spin style={{marginRight: 20}} size="large"/>
                    Loading
                </div>
            </Modal>
            <Modal
                open={showPredictionModal}
                closable={true}
                footer={null}
            >
                <div>
                    {selectedImage}
                </div>
            </Modal>
            <Card
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    marginBottom: 8
                }}
                styles={{
                    body: {
                        padding: '4px',
                        display: 'flex',
                        flexDirection: "column",
                        flex: "1 1 auto"
                    }
                }}
            >
                <Row justify="space-between">
                    <div>
                        <Select
                            mode="multiple"                  // Enable multiple selection
                            placeholder="Customer"
                            value={customerSelect}
                            maxTagCount={2}
                            onChange={(value) => handleSelectChange(value)}
                            style={{width: 300, marginTop: 4}}
                            optionFilterProp="children"      // Filter options based on their labels
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {customerOptions.map(customer => (
                                <Option key={customer.value} value={customer.value}>
                                    <Checkbox checked={customerSelect?.includes(customer.value)}
                                              style={{marginRight: 4}}/>
                                    {customer.label}
                                </Option>
                            ))}
                        </Select>
                        <Search
                            placeholder="UAID, Event or Inspection"
                            onSearch={onSearch}
                            allowClear={true}
                            style={{
                                width: 275,
                                marginTop: 4,
                                marginBottom: 4,
                                marginLeft: 16
                            }}
                        />
                        {!showEventStatus &&
                            <>
                                {/*<Text style={{marginTop: '8px', marginLeft: 16, marginRight: 8}}>*/}
                                {/*    Status*/}
                                {/*</Text>*/}
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Select: {
                                                selectorBg: statusStyles[statusValue],
                                            },
                                        }
                                    }}
                                >
                                    <Select
                                        value={statusValue}
                                        onChange={handleUpdateStatus}
                                        style={{
                                            marginLeft: 16,
                                            width: 120,
                                            ...statusStyles[statusValue]  // Apply the selected status style
                                        }}
                                    >
                                        <Option value="pending" style={statusStyles.pending}>pending</Option>
                                        <Option value="todo" style={statusStyles.todo}>todo</Option>
                                        <Option value="in_progress"
                                                style={statusStyles.in_progress}>in_progress</Option>
                                        <Option value="review" style={statusStyles.review}>review</Option>
                                        <Option value="done" style={statusStyles.done}>done</Option>
                                    </Select>
                                </ConfigProvider>
                            </>
                        }
                        {!showEventStatus &&
                            <>
                                <Text style={{marginTop: '8px', marginLeft: 16}}>
                                    Claims Ready
                                </Text>
                                <Switch
                                    style={{marginLeft: 8, marginTop: -4}}
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    checked={claimsReady}
                                    onChange={onSetClaimsReady}
                                    disabled={statusValue !== 'done' || claimsReady === true}
                                />
                            </>
                        }

                        {showEventStatus &&
                            <RangePicker
                                // open={isOpen}
                                // onOpenChange={(open) => setIsOpen(open)}
                                // onSelect={handleSelect}
                                size={'small'}
                                style={{height: 32, marginTop: 4, marginLeft: 16}}
                                defaultValue={[dayjs().add(-7, 'd'), dayjs()]}
                                defaultPickerValue={[dayjs().add(-7, 'd'), dayjs()]}
                                presets={rangePresets}
                                onChange={onChangeDateRange}
                            />
                        }
                        {showEventStatus &&
                            <Radio.Group
                                optionType="button"
                                size={'medium'}
                                value={assignmentStatus}
                                style={{marginTop: 4, marginLeft: 16}}
                                onChange={(e) => setAssignmentStatus(e.target.value)}
                            >
                                <Radio key={'1'} value={'all'}>
                                    all
                                </Radio>
                                <Radio key={'2'} value={'proactive'}>
                                    proactive
                                </Radio>
                                <Radio key={'3'} value={'reactive'}>
                                    reactive
                                </Radio>
                            </Radio.Group>
                        }
                        {showEventStatus && countData &&
                            <Radio.Group
                                optionType="button"
                                size={'medium'}
                                value={statusFilter}
                                style={{marginTop: 4, marginLeft: 16}}
                                onChange={(e) => onChangeFilter(e.target.value)}
                            >
                                {Object.entries(countData).map(([status, count]) => (
                                    <Radio key={status} value={status}>
                                        {status} ({count})
                                    </Radio>
                                ))}
                            </Radio.Group>
                        }
                    </div>
                    <div>
                        {!showEventStatus &&
                            <>
                                <Text style={{marginTop: '0px', marginLeft: 16}}>
                                    History
                                </Text>
                                <HistoryInfo
                                    searchValue={searchValue}
                                    eventId={eventID}
                                />
                            </>
                        }
                        {!showEventStatus &&
                            <>
                                <Text style={{marginTop: '0px', marginLeft: 16}}>
                                    Image Analysis
                                </Text>
                                <PredictionInfo
                                    data={AIData}
                                />
                            </>

                        }
                        {!showEventStatus &&
                            <>
                                <Text style={{marginTop: '8px', marginLeft: 16}}>
                                    Images
                                </Text>
                                <Switch
                                    style={{marginTop: '-4px', marginLeft: 8}}
                                    onChange={onChangeTimeSwitch}
                                    checkedChildren="all"
                                    unCheckedChildren="all"
                                    checked={allImages}
                                    // disabled={true}
                                />
                            </>
                        }
                        {!showEventStatus &&
                            <>
                                <Text style={{marginTop: '0px', marginLeft: 16}}>
                                    Predictions
                                </Text>
                                <Switch
                                    style={{marginTop: '-4px', marginLeft: 8}}
                                    onChange={onChangePredictionsSwitch}
                                    checkedChildren="all"
                                    unCheckedChildren="all"
                                    checked={allPredictions}
                                    // disabled={true}
                                />
                            </>
                        }
                    </div>
                </Row>
            </Card>
            {
                showEventStatus ?
                    <EventList
                        showEventStatus={showEventStatus}
                        customer={customerSelect}
                        eventStatusReload={eventStatusReload}
                        setEventStatusReload={setEventStatusReload}
                        queryStartDate={queryStartDate}
                        queryEndDate={queryEndDate}
                        setCountData={setCountData}
                        statusFilter={statusFilter}
                        setLoading={setLoading}
                        assignmentStatus={assignmentStatus}
                    />
                    :
                    <Row gutter={8}>
                        <Col span={6} style={{height: '100%'}}>
                            <SearchInfo
                                infoData={infoData}
                                plotData={plotData}
                                searchValue={searchValue}
                                eventID={eventID}
                                UAID={UAID}
                                customerId={customerId}
                                searchType={searchType}
                            />
                            {infoData?.anomaly_type === 'inspection' ?
                                <InspectionInfo
                                    inspectionData={detailData}
                                    labelTarget={labelTarget}
                                    predictionInfo={AIData}
                                /> :
                                <FeedbackInfo
                                    feedbackData={detailData}
                                    labelTarget={labelTarget}
                                    onSelectFeedback={onSelectFeedback}
                                    selectedFeedbacks={selectedFeedbacks}
                                    predictionInfo={AIData}
                                />
                            }
                        </Col>
                        <Col span={10}>
                            <div ref={containerRefLeft}>
                                <Card
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                        border: '1px solid #e8e8e8',
                                        marginBottom: 8,
                                        minHeight: 485
                                    }}
                                    styles={{
                                        body: {
                                            padding: '4px',
                                            display: 'flex',
                                            flexDirection: "column",
                                            flex: "1 1 auto"
                                        }
                                    }}
                                    title={'Event Images'}
                                >
                                    <Row style={{marginTop: 8}}>
                                        {/*<Text style={{fontWeight: 500, fontSize: 16}}>*/}
                                        {/*    Event Images*/}
                                        {/*</Text>*/}
                                        {feedbackHelper &&
                                            <MultiImageRenderer
                                                data={feedbackImages}
                                                secondaryData={feedbackImagesAI}
                                                labels={feedbackImagesLabels}
                                                onSelect={setSelectedImage}
                                                maxImages={5}
                                                height={165}
                                                minHeight={165}
                                                columnWidth={100}
                                                maxImageHeight={140}
                                                containerRef={containerRefLeft}
                                            />
                                        }
                                        {/*<Text style={{fontWeight: 500, fontSize: 16}}>*/}
                                        {/*    AI Detected*/}
                                        {/*</Text>*/}
                                    </Row>
                                    <Row style={{marginTop: 8, marginLeft: 8, marginRight: 8}}>
                                        {AIData?.length === 0 && feedbackImages?.length > 0 &&
                                            <div style={{marginLeft: 250, marginTop: 8, marginBottom: 16}}>
                                                Images have not been processed by AI
                                            </div>

                                        }
                                        {predictionData &&
                                            <DamageReportTable
                                                data={predictionData.filter(row => selectedDocs.includes(parseInt(row.doc_id)) || row.doc_id === null)}
                                                updateData={handleUpdate}
                                                labels={feedbackImagesLabels}
                                                imageData={feedbackImages}
                                                imageDataAI={feedbackImagesAI}
                                            />
                                        }
                                    </Row>
                                </Card>
                                <Card
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                        border: '1px solid #e8e8e8',
                                        marginBottom: 8,
                                        minHeight: 310
                                    }}
                                    styles={{
                                        body: {
                                            padding: '12px',
                                            display: 'flex',
                                            flexDirection: "column",
                                            flex: "1 1 auto"
                                        }
                                    }}
                                >
                                    {/*<Text style={{fontWeight: 500, fontSize: 16}}>*/}
                                    {/*    Labels*/}
                                    {/*</Text>*/}
                                    <div style={{overflow: 'auto', maxHeight: 'calc(100% - 30px)'}}>
                                        <Tabs
                                            onChange={onLabelTabSelect}
                                            defaultActiveKey="1"
                                            items={[
                                                {
                                                    key: 'feedbacks',
                                                    label: `Image Labels`,
                                                    icon: <PictureOutlined/>,
                                                    children:
                                                        <>
                                                            {feedbackImages ?
                                                                <ImageLabels
                                                                    customer={customer}
                                                                    searchValue={searchValue}
                                                                    searchType={searchType}
                                                                    labelTarget={labelTarget}
                                                                    feedbackImages={feedbackImages}
                                                                    feedbackData={detailData}
                                                                    labelData={labelData?.images}
                                                                    setUpdateCounter={setLabelReload}
                                                                    labelFeedback={labelFeedback}
                                                                />
                                                                :
                                                                <Text>
                                                                    No Feedback Available
                                                                </Text>
                                                            }
                                                        </>,
                                                },
                                                {
                                                    key: 'carparts',
                                                    label: `Car Part Labels`,
                                                    icon: <TagOutlined/>,
                                                    children:
                                                        <>
                                                            <CarPartLabels
                                                                customer={customer}
                                                                searchValue={searchValue}
                                                                searchType={searchType}
                                                                labelTarget={labelTarget}
                                                                data={predictionData?.filter(row => selectedDocs.includes(parseInt(row.doc_id)) || row.doc_id === null)}
                                                                imageData={feedbackImagesLabels}
                                                                labelData={labelData?.carparts}
                                                                setUpdateCounter={setLabelReload}
                                                                labelFeedback={labelFeedback}
                                                            />
                                                        </>,
                                                }
                                            ]
                                            }
                                        />
                                    </div>
                                </Card>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div ref={containerRefRight}>
                                {inspectionData &&
                                    <VehicleImages
                                        customer={customer}
                                        inspectionData={inspectionData}
                                        customerId={customerId}
                                        containerRef={containerRefRight}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
            }
        </>
    )
        ;
}

export default ImageTool;