import axios from "axios";
import { authConfig} from "../authConfig";
import { User } from "oidc-client-ts"

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

function getUser() {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${authConfig.authority}:${authConfig.client_id}`)
    if (!oidcStorage) {
        return null;
    }
    return User.fromStorageString(oidcStorage);
}


// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const user = getUser();
    const accessToken = user?.access_token;
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;