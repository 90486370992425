import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Dropdown,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Divider,
    Popover,
    Checkbox,
    Tag,
    InputNumber, Descriptions, Image, Timeline, Tooltip, Radio, Table, Tabs
} from 'antd';
import Icon, {
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    FilterOutlined,
    PictureOutlined,
    TagOutlined,
    EllipsisOutlined,
    DatabaseOutlined,
    ReloadOutlined,
    ZoomOutOutlined,
    EditFilled,
    ToolOutlined,
    CarOutlined,
    SearchOutlined,
    WarningOutlined, CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";


import axiosInstance from "services/axios";

import CarPartLabels from "./CarPartLabels";
import ImageLabels from "./ImageLabels";
import EventLabels from "./EventLabels";
import DamageReportTable from "./DamageReportTable";
import MultiImageRenderer from "./MultiImageRenderer";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import MultiImageRendererV2 from "../MultiImageRendererV2/MultiImageRendererV2";
import {useNavigate} from "react-router-dom";
import {FaFile, FaInfo} from "react-icons/fa";
import moment from "moment/moment";
import {AgGridReact} from "ag-grid-react";
import {getAllColumnDefs, getProactiveColumnDefs, getReactiveColumnDefs} from "./ListColumns";
import dayjs from "dayjs";

const {Search} = Input;
const {Text, Paragraph, Link} = Typography;
const {Option} = Select;

const EventList = (props) => {

    const [gridRef, setGridRef] = useState(null);
    const [data, setData] = useState(null);
    const gridRefAll = useRef();
    const gridRefReactive = useRef();
    const gridRefProactive = useRef();

    useEffect(() => {
        if (gridRefProactive && gridRefReactive && gridRefAll) {
            if (props.assignmentStatus === 'proactive') {
                setGridRef(gridRefProactive)
            } else if (props.assignmentStatus === 'reactive') {
                setGridRef(gridRefReactive)
            } else {
                setGridRef(gridRefAll)
            }

        }
    }, [gridRefProactive, gridRefReactive, gridRefAll, props.assignmentStatus]);

    useEffect(() => {
        if (gridRef?.current?.api && data) {
            if (props.assignmentStatus === 'proactive') {
                gridRef.current.api.setRowData(data.filter(item => item.assignment_status === 'proactive'));
                const statusCountDict = {
                    all: data.filter(item => item.assignment_status === 'proactive').length,
                    pending: data.filter(item => item.status === 'pending' && item.assignment_status === 'proactive').length,
                    todo: data.filter(item => item.status === 'todo' && item.assignment_status === 'proactive').length,
                    in_progress: data.filter(item => item.status === 'in_progress' && item.assignment_status === 'proactive').length,
                    review: data.filter(item => item.status === 'review' && item.assignment_status === 'proactive').length,
                    done: data.filter(item => item.status === 'done' && item.assignment_status === 'proactive').length,
                };
                props.setCountData(statusCountDict)
            } else if (props.assignmentStatus === 'reactive') {
                gridRef.current.api.setRowData(data.filter(item => item.assignment_status === 'reactive'));
                const statusCountDict = {
                    all: data.filter(item => item.assignment_status === 'reactive').length,
                    pending: data.filter(item => item.status === 'pending' && item.assignment_status === 'reactive').length,
                    todo: data.filter(item => item.status === 'todo' && item.assignment_status === 'reactive').length,
                    in_progress: data.filter(item => item.status === 'in_progress' && item.assignment_status === 'reactive').length,
                    review: data.filter(item => item.status === 'review' && item.assignment_status === 'reactive').length,
                    done: data.filter(item => item.status === 'done' && item.assignment_status === 'reactive').length,
                };
                props.setCountData(statusCountDict)
            } else {
                gridRef.current.api.setRowData(data);
                const statusCountDict = {
                    all: data.length,
                    pending: data.filter(item => item.status === 'pending').length,
                    todo: data.filter(item => item.status === 'todo').length,
                    in_progress: data.filter(item => item.status === 'in_progress').length,
                    review: data.filter(item => item.status === 'review').length,
                    done: data.filter(item => item.status === 'done').length,
                };
                props.setCountData(statusCountDict)
            }
        }
    }, [gridRef, data]);

    useEffect(() => {
        if (props.customer && props.customer.length === 0 && gridRef && gridRef.current?.api) {
            gridRef.current.api.setRowData(null);
        }
    }, [props.customer]);

    useEffect(() => {
        if (props.customer && props.queryStartDate && props.queryEndDate && props.customer.length > 0) {
            props.setLoading(true)
            axiosInstance.post(
                '/api/image_tool/event_status',
                {
                    customer: props.customer,
                    date_start: props.queryStartDate,
                    date_end: props.queryEndDate,
                    assignment_status: props.assignmentStatus
                })
                .then(res => {
                    console.log(res.data)
                    props.setLoading(false)
                    setData(res.data.data)
                })
                .catch(error => {
                        console.log(error);
                        props.setLoading(false)
                        notification['error']({
                            message: 'Error in get EventStatus',
                            description: (
                                <>
                                    {error.message + ':'}<br/>
                                    {error.response && error.response.data && error.response.data.message}
                                </>
                            ),
                        });
                    }
                )
        }
    }, [props.customer, props.eventStatusReload, props.queryEndDate, props.queryStartDate, props.showEventStatus]);


    useEffect(() => {
        if (gridRef?.current?.api) {
            if (props.statusFilter !== 'all') {
                gridRef.current.api.setFilterModel({
                    'status': {
                        "values": [
                            props.statusFilter
                        ],
                        "filterType": "set"
                    }
                });
            } else {
                gridRef.current.api.setFilterModel({});
            }
        }
    }, [props.statusFilter]);

    const onCellValueChanged = (params) => {
        console.log(params);

        let col = params.column.getColId();
        if (params.oldValue !== params.newValue) {
            let data = {
                customer: params.data.customer,
                event_id: params.data.event_id,
                inspection_id: params.data.inspection_id,
                status: params.data.status,
                comment: params.data.comment,
            };
            data[col] = params.newValue

            let updateEditor = false
            if (col === 'status') {
                updateEditor = true
            }

            const payload = {
                data: data,
                update_editor: updateEditor
            }

            axiosInstance.post('/api/image_tool/event_status/update', payload)
                .then(response => {
                    //params.success(response.values);
                    props.setEventStatusReload(prevCounter => prevCounter + 1);
                    console.log('Success updating entry');
                    console.log(response);
                    console.log(params);
                    // notification['success']({
                    //         message: 'Updated project report for ' + params.data.customer,
                    //         duration: 2.5,
                    //         maxCount: 15
                    //     }
                    // );
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error updating', description: error.message});
                })
        }
    }

    const gridOptions = {
        localeText: {
            noRowsToShow: 'No data',
        },
        rowHeight: 50,
        rowSelection: 'multiple',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        pagination: true,
        paginationAutoPageSize: true,
        // enableCellTextSelection: true,
        overlayLoadingTemplate: 'Select a customer to view the event status list - to view a specific event search for it',
        defaultColDef: {
            width: 100,
            autoHeight: true,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {
                // textAlign: 'center',  // Center the text horizontally
                display: 'flex',
                alignItems: 'center', // Center vertically
                whiteSpace: 'normal', // Allow text to wrap
                lineHeight: 'normal',  // Maintain normal line height
                wordBreak: 'break-all',
                overflow: 'hidden'
            }
        },
        sideBar: {
            defaultToolPanel: '',
            position: 'right',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 400,
                    width: 300,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250
                }
            ],
        },
        defaultExcelExportParams: {
            processCellCallback: function (params) {
                if (params.column.colDef && params.column.colDef.cellClass === 'dateType') {
                    if (params.value) {
                        return moment(params.value).format('YYYY-MM-DDThh:mm:ss');
                    } else {
                        return params.value;
                    }
                } else {
                    return params.value;
                }
                //debugger;
            }
        },
        excelStyles: [
            {
                id: 'dateType',
                dataType: 'DateTime',
                numberFormat: {
                    format: 'yyy-mm-ddThh:mm:ss'
                }
            }
        ],
        onCellValueChanged: onCellValueChanged,
        onGridReady: function () {
            console.log('grid ready');
        },
        // statusBar: {
        //     statusPanels: [
        //         {statusPanel: 'agAggregationComponent', align: 'left'},
        //     ],
        // }
    }

    //
    // const onChangeTimeSwitch = (checked) => {
    //     setAllImages(checked);
    // }
    //
    // const handleSelectChange = (value) => {
    //     setCustomerSelect(value)
    // };

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: 'calc(100% - 80px)',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    marginBottom: 8,
                }}
                styles={{
                    body: {
                        padding: '4px',
                        display: 'flex',
                        flexDirection: "column",
                        flex: "1 1 auto"
                    }
                }}
            >
                <Row className="ag-theme-alpine" style={{flex: "1 1 auto"}}>
                    <Col style={{flex: "1 1 auto"}}>
                        {props.assignmentStatus === 'proactive' &&
                            <AgGridReact
                                ref={gridRefProactive}
                                columnDefs={getProactiveColumnDefs()}
                                gridOptions={gridOptions}
                                context={{customer: props.customer}}
                            />
                        }
                        {props.assignmentStatus === 'reactive' &&
                            <AgGridReact
                                ref={gridRefReactive}
                                columnDefs={getReactiveColumnDefs()}
                                gridOptions={gridOptions}
                                context={{customer: props.customer}}
                            />
                        }
                        {props.assignmentStatus === 'all' &&
                            <AgGridReact
                                ref={gridRefAll}
                                columnDefs={getAllColumnDefs()}
                                gridOptions={gridOptions}
                                context={{customer: props.customer}}
                            />
                        }
                    </Col>
                </Row>
            </Card>

        </>
    );
}

export default EventList;