import React, {useState, useEffect, useRef} from 'react';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";


const LoggingInfo = (props) => {

    const screenSize = {width: window.innerWidth, height: window.innerHeight};
    const height = screenSize.height > 1200 ? 985 : 655; // Assuming 2560 width for a 2k monitor


    const customProgressComparator = (str1, str2) => {
        const value1 = parseInt(str1.split("/")[0]);
        const value2 = parseInt(str2.split("/")[0]);

        if (value1 < value2) {
            return -1;
        } else if (value1 > value2) {
            return 1;
        } else {
            return 0;
        }
    };

    const customDateComparator = (date1, date2) => {
        const parsedDate1 = new Date(date1);
        const parsedDate2 = new Date(date2);

        if (parsedDate1 < parsedDate2) {
            return -1;
        } else if (parsedDate1 > parsedDate2) {
            return 1;
        } else {
            return 0;
        }
    };

    const customDateRenderer = (params) => {
        if (params.value === null || params.value === undefined) {
            return null; // Return null if the date value is null
        }
        const date = new Date(Date.parse(params.value));
        // console.log(date)
        const options = {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
        // console.log(date.toLocaleDateString(undefined, options))
        return date.toLocaleDateString(undefined, options)
    };

    const gridRef = useRef();
    const [renderedDateTime, setRenderedDateTime] = useState(new Date());
    const [renderKey, setRenderKey] = useState(0);

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        {
            field: 'operation_type',
            headerName: 'Context',
            filter: 'agSetColumnFilter',
            width: 130,
        },
        {
            field: 'operation',
            // hide: true,
            headerName: 'Operation ID',
            filter: 'agTextColumnFilter',
            width: 100,
        },
        {
            field: 'content',
            headerName: 'Operation',
            filter: 'agTextColumnFilter',
            width: 280,
        },
        {
            field: 'status',
            filter: 'agSetColumnFilter',
            headerName: 'Status',
            width: 75,
        },
        {
            field: 'progress',
            filter: 'agTextColumnFilter',
            headerName: 'Progress',
            comparator: customProgressComparator,
            width: 75,
        },
        {
            field: 'operation_user',
            filter: 'agSetColumnFilter',
            headerName: 'User',
            width: 150
        },
        // {
        //     field: 'anomalies_to_add',
        //     headerName: 'To Add',
        // },
        // {
        //     field: 'anomalies_added',
        //     headerName: 'Added',
        // },
        // {
        //     field: 'anomalies_updated',
        //     headerName: 'Updated',
        // },
        {
            field: 'created_datetime',
            headerName: 'Created',
            comparator: customDateComparator,
            cellRenderer: customDateRenderer,
            filter: 'agDateColumnFilter',
            sort: 'desc',
            filterParams: {
                inRangeInclusive: true,
                suppressAndOrCondition: true,
            }
        },
        {
            field: 'modified_datetime',
            headerName: 'Modified',
            comparator: customDateComparator,
            cellRenderer: customDateRenderer,
            filter: 'agDateColumnFilter',
            filterParams: {
                inRangeInclusive: true,
                suppressAndOrCondition: true,
            }
        }
    ]

    let emptyRowData = [
        {box_id: 'empty'},
    ];

    const [gridOptions, setGridOptions] = useState({
        rowData: emptyRowData,
        rowSelection: 'single',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: columnDefs,
        rowHeight: 41,
        // getContextMenuItems: getContextMenuItems,
        pagination: true,
        paginationPageSize: screenSize.height > 1200 ? 20 : 12, // Number of rows per page
        // onPaginationChanged: onPaginationChanged,
        // components: {
        //     eventUrlRenderer: EventUrlRenderer
        // },
        defaultColDef: {
            width: 100,
            floatingFilter: true,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {fontSize: '14px'}
        },
        onGridReady: (event) => event.api.sizeColumnsToFit(),
        // onSelectionChanged: onSelectionChanged,
        sideBar: {
            position: 'right',
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                }
            ],
        },
    })

    useEffect(() => {
        const fetchData = async () => {
            axiosInstance.post('/api/sidebar/logging')
                .then(function (response) {
                    setRenderedDateTime(new Date());
                    if (gridRef.current) {
                        gridRef.current.api.setRowData(response.data.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        if (gridRef.current) {
            fetchData();
        }

    }, [props.count]);

    return (
        <>
            <div>Displaying logs within last 30 days - Refreshed at: {renderedDateTime.toLocaleString()}</div>
            <div
                style={{width: '100%', height: height, minHeight: 280, marginTop: 8}}
            >
                <AgGridReact
                    key={renderKey}
                    ref={gridRef}
                    gridOptions={gridOptions}
                    className="ag-theme-alpine"
                />
            </div>
        </>
    );
};

export default LoggingInfo;