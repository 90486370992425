import React, {useEffect, useState} from 'react';
import {Modal, Table} from 'antd';
import axiosInstance from "../../services/axios";

const HistoryModal = (props) => {

  const [data, setData] = useState(undefined);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'operation_type',
      key: 'operation_type',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'User',
      dataIndex: 'operation_user',
      key: 'operation_user',
    },
    {
      title: 'Modified Datetime',
      dataIndex: 'modified_datetime',
      key: 'modified_datetime',
      render: (value) => value?.split('T')[0] + ' ' + value?.split('T')[1]?.split('.')[0],
    },
  ]

  useEffect(() => {
    const payload = {
      'id': props.id
    }
    axiosInstance.post('/api/matching_tool/event_logs', payload)
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        setData(undefined);
      });
  }, [props.count]);

  const handleCancel = () => {
    props.hideModal && props.hideModal();
  };

  return (
    <>
      <Modal title="Anomaly History"
         open={props.open}
         onCancel={handleCancel}
         width={"60vw"}
         height={"90vh"}
         footer={null}
      >
          <Table
            size='small'
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 680 }}
            // style={{'minWidth': '300px', 'maxWidth': '800px'}}
          />
      </Modal>
    </>
  );
};

export default HistoryModal;