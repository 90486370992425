import React, {useEffect, useState} from 'react';
import {Tag, Input, Tooltip, message} from 'antd';
import {PlusOutlined, EditOutlined, CheckOutlined} from '@ant-design/icons';
import {useAuth} from "react-oidc-context";
import axiosInstance from "../../services/axios";

const {TextArea} = Input;

const CommentTags = (props) => {
    const [tags, setTags] = useState([]);
    const [tagsLoaded, setTagsLoaded] = useState(false);
    const [originalTags, setOriginalTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const auth = useAuth();

    // console.log(props)
    // console.log(tags)

    useEffect(() => {
        if (props.data) {
            if (Object.keys(props.data).length !== 0) {
                setTags(props.data)
                setOriginalTags(props.data)
            }
            setTagsLoaded(true)
        }
    }, [props.data]);

    useEffect(() => {
        if (props.labelTarget && props.id && originalTags !== tags && tagsLoaded) {

            const payload = {
                search_value: props.labelTarget,
                row_id: props.id,
                customer: props.customer,
                label_type: props.labelType,
                comments: tags
            }

            axiosInstance.post('/api/image_tool/comments/update', payload)
                .then(function (response) {
                    props?.onUpdateComment(tags)
                    setOriginalTags(tags)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [tags]);

    const handleClose = removedTag => {
        const newTags = tags.filter(tag => tag !== removedTag);
        setTags(newTags);
        // props.onUpdateComment(newTags)
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags.filter(tag => tag.text === inputValue).length === 0) {
            setTags([...tags, {text: inputValue, user: auth?.user?.profile.name, date: new Date()}]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = e => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = {...newTags[editInputIndex], text: editInputValue, date: new Date()};
        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
    };

    const getTooltipContent = (user, created) => {
        if (user && created) {
            const formattedDatetime = new Date(created).toLocaleString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replace(',', '');

            if (user !== auth?.user?.profile?.name) {
                return `Created by ${user} - ${formattedDatetime}`
            } else {
                return `Created by you - ${formattedDatetime}`
            }
        }
        return null;
    };

    return (
        <div>
            {tags.map((tag, index) => {
                if (editInputIndex === index) {
                    return (
                        <Input
                            key={tag.text}
                            size="small"
                            className="tag-input"
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                        />
                    );
                }

                const isLongTag = tag.text.length > 20;
                const tagElem = (
                    <Tag
                        className="edit-tag"
                        key={tag.text}
                        closable={tag.user === auth?.user?.profile.name}
                        onClose={() => handleClose(tag)}
                        style={{marginTop: 4, display: 'inline-block', whiteSpace: 'normal'}}
                    >
            <span
                onDoubleClick={e => {
                    if (tag.user === auth?.user?.profile.name) {
                        setEditInputIndex(index);
                        setEditInputValue(tag.text);
                        e.preventDefault();
                    } else {
                        message.info(`Tag created by ${tag.user} on ${tag.date.toLocaleString()}`);
                    }
                }}
            >
              {isLongTag ? `${tag.text.slice(0, 140)}...` : tag.text}
            </span>
                    </Tag>
                );

                return (
                    <Tooltip
                        title={
                            <>
                                {getTooltipContent(tag.user, tag.date)}<br/><br/>
                                {tag.text.length > 500 ? `${tag.text.slice(0, 500)}...` : tag.text}
                            </>
                        }
                        key={tag.text}>
                        {tagElem}
                    </Tooltip>
                );
            })}
            {inputVisible && (
                <TextArea
                    autoSize
                    type="text"
                    size="small"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    style={{maxWidth: 380}}
                />
            )}
            {!inputVisible && (
                <Tag className="site-tag-plus" onClick={showInput} style={{marginTop: 4}}>
                    <PlusOutlined/> New Comment
                </Tag>
            )}
        </div>
    );
};

export default CommentTags;
