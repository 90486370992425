import React, {useState, useEffect} from 'react';

import InfoComponent from "./InfoComponent";
import {Button, message, Modal, Row, Typography} from "antd";

const {Text} = Typography;

const AnomalyViewer = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowRight' && currentIndex < props.uaids.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else if (e.key === 'ArrowLeft' && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentIndex, props.uaids]);

  const handleCancel = () => {
    props.hideModal && props.hideModal();
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Copied to clipboard!');
    } catch (err) {
      message.error('Failed to copy text.');
    }
  };

  return (
    <Modal open={props.open}
           onCancel={handleCancel}
           width={"80vw"}
           height={"80vh"}
           footer={null}
    >
      <Row>
        <Button
          style={{marginBottom: "10px"}}
          onClick={() => {
            copyToClipboard(props.uaids);
          }}
        >
          Copy all UAIDs
        </Button>
        <Text style={{marginTop: 5, marginLeft: 10}}>
          {`${currentIndex + 1}/${props.uaids.length} total`}
        </Text>
      </Row>
      <InfoComponent id={props.uaids[currentIndex]} plotHeight={'400px'}/>
    </Modal>
  );
};

export default AnomalyViewer;