import React, {useState, useEffect} from 'react';
import axiosInstance from "services/axios";
import {Button, Col, Descriptions, Input, message, notification, Row, Typography, Checkbox} from "antd";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import ChartToolModal from "../ChartTool/ChartToolModal";

const {Text} = Typography;

const InfoComponent = (props) => {

    const [data, setData] = useState(null);
    const [plots, setPlots] = useState(null);
    const [eventPattern, setEventPattern] = useState('');
    const [eventComment, setEventComment] = useState('');
    const [chartModalOpen, setChartModalOpen] = useState(false);

    const showBothChartModal = () => {
        setChartModalOpen(true);
    }

    const getDashboardValues = () => {
        axiosInstance.post(
            '/api/anomaly_tool/plots',
            {
                UAID: props.id,
            })
            .then(res => {
                // console.log(res.data)
                setPlots(res.data)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in get AnomalyPred',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (props.id !== null) {
            getDashboardValues();
        }
    }, [props.id]);

    const getInfoValues = () => {
        axiosInstance.post(
            '/api/anomaly_tool/info',
            {
                UAID: props.id,
            })
            .then(res => {
                setData(res.data)
                setEventPattern(res.data.event_pattern)
                setEventComment(res.data.event_comment)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in get AnomalyInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (props.id !== null) {
            getInfoValues();
        }
    }, [props.id]);

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            message.success('Copied to clipboard!');
        } catch (err) {
            message.error('Failed to copy text.');
        }
    };

    const handlePatternSubmit = (event) => {
        event.preventDefault();

        if (data.exists_matching_tool) {
            const payload = {
                'pattern': event.target[0].value,
                'UAID': props.id
            }
            axiosInstance.patch('/api/matching_tool/' + data.event_id, payload)
                .then(response => {
                    notification['success']({
                            message: 'Updated pattern for ' + props.id,
                            duration: 2.5,
                            maxCount: 15
                        }
                    );
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error updating pattern for' + props.id,
                        description: error.message
                    });
                })
        } else {
            notification['warning']({message: props.id + ' is not in the MatchingDB - it will be added now with this pattern'});
            importUAID({'pattern': event.target[0].value})
        }

    };

    const handleCommentSubmit = (event) => {
        event.preventDefault();

        if (data.exists_matching_tool) {
            const payload = {
                'comment': event.target[0].value,
                'UAID': props.id
            }
            axiosInstance.patch('/api/matching_tool/' + data.event_id, payload)
                .then(response => {
                    notification['success']({
                            message: 'Updated comment for ' + props.id,
                            duration: 2.5,
                            maxCount: 15
                        }
                    );
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error updating comment for' + props.id,
                        description: error.message
                    });
                })
        } else {
            notification['warning']({message: props.id + ' is not in the MatchingDB - it will be added now with this comment'});
            importUAID({'comment': event.target[0].value})
        }
    };

    const importUAID = (extra) => {
        let payload = {}
        if (extra) {
            payload = {
                'uaid': props.id,
                'extra': extra
            }
        } else {
            payload = {
                'uaid': props.id
            }
        }

        axiosInstance.post('/api/matching_tool/uaid', payload)
            .then(response => {
                notification['success']({
                        message: 'Importing ' + props.id,
                        duration: 2.5,
                        maxCount: 15
                    }
                );
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error importing ' + props.id, description: error.message});
            })
    }

    const backgroundColor = data?.exists_matching_tool ? 'rgba(0, 128, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)';

    return (
        <>
            <div>
                <ChartToolModal
                    showModal={() => setChartModalOpen(true)}
                    hideModal={() => setChartModalOpen(false)}
                    open={chartModalOpen}
                    type={'b'}
                    uaid={props.id}
                />
                <a href={`https://matching.carvaloo.com/anomaly_tool/${props.id}`} target="_blank"
                   rel="noopener noreferrer">
                    <Text style={{marginTop: '4px'}} strong={true}>
                        {props.id}
                    </Text>
                </a>
                <Text
                    style={{marginTop: '4px', marginLeft: '8px', padding: '2px 8px', backgroundColor: backgroundColor}}>
                    in Matching Tool
                </Text>
                <Text style={{marginTop: '0px'}}>
                    <br/> Customer: {data?.customer} - Box: {data?.box_id} - Alert: {data?.anomaly_type}
                    <br/> Vehicle: {data?.vehicle_id} - Type: {data?.vehicle_type}
                </Text>
                {!props.miniTooltip &&
                    <>
                        <Row>
                            <Col span={12}>
                                <div style={{height: props.plotHeight ? props.plotHeight : "100px"}}>
                                    <ImageRenderer value={plots?.uaid_image} name={'uaid_image'}/>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div style={{height: props.plotHeight ? props.plotHeight : "100px"}}>
                                    <ImageRenderer value={plots?.trip_image} name={'trip_image'}/>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 5}}>
                            <Button
                                onClick={showBothChartModal}
                            >
                                View Raw Data
                            </Button>
                            <Button
                                onClick={() => {
                                    copyToClipboard(props.id);
                                }}
                            >
                                Copy UAID
                            </Button>
                            {(data?.exists_matching_tool === false) && (
                                <Button
                                    onClick={() => {
                                        importUAID();
                                    }}
                                >
                                    Import to Tool
                                </Button>)}
                        </Row>
                        <div style={{marginTop: '10px', marginBottom: '10px'}}>
                            <form onSubmit={handlePatternSubmit}>
                                <Text style={{marginTop: '4px', marginRight: 5}} strong={true}>
                                    Pattern
                                </Text>
                                <Input
                                    type="text"
                                    value={eventPattern ? eventPattern : ''}
                                    placeholder={!eventPattern ? 'Add a pattern' : ''}
                                    style={{width: '60%'}}
                                    onChange={e => {
                                        setEventPattern(e.target.value);
                                    }}
                                />
                                <Button type="primary" htmlType="submit" style={{marginLeft: '5px'}}>
                                    Submit
                                </Button>
                            </form>
                        </div>
                        <div style={{marginTop: '10px', marginBottom: '10px'}}>
                            <form onSubmit={handleCommentSubmit}>
                                <Text style={{marginTop: '4px', marginRight: 5}} strong={true}>
                                    Comment
                                </Text>
                                <Input
                                    type="text"
                                    value={eventComment ? eventComment : ''}
                                    placeholder={!eventComment ? 'Add a comment' : ''}
                                    style={{width: '56%'}}
                                    onChange={e => {
                                        setEventComment(e.target.value);
                                    }}
                                />
                                <Button type="primary" htmlType="submit" style={{marginLeft: '5px'}}>
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default InfoComponent;