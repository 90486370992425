import React, {useState} from 'react';
import {Modal} from 'antd';
import LoggingInfo from "./LoggingInfo";

const LoggingInfoModal = (props) => {

  const handleCancel = () => {
    props.hideModal && props.hideModal();
  };

  return (
    <>
      <Modal title="Matching Tool Logging"
             open={props.open}
             onCancel={handleCancel}
             width={"80vw"}
             height={"60vh"}
             footer={null}
      >
        <LoggingInfo count={props.loggingOpenCount}/>
      </Modal>
    </>
  );
};

export default LoggingInfoModal;