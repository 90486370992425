function twoDigitRenderer(params) {
    if (params.value && !Array.isArray(params.value)) {
        return `${params.value.toFixed(2)}`
    } else {
        return "-"
    }
}

function zeroDigitRenderer(params) {
    if (params.value && !Array.isArray(params.value)) {
        return `${params.value.toFixed(0)}`
    } else {
        return "-"
    }
}

function percentageRenderer(params) {
    if (params.value && !Array.isArray(params.value)) {
        return `${params.value.toFixed(0)}%`
    } else {
        return "-"
    }
}

//
// function positiveCellColor50(params) {
//     const value = params.value;
//     const hue = Math.min(120 * (value / 50), 120);
//     const hsl = `hsl(${hue}, 100%, 50%, 40%)`;
//     return {
//         'backgroundColor': (value === null || value === undefined || value === 0) ? '#fff' : hsl,
//         'display': 'flex',
//         'alignItems': 'center',
//         'justifyContent': 'center'
//     }
// }

function quadraticRiseFall(H, max, min) {
    const range = max - min;
    if (H <= min) {
        return 0;
    } else if (H >= max) {
        return 100;
    } else {
        const scaledH = (H - min) / range;
        return scaledH * 100;
    }
}

function positiveCellColor(params, max, min) {
    const value = params.value;
    let hue;
    if (value === null || value === undefined || value === 0) {
        hue = 0;
    } else {
        if (value > max) {
            hue = 120;
        } else if (value < min) {
            hue = 0;
        } else {
            hue = 120 * ((value - min) / (max - min));
        }
    }

    let light = 50;
    // if (hue > min && hue < max) {
    //     light = quadraticRiseFall(hue, max, min);
    // }

    const hsl = `hsl(${hue}, 100%, ${light}%, 40%)`;
    return {
        'backgroundColor': (value === null || value === undefined || value === 0) ? '#fff' : hsl,
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center'
    };
}

function negativeCellColor(params, max, min) {
    const value = params.value;
    let hue;
    if (value === null || value === undefined || value === 0) {
        hue = 0;
    } else {
        if (value > max) {
            hue = 0; // Adjusted for negative values
        } else if (value < min) {
            hue = 120;
        } else {
            hue = 120 * ((max - value) / (max - min)); // Adjusted for negative values
        }
    }

    let light = 50;
    // if (hue > min && hue < max) {
    //     light = quadraticRiseFall(hue, max, min);
    // }

    const hsl = `hsl(${hue}, 100%, ${light}%, 40%)`;
    return {
        'backgroundColor': (value === null || value === undefined || value === 0) ? '#fff' : hsl,
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center'
    };
}

function commentStyle(params) {
    return {
        'fontSize': '11px',
        'whiteSpace': 'pre-wrap',
        'lineHeight': '1.2',
    };
}

const tooltipRenderer = (params) => {
    const {yValue} = params;
    return {
        content: (yValue === null || yValue === undefined || yValue === 0) ? yValue : yValue.toFixed(2), // format Y number values
    }
}

// const tooltipPercentRenderer = (params) => {
//     console.log(params)
//     const {yValue} = params;
//     const valueStr = yValue.toFixed(0)
//     return {
//         content: valueStr + '%', // format Y number values
//     }
// }

const customerPhaseOptions = ["pilot", "series"];
const customerStatusOptions = ["ok", "priority", "critical", "discontinued", "paused", "pending"];
const customerShowOptions = ["true", "false"];

const dateTimeFormatter = (params) => {
    const date = new Date(params.value + 'Z');
    return date.toLocaleString();
};

const nullFormatter = (params) => {
    return '';
};

export function getColumnDefs() {
    return [
        {
            field: 'customer',
            headerName: 'Customer',
            width: 150,
            floatingFilter: true,
            filter: 'agSetColumnFilter',
            pinned: 'left',
            sort: 'asc'
        },
        {
            field: 'group',
            width: 150,
            headerName: 'Vehicle Type',
            floatingFilter: true,
            filter: 'agSetColumnFilter',
            hide: true,
        },
        {
            headerName: 'Report',
            children: [
                {
                    field: 'status',
                    headerName: "Project Status",
                    headerTooltip: "Project Status",
                    floatingFilter: true,
                    width: 125,
                    filter: 'agSetColumnFilter',
                    cellEditor: 'agSelectCellEditor',
                    editable: true,
                    cellEditorParams: {
                        values: customerStatusOptions,
                    },
                    cellStyle: function (params) {
                        switch (params.value) {
                            case "ok":
                                return {
                                    'backgroundColor': '#d3f1b0',
                                    'textAlign': 'center',
                                    'fontSize': '18px',
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'justifyContent': 'center'
                                };
                            case "priority":
                                return {
                                    'backgroundColor': '#eee0ac',
                                    'textAlign': 'center',
                                    'fontSize': '18px',
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'justifyContent': 'center'
                                };
                            case "critical":
                                return {
                                    'backgroundColor': '#e5b2a9',
                                    'textAlign': 'center',
                                    'fontSize': '18px',
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'justifyContent': 'center'
                                };
                            case "discontinued":
                                return {
                                    'backgroundColor': '#ffffff',
                                    'textAlign': 'center',
                                    'fontSize': '18px',
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'justifyContent': 'center'
                                };
                            case "paused":
                                return {
                                    'backgroundColor': '#9f9f9f',
                                    'textAlign': 'center',
                                    'fontSize': '18px',
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'justifyContent': 'center'
                                };
                            case "pending":
                                return {
                                    'backgroundColor': '#59c5ff',
                                    'textAlign': 'center',
                                    'fontSize': '18px',
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'justifyContent': 'center'
                                };
                            default:
                                return {};
                        }
                    }
                },
                {
                    field: 'phase',
                    headerName: "Project Phase",
                    headerTooltip: "Project Phase",
                    floatingFilter: true,
                    width: 100,
                    filter: 'agSetColumnFilter',
                    cellEditor: 'agSelectCellEditor',
                    editable: true,
                    cellEditorParams: {
                        values: customerPhaseOptions,
                    },
                },
                {
                    field: 'comment',
                    headerName: 'Comment',
                    floatingFilter: true,
                    cellEditorPopup: true,
                    width: 200,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorParams: {
                        maxLength: 1000,
                        rows: 30,
                        cols: 60
                    },
                    filter: 'agTextColumnFilter',
                    columnGroupShow: 'open',
                    editable: true,
                    cellStyle: commentStyle
                },
                {
                    field: 'ratio',
                    headerName: 'Device Activation %',
                    headerTooltip: "Amount activated damage detection telematics [%] (activated telematics units / assigned telematics units)",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'defined_action',
                    headerName: 'Planned Action',
                    floatingFilter: true,
                    width: 120,
                    cellEditorPopup: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorParams: {
                        maxLength: 1000,
                        rows: 30,
                        cols: 60
                    },
                    filter: 'agTextColumnFilter',
                    columnGroupShow: 'open',
                    editable: true,
                    cellStyle: commentStyle
                },
                {
                    field: 'status_action',
                    headerName: 'Action Status',
                    floatingFilter: true,
                    cellEditorPopup: true,
                    cellEditor: 'agLargeTextCellEditor',
                    width: 120,
                    cellEditorParams: {
                        maxLength: 1000,
                        rows: 30,
                        cols: 60
                    },
                    filter: 'agTextColumnFilter',
                    columnGroupShow: 'open',
                    editable: true,
                    cellStyle: commentStyle
                },
                {
                    field: 'show_default',
                    headerName: 'Default',
                    headerTooltip: "Default customers to show/hide",
                    floatingFilter: true,
                    filter: 'agSetColumnFilter',
                    cellEditor: 'agSelectCellEditor',
                    editable: true,
                    cellEditorParams: {
                        values: customerShowOptions,
                    },
                    columnGroupShow: 'open',
                },
                {
                    field: 'created_datetime',
                    headerName: 'Modified',
                    columnGroupShow: 'open',
                    width: 200,
                    valueFormatter: dateTimeFormatter,
                },
            ]
        },
        {
            headerName: 'Vehicles',
            children: [
                {
                    field: 'mean_active_count',
                    headerName: '\u2300 Activated Vehicle Count',
                    headerTooltip: "Mean activated vehicle count per month",
                    floatingFilter: true,
                    width: 110,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: zeroDigitRenderer,
                },
                {
                    field: 'activated',
                    headerName: 'Current Activated Vehicle Count',
                    headerTooltip: "Current activated vehicle count",
                    floatingFilter: true,
                    width: 110,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: zeroDigitRenderer,
                },
                {
                    field: 'months',
                    headerName: 'Active Months',
                    headerTooltip: "Active month timeframe",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                // {
                //     field: 'car_count',
                //     headerName: '\u2300 Car Count',
                //     headerTooltip: "Mean car count per month",
                //     floatingFilter: true,
                //     filter: 'agNumberColumnFilter',
                //     columnGroupShow: 'open',
                //     cellRenderer: zeroDigitRenderer,
                // },
                // {
                //     field: 'van_count',
                //     headerName: '\u2300 Van Count',
                //     headerTooltip: "Mean van count per month",
                //     floatingFilter: true,
                //     filter: 'agNumberColumnFilter',
                //     columnGroupShow: 'open',
                //     cellRenderer: zeroDigitRenderer,
                // },
                // {
                //     field: 'car_percentage',
                //     headerName: 'Car %',
                //     floatingFilter: true,
                //     filter: 'agNumberColumnFilter',
                //     columnGroupShow: 'open',
                //     cellRenderer: percentageRenderer,
                // },
                // {
                //     field: 'van_percentage',
                //     headerName: 'Van %',
                //     floatingFilter: true,
                //     filter: 'agNumberColumnFilter',
                //     columnGroupShow: 'open',
                //     cellRenderer: percentageRenderer,
                // },
                // {
                //     field: 'alert_configuration',
                //     headerName: 'Alert Configuration',
                //     width: 150,
                //     floatingFilter: true,
                //     filter: 'agTextColumnFilter',
                //     columnGroupShow: 'open'
                // },
            ]
        },
        {
            headerName: 'Trips',
            children: [
                {
                    field: 'mean_driving_count',
                    headerName: '\u2300 Driving Vehicle Count',
                    headerTooltip: "Mean driving vehicle count per month (aggregated based on daily driving - minimum one trip per day)",
                    floatingFilter: true,
                    width: 110,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: zeroDigitRenderer,
                },
                {
                    field: 'vehicle_monthly_plot',
                    headerName: '\u2300 Vehicle Trend',
                    headerTooltip: "Plot mean driving vehicles per time period",
                    columnGroupShow: 'open',
                    hide: true,
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            }
                        }
                    }
                },
                {
                    field: 'driving_distance_sum',
                    width: 105,
                    headerName: 'Total Distance',
                    headerTooltip: "Total kilometers for all vehicles",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                    cellRenderer: zeroDigitRenderer,
                },
                {
                    field: 'trip_count',
                    width: 105,
                    headerName: 'Total Trips',
                    headerTooltip: "Total trips for all vehicles",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                    cellRenderer: zeroDigitRenderer,
                },
                {
                    field: 'vehicle_mean_velocity',
                    headerName: '\u2300 Velocity',
                    headerTooltip: "Mean kilometer/hour for all vehicles",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'vehicle_mean_distance',
                    headerName: '\u2300 Daily Distance',
                    width: 105,
                    headerTooltip: "Mean kilometers per vehicle per day",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'vehicle_mean_duration',
                    width: 105,
                    headerName: '\u2300 Daily Driving',
                    headerTooltip: "Mean hours driving per vehicle per day",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'vehicle_mean_trip_count',
                    width: 105,
                    headerName: '\u2300 Daily Trips',
                    headerTooltip: "Mean number of trips per vehicle per day",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                    cellRenderer: twoDigitRenderer,
                },
            ]
        },
        {
            headerName: 'Statistics',
            children: [
                {
                    field: 'proactive_alerts',
                    headerName: 'Proactive Alerts',
                    floatingFilter: true,
                    width: 120,
                    filter: 'agNumberColumnFilter',
                },
                {
                    field: 'alerts_plot',
                    headerName: 'Proactive Alert Trend',
                    headerTooltip: "Plot total alerts per month",
                    hide: true,
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            }
                        }
                    }
                },
                {
                    field: 'relative_alerts_plot',
                    headerName: 'Proactive Alert % Trend',
                    headerTooltip: "Plot relative total alerts per month",
                    hide: true,
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            },
                            // tooltip: {
                            //   renderer: tooltipPercentRenderer
                            // }
                        }
                    },
                },
                {
                    field: 'no_damage_feedback',
                    headerName: 'No Feedback Proactive',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
                {
                    field: 'reactive_alerts',
                    headerName: 'Reactive Events',
                    headerTooltip: "Reactive alerts which have not yet been closed in the WebApp",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
                {
                    field: 'reactive_open',
                    headerName: 'Open Reactive Events',
                    headerTooltip: "Reactive alerts which have not yet been closed in the WebApp",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
                {
                    field: 'damages',
                    headerName: 'Reported Damages',
                    headerTooltip: 'Reported Damages (customer damages + proactive)',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
                {
                    field: 'damages_detected',
                    headerName: 'Detected Damages',
                    headerTooltip: 'Detected Damages (reactive + proactive)',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
                {
                    field: 'damages_not_detected',
                    headerName: 'Not Detected Damages',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
                {
                    field: 'proactive_damages',
                    headerName: 'Proactive Damages',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
                {
                    field: 'reactive_damages',
                    headerName: 'Reactive Damages',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    columnGroupShow: 'open',
                },
            ]
        },
        {
            headerName: 'Primary KPIs',
            children: [
                {
                    field: 'proactive_damages_traced_percentage',
                    headerName: 'Proactive Damage %',
                    headerTooltip: "Damage traceability in %",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => positiveCellColor(params, 100, 50)
                },
                {
                    field: 'reactive_damages_traced_percentage',
                    headerName: 'Reactive Damage %',
                    headerTooltip: "Damage traceability in %",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => negativeCellColor(params, 50, 0)
                },
                // {
                //     field: 'adjusted_tp_percentage',
                //     headerName: 'Adjusted TP %',
                //     columnGroupShow: 'open',
                //     headerTooltip: "TP percentage excluding feedback and auto closed rates",
                //     floatingFilter: true,
                //     filter: 'agNumberColumnFilter',
                //     cellRenderer: percentageRenderer,
                //     cellStyle: positiveCellColor
                // },
                {
                    field: 'traceability',
                    headerName: 'Damage Trace %',
                    headerTooltip: "Damage traceability in %",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => positiveCellColor(params, 100, 50)
                },
                {
                    field: 'trace_plot',
                    headerName: 'Trace % Trend',
                    headerTooltip: "Damage traceability in % as time trend",
                    cellRenderer: 'agSparklineCellRenderer',
                    hide: true,
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            },
                        }
                    },
                },
                {
                    field: 'true_alert_percentage',
                    headerName: 'Alert True Positive %',
                    floatingFilter: true,
                    width: 105,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => positiveCellColor(params, 50, 0)
                },
                {
                    field: 'proactive_damages_percentage',
                    headerName: 'Proactive %',
                    floatingFilter: true,
                    hide: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => positiveCellColor(params, 50, 0)
                },
                {
                    field: 'reactive_damages_percentage',
                    headerName: 'Reactive %',
                    floatingFilter: true,
                    hide: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => negativeCellColor(params, 50, 0)
                },
            ]
        },
        {
            headerName: 'Secondary KPIs',
            children: [
                {
                    field: 'alerts_monthly',
                    headerName: 'Total Alert Rate',
                    headerTooltip: "Total alerts per month",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'alerts_active_vehicle_month',
                    headerName: 'Activated Alert Rate',
                    headerTooltip: "Total alerts per activated vehicle per month",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'alerts_active_plot',
                    headerName: 'Activated Alert Rate Trend',
                    headerTooltip: "Plot total alerts per month per activated vehicle",
                    hide: true,
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            },
                            tooltip: {
                                renderer: tooltipRenderer
                            }
                        }
                    },
                },
                {
                    field: 'alerts_vehicle_month',
                    headerName: 'Driving Alert Rate',
                    headerTooltip: "Total alerts per driving vehicle per month",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'alerts_vehicle_plot',
                    headerName: 'Driving Alert Rate Trend',
                    headerTooltip: "Plot total alerts per month per driving vehicle",
                    hide: true,
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            },
                            tooltip: {
                                renderer: tooltipRenderer
                            }
                        }
                    },
                },
                {
                    field: 'damages_active_vehicle_month',
                    headerName: 'Activated Damage Detection Rate',
                    headerTooltip: "Damages detected per active vehicle per month",
                    floatingFilter: true,
                    hide: true,
                    width: 105,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'damages_detected_active_vehicle_plot',
                    headerName: 'Activated Damage Detection Trend',
                    headerTooltip: "Plot damages detected per active vehicle per month",
                    hide: true,
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            }
                        }
                    }
                },
                {
                    field: 'damages_vehicle_month',
                    headerName: 'Driving Damage Detection Rate',
                    headerTooltip: "Damages detected per driving vehicle per month",
                    floatingFilter: true,
                    hide: true,
                    width: 105,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'damages_detected_vehicle_plot',
                    headerName: 'Driving Damage Detection Trend',
                    headerTooltip: "Plot damages detected per driving vehicle per month",
                    hide: true,
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            }
                        }
                    }
                },
                {
                    field: 'damages_all_active_vehicle_month',
                    headerName: 'Activated Damage Rate',
                    headerTooltip: "Damages per active vehicle per month",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'damages_active_vehicle_plot',
                    headerName: 'Activated Damage Trend',
                    headerTooltip: "Plot damages per active vehicle per month",
                    hide: true,
                    columnGroupShow: 'open',
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            }
                        }
                    }
                },
                {
                    field: 'damages_all_vehicle_month',
                    headerName: 'Driving Damage Rate',
                    headerTooltip: "Damages per driving vehicle per month",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'damages_vehicle_plot',
                    headerName: 'Driving Damage Trend',
                    headerTooltip: "Plot damages per driving vehicle per month",
                    hide: true,
                    columnGroupShow: 'open',
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            }
                        }
                    }
                },
                // {
                //   field: 'relative_alerts_vehicle_plot',
                //   headerName: '% Alert Rate Trend',
                //   headerTooltip: "Plot relative total alerts per month per driving vehicle",
                //   hide: true,
                //   cellRenderer: 'agSparklineCellRenderer',
                //   cellRendererParams: {
                //     sparklineOptions: {
                //       type: 'column',
                //       highlightStyle: {
                //           fill: 'orange', // sets the highlighted column fill
                //       },
                //     }
                //   },
                // },
                {
                    field: 'alerts_vehicle_month_trips',
                    headerName: 'Driving Alert Rate (Trips)',
                    headerTooltip: "Total alerts per 1000 trips",
                    width: 105,
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'alerts_vehicle_month_distance',
                    headerName: 'Driving Alert Rate (Distance)',
                    headerTooltip: "Total alerts per 1000 km driving",
                    width: 105,
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'alerts_vehicle_month_duration',
                    headerName: 'Driving Alert Rate (Duration)',
                    headerTooltip: "Total alerts per 24h driving",
                    width: 105,
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'detected_vehicle_month_trips',
                    headerName: 'Driving Damage Detection Rate (Trips)',
                    headerTooltip: "Total damages detected per 1000 trips",
                    floatingFilter: true,
                    hide: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'detected_vehicle_month_distance',
                    headerName: 'Driving Damage Detection Rate (Distance)',
                    headerTooltip: "Total damages detected per 1000 km driving",
                    floatingFilter: true,
                    hide: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'detected_vehicle_month_duration',
                    headerName: 'Driving Damage Detection Rate (Duration)',
                    headerTooltip: "Total damages detected per 24h driving",
                    floatingFilter: true,
                    hide: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'damages_vehicle_month_trips',
                    headerName: 'Driving Damage Rate (Trips)',
                    headerTooltip: "Total damages per 1000 trips",
                    floatingFilter: true,
                    width: 105,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'damages_vehicle_month_distance',
                    headerName: 'Driving Damage Rate (Distance)',
                    headerTooltip: "Total damages per 1000 km driving",
                    floatingFilter: true,
                    width: 105,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'damages_vehicle_month_duration',
                    headerName: 'Driving Damage Rate (Duration)',
                    headerTooltip: "Total damages per 24h driving",
                    floatingFilter: true,
                    width: 105,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                // {
                //     field: 'no_damages_vehicle_month',
                //     headerName: 'No Damage Detection Rate',
                //     headerTooltip: "No damages detected per vehicle per month",
                //     floatingFilter: true,
                //     hide: true,
                //     filter: 'agNumberColumnFilter',
                //     cellRenderer: twoDigitRenderer,
                // },
                // {
                //     field: 'manual_process_percentage',
                //     headerName: 'Manual Process %',
                //     headerTooltip: "Manual processing of proactive alerts",
                //     floatingFilter: true,
                //     columnGroupShow: 'open',
                //     filter: 'agNumberColumnFilter',
                //     cellRenderer: percentageRenderer,
                //     cellStyle: positiveCellColor
                // },
                // {
                //     field: 'manual_process_percentage_plot',
                //     headerName: 'Manual Process % Trend',
                //     headerTooltip: "Manual processing of proactive alerts plotted",
                //     hide: true,
                //     columnGroupShow: 'open',
                //     cellRenderer: 'agSparklineCellRenderer',
                //     cellRendererParams: {
                //         sparklineOptions: {
                //             type: 'column',
                //             highlightStyle: {
                //                 fill: 'orange', // sets the highlighted column fill
                //             },
                //         }
                //     },
                // },
            ]
        },
        {
            headerName: 'Feedback KPIs',
            children: [
                {
                    field: 'no_feedback_percentage',
                    headerName: 'No Feedback Proactive %',
                    headerTooltip: "Percentage of no feedback and auto closed",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'review_percentage',
                    headerName: 'User Feedback Reviewed % ',
                    headerTooltip: "User Feedback events where the feedback has been reviewed [%]",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'feedback_quality',
                    headerName: 'Proactive Feedback Quality % ',
                    headerTooltip: "Events where pictures or inspection result not blank [%] - includes all proactive events",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'damage_feedback_quality',
                    headerName: 'Damage Feedback Quality % ',
                    headerTooltip: "Damages where pictures or inspection result not blank [%] - includes all proactive damage events",
                    floatingFilter: true,
                     columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'no_damage_feedback_quality',
                    headerName: 'No Damage Feedback Quality % ',
                    headerTooltip: "Events without damages where pictures or inspection result not blank [%] - includes all proactive no damage events",
                    floatingFilter: true,
                     columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'timely_webapp_feedback',
                    headerName: 'Timely Webapp Feedback %',
                    headerTooltip: 'notification date - feedback date < 1 Week [%]',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'timely_user_feedback',
                    headerName: 'Timely User Feedback %',
                    headerTooltip: 'notification date - feedback date < 1 Week [%]',
                    columnGroupShow: 'open',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'timely_review_feedback',
                    headerName: 'Timely Review Feedback %',
                    headerTooltip: 'notification date - feedback date < 1 Week [%]',
                    columnGroupShow: 'open',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'mean_webapp_feedback',
                    headerName: 'Median Webapp Feedback (Days)',
                    headerTooltip: "notification date - feedback date [days]",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'mean_user_feedback',
                    headerName: 'Median User Feedback (Days)',
                    headerTooltip: "notification date - feedback date [days]",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'mean_review_feedback',
                    headerName: 'Median Review Feedback (Days)',
                    headerTooltip: "notification date - feedback date [days]",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                }
            ]
        },
                {
            headerName: 'Analytics KPIs',
            children: [
                {
                    field: 'proactive_damages',
                    headerName: 'Total Proactive Damages',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                },
                {
                    field: 'damages_label_pct',
                    headerName: 'Total Proactive Damage Label %',
                    headerTooltip: "Percentage of damage events that have been labeled",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'plausible_dmg_pct',
                    headerName: 'Plausible Proactive Damage %',
                    headerTooltip: "Percentage of damage events that have been labeled with MC >= 2",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => positiveCellColor(params, 50, 0)
                },
                {
                    field: 'unsure_dmg_pct',
                    headerName: 'Unsure Proactive Damage %',
                    headerTooltip: "Percentage of damage events that have been labeled with MC == 0",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'unlikely_dmg_pct',
                    headerName: 'Unlikely Proactive Damage %',
                    headerTooltip: "Percentage of damage events that have been labeled with MC == 1",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => negativeCellColor(params, 15, 0)
                },
                {
                    field: 'no_damage_detected',
                    headerName: 'Total False Positives',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                },
                {
                    field: 'no_damages_label_pct',
                    headerName: 'Total False Positive Label %',
                    headerTooltip: "Percentage of no damage events that have been labeled",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'plausible_fp_pct',
                    headerName: 'Plausible False Positive %',
                    headerTooltip: "Percentage of false positive events that have been labeled with MC <= -2",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'unsure_fp_pct',
                    headerName: 'Unsure False Positive %',
                    headerTooltip: "Percentage of false positive events that have been labeled with MC == 0",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'unlikely_fp_pct',
                    headerName: 'Unlikely False Positive %',
                    headerTooltip: "Percentage of false positive events that have been labeled with MC == -1",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => negativeCellColor(params, 20, 0)
                },
                {
                    field: 'anomalies_active_vehicle_month',
                    headerName: 'Activated Anomaly Rate',
                    headerTooltip: "Total anomalies per activated vehicle per month",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'anomalies_vehicle_month',
                    headerName: 'Driving Anomaly Rate',
                    headerTooltip: "Total anomalies per driving vehicle per month",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'anomalies_vehicle_month_distance',
                    headerName: 'Driving Anomaly Rate (Distance)',
                    headerTooltip: "Total anomalies per 1000 km driving",
                    width: 105,
                    columnGroupShow: 'open',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'anomalies_vehicle_month_duration',
                    headerName: 'Driving Anomaly Rate (Duration)',
                    headerTooltip: "Total anomalies per 24h driving",
                    width: 105,
                    columnGroupShow: 'open',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
            ]
        },
        {
            headerName: 'Matching KPIs',
            children: [
                {
                    field: 'reactive_open_pct',
                    headerName: 'Reactive Open %',
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'reactive_matched_pct',
                    headerName: 'Reactive Match %',
                    headerTooltip: "Percentage of matched reactive tickets",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                    cellStyle: params => positiveCellColor(params, 100, 50)
                },
                {
                    field: 'reactive_no_match_pct',
                    headerName: 'Reactive No Match %',
                    headerTooltip: "Percentage of reactive tickets without a match",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'reactive_scope_pct',
                    headerName: 'Reactive Out of scope %',
                    headerTooltip: "Percentage of reactive tickets out of scope",
                    floatingFilter: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: percentageRenderer,
                },
                {
                    field: 'reactive_monthly',
                    headerName: 'Reactive Alert Monthly',
                    headerTooltip: "Total reactive alerts per month",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'reactive_vehicle_monthly',
                    headerName: 'Reactive Alert Rate',
                    headerTooltip: "Total reactive alerts per driving vehicle per month",
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: twoDigitRenderer,
                },
                {
                    field: 'reactive_vehicle_monthly_plot',
                    headerName: 'Reactive Alert Trend',
                    headerTooltip: "Plot total reactive alerts per month per driving vehicle",
                    hide: true,
                    columnGroupShow: 'open',
                    cellRenderer: 'agSparklineCellRenderer',
                    cellRendererParams: {
                        sparklineOptions: {
                            type: 'column',
                            highlightStyle: {
                                fill: 'orange', // sets the highlighted column fill
                            },
                            tooltip: {
                                renderer: tooltipRenderer
                            }
                        }
                    },
                },
            ]
        },
    ]
}