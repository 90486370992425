import React, {useEffect, useState} from 'react';
import {Button, Popover, Input, Checkbox, Select, Col, notification, Row, Divider, Radio, Descriptions} from 'antd';
import axiosInstance from "../../services/axios";
import {useAuth} from "react-oidc-context";

const ViewManager = (props) => {
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [existingViews, setExistingViews] = useState([]);
    const [viewMode, setViewMode] = useState('add');
    const [viewName, setViewName] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [creator, setCreator] = useState(null);
    const [modified, setModified] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [isInitRun, setIsInitRun] = useState(true)

    const auth = useAuth();
    const gridRef = props.gridRef

    useEffect(() => {
        if (existingViews.length > 0) {
            if (props.viewId && isInitRun) {
                handleViewSelect(props.viewId)
                setIsInitRun(false)
            }
        }
    }, [props.viewId, existingViews]);

    useEffect(() => {
        if (popoverVisible || props.viewId) {
            const payload = {};
            axiosInstance.post('/api/monitoring/view/list', payload)
                .then(res => {
                    setExistingViews(res.data.data)
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Failed loading view list',
                        description: error.message,
                        duration: 5,
                    });
                });
        }

    }, [popoverVisible, props.viewId]);

    const onSaveView = () => {

        let columnState = gridRef.current.columnApi.getColumnState();

        // Create a separate object to store the active sorted columns
        let sortModel = {}
        sortModel['columns'] = columnState
            .filter(column => column.sort) // Filter to get only sorted columns
            .map(column => ({
                colId: column.colId,  // Store the column ID
                sort: column.sort,     // Store the sort direction (asc/desc)
                sortIndex: column.sortIndex // Store the sort index if needed
            }));
        // console.log(sortModel);

        // Set the sort key to null for all columns
        let columnModel = {}
        columnModel['columns'] = columnState.map(column => ({
            ...column,
            sort: null,
            sortIndex: null // If you want to reset the sort index as well
        }));
        // console.log(columnState)

        let filterModel = {}
        filterModel['columns'] = gridRef.current.api.getFilterModel()
        filterModel['filter_date'] = {
            queryStartDate: props.queryStartDate,
            queryEndDate: props.queryEndDate,
        }
        filterModel['filter_values'] = props.filterValues
        // console.log(filterModel)

        let groupModel = {}
        groupModel['columnGroupBy'] = gridRef.current.columnApi.getColumnGroupState();
        groupModel['rowGroupBy'] = props.rowGroupBy
        // console.log(groupModel)

        const payload = {
            sortModel: sortModel,
            columnModel: columnModel,
            filterModel: filterModel,
            groupModel: groupModel,
            viewMode: viewMode,
            viewName: viewName,
            isPublic: isPublic,
            viewId: selectedView?.id || null,
        };
        console.log(payload)
        axiosInstance.post('/api/monitoring/view/save', payload)
            .then(res => {

                if (viewMode === 'edit') {
                    notification['success']({
                        message: 'Edited view successfully.',
                        duration: 5,
                    });
                } else {
                    notification['success']({
                        message: 'Saved new view.',
                        duration: 5,
                    });
                }

                setPopoverVisible(false); // Close the popover after saving
                setViewMode('edit')
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error saving custom table view',
                    description: error.message,
                    duration: 5,
                });
            });
    };

    const handleSave = () => {
        onSaveView()
    };

    const handleNew = () => {
        setViewName(''); // Clear input fields
        setSelectedView(null);
        setViewMode('add')
        setCreator(null)
        setModified(null)
    };

    const setUrlParams = (params) => {
        const currentParams = new URLSearchParams(window.location.search);

        // Update URL parameters with new values
        Object.keys(params).forEach((key) => {
            if (params[key] !== undefined && params[key] !== null) {
                currentParams.set(key, params[key]);
            } else {
                currentParams.delete(key);  // Remove param if the value is null or undefined
            }
        });

        // Update the browser's URL without reloading the page
        window.history.replaceState({}, '', `${window.location.pathname}?${currentParams.toString()}`);
    };

    const handleViewSelect = (value) => {
        const view = existingViews.find(view => view.id === value);

        setViewMode('edit')
        setSelectedView(view);
        setViewName(view?.name || '');
        setIsPublic(view?.public || false);
        setCreator(view?.email || null)
        setModified(view?.modified_datetime || null)

        const payload = {
            viewId: view?.id
        };
        axiosInstance.post('/api/monitoring/view/load', payload)
            .then(res => {
                console.log(res.data)
                const data = res.data.data[0]

                props.gridRef.current.columnApi.applyColumnState({state: data.column_model.columns});
                props.gridRef.current.api.setFilterModel(data.filter_model.columns);
                props.setFilterModel(data.filter_model.columns)
                props.gridRef.current.columnApi.setColumnGroupState(data.group_model.columnGroupBy)

                if (data.group_model.rowGroupBy !== props.rowGroupBy) {
                    props.onChangeGroupView(data.group_model.rowGroupBy)
                }

                if (data.filter_model.filter_values !== props.filterValues) {
                    props.handleFilterChange(data.filter_model.filter_values)
                }

                setPopoverVisible(false);
                setUrlParams({view_id: view?.id, public: view?.public})
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Failed loading view',
                    description: error.message,
                    duration: 5,
                });
            });
    };

    const popoverContent = (
        <div style={{minWidth: 300}}>
            <Row style={{marginBottom: 8}}>
                <Select
                    placeholder="Load existing view"
                    value={selectedView ? selectedView.id : undefined}
                    onChange={handleViewSelect}
                    options={existingViews.map(view => ({
                        label: `${view.name} - (${view.public ? 'Public' : 'Private'})`,
                        value: view.id,
                    }))}
                    showSearch
                    allowClear
                    style={{width: 220}}
                />
                <Button style={{marginLeft: 16}} onClick={handleNew} disabled={viewMode === 'add'}>
                    New
                </Button>
            </Row>
            <Row>
                <Descriptions bordered size={'middle'} column={1}>
                    <Descriptions.Item label="Creator">{creator}</Descriptions.Item>
                    <Descriptions.Item label="Modified">
                        {modified ? modified.slice(0, 16).replace('T', ' ') + ' UTC': ''}
                    </Descriptions.Item>
                </Descriptions>
            </Row>
            <Divider style={{marginTop: 8, marginBottom: 8}}/>
            <Row style={{marginBottom: 8}}>
                <Input
                    placeholder="Enter custom view name"
                    value={viewName}
                    onChange={(e) => setViewName(e.target.value)}
                    style={{width: 220}}
                    disabled={viewMode === 'edit' && selectedView?.user !== auth.user?.profile.sub}
                />
                <Checkbox
                    disabled={viewMode === 'edit' && selectedView?.user !== auth.user?.profile.sub}
                    checked={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                    style={{marginLeft: 16}}
                >
                    <div style={{marginTop: 4}}>
                        Public
                    </div>
                </Checkbox>
            </Row>
            <Row>
                <Button
                    type="primary"
                    onClick={handleSave}
                    disabled={!viewName || (viewMode === 'edit' && selectedView?.user !== auth.user?.profile.sub)}
                    block
                >
                    {viewMode === 'edit' ? 'Update Selected View' : 'Create New View'}
                </Button>
            </Row>
        </div>
    );

    return (
        <Col>
            <Popover
                content={popoverContent}
                title="Manage Custom View"
                trigger="click"
                placement="bottom"
                open={popoverVisible}
                onOpenChange={setPopoverVisible}
            >
                <Button onClick={() => setPopoverVisible(!popoverVisible)}>
                    Manage View
                </Button>
            </Popover>
        </Col>
    );
};

export default ViewManager;
