import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal, Image, Card
} from 'antd';
import axiosInstance from "../../services/axios";

import {FaCarCrash, FaFile, FaInfo, FaList, FaSearch} from "react-icons/fa";


const HistoryInfo = (props) => {

    const [data, setData] = useState(null);
    const [dataReload, setDataReload] = useState(0);

    useEffect(() => {
        if (props.searchValue) {
            axiosInstance.post(
                '/api/image_tool/event_logs',
                {
                    search_value: props.searchValue,
                    event_id: props.eventId,
                })
                .then(res => {
                    setData(res.data.data)
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in get event logs',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        }
    }, [props.searchValue, props.eventId, dataReload]);

    const columns = [
        {
            title: 'Context',
            dataIndex: 'operation_type',
            key: 'operation_type',
            width: 200,
        },
        {
            title: 'Operation',
            dataIndex: 'content',
            key: 'content',
            width: 550,
        },
        {
            title: 'User',
            dataIndex: 'operation_user',
            key: 'operation_user',
        },
        {
            title: 'Modified',
            dataIndex: 'modified_datetime',
            key: 'modified_datetime',
            width: 120,
            render: (value) => value?.split('T')[0] + ' ' + value?.split('T')[1]?.split('.')[0],
        },
    ];

    return (
        <Popover
            content={<Table
                columns={columns}
                dataSource={data}
                pagination={false}
                style={{maxWidth: 1100}}
                scroll={{y: 650}} // Set max height to 300px with vertical scrolling
            />}
            title="Details"
            trigger="click"
            onOpenChange={(visible) => {
                if (visible) setDataReload((prevCounter) => prevCounter + 1);
            }}
        >
            <Button icon={<FaList/>} style={{cursor: 'pointer', marginTop: 4, marginLeft: 8}}/>
        </Popover>
    );
};

export default HistoryInfo;