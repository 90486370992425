import {hasAuthParams, useAuth} from "react-oidc-context";
import {authConfig} from "../../authConfig";
import React, {useEffect} from "react";
import {AuthError} from "./AuthError";
import {useSearchParams} from "react-router-dom";


export const hasAllRoles = (auth, roles) => {
    const userRoles = auth.user?.profile.resource_access[authConfig.client_id].roles || [];
    return auth.isAuthenticated && roles.every(v => userRoles.includes(v))
}

export const hasAnyRoles = (auth, roles) => {
    const userRoles = auth.user?.profile.resource_access[authConfig.client_id].roles || [];
    return auth.isAuthenticated && roles.some(v => userRoles.includes(v))
}

export const signinRedirectCustom = (auth, searchParams) => {
    let extraQueryParams = null;
    if (searchParams && searchParams.get('kc_idp_hint')) {
        extraQueryParams = {kc_idp_hint: searchParams.get('kc_idp_hint')}
    }

    return auth.signinRedirect({
        state: window.location.pathname + window.location.search,
        extraQueryParams: extraQueryParams
    });
}

export const AuthenticatedTemplate = (props) => {

    const auth = useAuth();
    const {
        loadingComponent = null
    } = props;
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!auth.isAuthenticated) {
            signinRedirectCustom(auth, searchParams);
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);


    if (auth.isLoading) {
        return loadingComponent;
    }

    if (auth.isAuthenticated) {
        return (<>{props.children}</>);
    }
}

export const UnauthenticatedTemplate = (props) => {

    const auth = useAuth();

    return !auth.isAuthenticated && (<>{props.children}</>);

}

export const AuthorizedTemplate = (props) => {

    const auth = useAuth();
    let [searchParams, setSearchParams] = useSearchParams();
    const {children, hasAllRoles = [], hasAnyRoles = [], unauthorizedComponent = null} = props;
    const userRoles = auth.user?.profile?.resource_access?.[authConfig.client_id]?.roles || [];
    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            signinRedirectCustom(auth, searchParams);
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    if (!auth.isAuthenticated) {
        return null;
    }

    // user must be authenticated
    if (auth.isAuthenticated) {
        // console.log('User has roles' + userRoles);
        let authorized = true;
        // user has at least one role of anyRoles
        if (Array.isArray(hasAnyRoles) && hasAnyRoles.length) {
            authorized = authorized && hasAnyRoles.some(v => userRoles.includes(v));
        }
        // user has all role of hasAllRoles
        if (Array.isArray(hasAllRoles) && hasAllRoles.length) {
            authorized = authorized && hasAllRoles.every(v => userRoles.includes(v));
        }
        if (authorized) {
            return (
                <>
                    <AuthError/>
                    {children}
                </>);
        }
    }
    return unauthorizedComponent;
}