import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Button,
    Alert,
    Divider,
    Tooltip
} from 'antd';
import axiosInstance from "../../services/axios";
import TagRenderer from "../TagRendererV2/TagRendererV2";
import TagValues from "../TagValues/TagValues";
import CommentTags from "./CommentTags";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;


const EventLabels = (props) => {

    const [labelsExist, setLabelsExits] = useState(false)
    const [labelData, setLabelData] = useState({
        id: undefined,
        damages_exist: null,
        damages_visible: null,
        damages_assignable: null,
        visible_damages_detected: null,
        irrelevant_damages_exist: null,
        irrelevant_damages_detected: null,
    });

    // console.log(labelData)

    useEffect(() => {
        if (props.labelData && props.labelData.id) {
            setLabelsExits(true)
            setLabelData(
                {
                    id: props.labelData.id,
                    damages_exist: props.labelData.damages_exist,
                    damages_visible: props.labelData.damages_visible,
                    damages_assignable: props.labelData.damages_assignable,
                    visible_damages_detected: props.labelData.visible_damages_detected,
                    irrelevant_damages_exist: props.labelData.irrelevant_damages_exist,
                    irrelevant_damages_detected: props.labelData.irrelevant_damages_detected,
                }
            )
        }
    }, [props.labelData]);

    const handleUpdate = (value, key) => {
        setLabelData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const onUpdateComment = (newComment) => {
        setLabelData(prevState => ({
            ...prevState,
            comment: newComment,
        }));
    };

    const onConfirmLabels = () => {
        // console.log(labelData)
        const allNonNull = Object.values(labelData).every(value => value !== null);

        if (allNonNull) {
            axiosInstance.post(
                '/api/image_tool/labels/update',
                {
                    update: labelsExist,
                    customer: props.customer,
                    search_value: props.labelTarget,
                    label_type: 'damages',
                    update_dict: labelData
                })
                .then(res => {
                    // props.setLabelData(res.data)
                    notification['success']({
                        message: 'Damage Labels updated',
                    });
                    props.setUpdateCounter(prevCounter => prevCounter + 1);
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in updateLabels',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        } else {
            notification['error']({
                message: 'Not all labels have a value',
            });
        }
    };

    return (
        <div>
            <Row>
                <Alert message={`Label Reference - ${props.labelTarget}`} type="info" style={{height: 25, marginTop: -8}}/>
            </Row>
            <Divider style={{marginTop: 4, marginBottom: 4}}/>
            <Row>
                <Descriptions bordered size={'small'} column={1} style={{marginBottom: 16}}>
                    <Descriptions.Item label="Damages Exist" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.damages_exist}
                                     onChange={(e) => handleUpdate(e.target.value, 'damages_exist')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Damages Visible" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.damages_visible}
                                     onChange={(e) => handleUpdate(e.target.value, 'damages_visible')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Damages Assignable" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.damages_assignable}
                                     onChange={(e) => handleUpdate(e.target.value, 'damages_assignable')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Visible Damages Detected"
                                       style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.visible_damages_detected}
                                     onChange={(e) => handleUpdate(e.target.value, 'visible_damages_detected')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Irrelevant Damages Exist"
                                       style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.irrelevant_damages_exist}
                                     onChange={(e) => handleUpdate(e.target.value, 'irrelevant_damages_exist')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Irrelevant Damages Detected"
                                       style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.irrelevant_damages_detected}
                                     onChange={(e) => handleUpdate(e.target.value, 'irrelevant_damages_detected')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                </Descriptions>
            </Row>
            <Row>
                <Button onClick={onConfirmLabels} type={'primary'} style={{marginBottom: 8}}>Submit</Button>
                <Button onClick={onConfirmLabels} type={'primary'} disabled={true} style={{marginBottom: 8}}>Confirm</Button>
            </Row>
            {labelsExist ? (
                <Row>
                    <CommentTags
                        data={props.labelData?.comment}
                        id={props.labelData?.id}
                        customer={props.customer}
                        labelType={'damages'}
                        labelTarget={props.labelTarget}
                        onUpdateComment={onUpdateComment}
                    />
                </Row>
            ) : (
                <Tooltip title="Submit a label to comment">
                    <div style={{opacity: 0.5, width: 150}}>
                        <Row style={{pointerEvents: 'none'}}>
                            <CommentTags/>
                        </Row>
                    </div>
                </Tooltip>
            )}
        </div>

    );
};

export default EventLabels;