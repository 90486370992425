import React, {useEffect, useState} from 'react';
import {Modal, Table} from 'antd';
import axiosInstance from "../../services/axios";

const HistoryModal = (props) => {

  const columns = [
    {
      title: 'Type',
      dataIndex: 'operation_type',
      key: 'operation_type',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'User',
      dataIndex: 'operation_user',
      key: 'operation_user',
    },
    {
      title: 'Modified Datetime',
      dataIndex: 'modified_datetime',
      key: 'modified_datetime',
    },
  ]

  const handleCancel = () => {
    props.hideModal && props.hideModal();
  };

  return (
    <>
      <Modal title="Anomaly History"
         open={props.open}
         onCancel={handleCancel}
         width={"60vw"}
         height={"90vh"}
         footer={null}
      >
          <Table
            size='small'
            columns={columns}
            dataSource={props.data}
            pagination={false}
            scroll={{ y: 680 }}
            // style={{'minWidth': '300px', 'maxWidth': '800px'}}
          />
      </Modal>
    </>
  );
};

export default HistoryModal;