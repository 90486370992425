import React from "react";
import Popup from 'reactjs-popup';
import './ImageRenderer.css';

export default class ImageRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 1,
      x: 0,
      y: 0,
      isPanning: false,
      startX: 0,
      startY: 0
    };
  }

  handleWheel = (e) => {
    if (e.deltaY < 0) {
      this.setState(prevState => ({ zoom: prevState.zoom * 1.1 }));
    } else {
      this.setState(prevState => ({ zoom: prevState.zoom / 1.1 }));
    }
  }

  handleMouseDown = (e) => {
    this.setState({
      isPanning: true,
      startX: e.clientX - this.state.x,
      startY: e.clientY - this.state.y
    });
  }

  handleMouseMove = (e) => {
    if (this.state.isPanning) {
      this.setState({
        x: e.clientX - this.state.startX,
        y: e.clientY - this.state.startY
      });
    }
  }

  handleMouseUp = () => {
    this.setState({ isPanning: false });
  }

  resetZoomAndPosition = () => {
    this.setState({
      zoom: 1,
      x: 0,
      y: 0,
      isPanning: false
    });
  }

  render() {
    if (this.props.value == null || typeof this.props.value !== "string") {
      return null;
    }

    const isVideo = this.props.value.toLowerCase().includes(".mov");
    if (isVideo) {
      return (
        <a href={this.props.value} download>
          Video
        </a>
      );
    }

    const isPDF = this.props.value.toLowerCase().includes(".pdf");
    if (isPDF) {
      return (
        <a href={this.props.value} download>
          PDF
        </a>
      );
    }

    return (
      <Popup
        trigger={
          <img
            className="small"
            src={this.props.value}
            alt={this.props.name}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        }
        modal
        nested
        position="center center"
        onOpen={this.resetZoomAndPosition}
        onClose={this.resetZoomAndPosition}
      >
        {close => (
          <div className="image-modal">
            <button className="close" onClick={() => { close(); this.resetZoomAndPosition(); }}>
              &times;
            </button>
            <div className="content">
              <img
                className="plot"
                src={this.props.value}
                alt={this.props.name}
                style={{
                  transform: `scale(${this.state.zoom}) translate(${this.state.x}px, ${this.state.y}px)`,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  cursor: this.state.isPanning ? 'grabbing' : 'grab'
                }}
                onWheel={this.handleWheel}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onMouseLeave={this.handleMouseUp}
              />
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
