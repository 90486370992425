import React, {useState, useRef, useCallback, useEffect} from 'react';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import {LicenseManager} from 'ag-grid-enterprise';

import {
    Button,
    Card,
    InputNumber,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Divider,
    Dropdown,
    Radio,
    Popover,
    message
} from 'antd';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Icon, {DatabaseOutlined, FilterOutlined, NumberOutlined} from "@ant-design/icons";
import {FaShapes} from 'react-icons/fa';
import axiosInstance from "services/axios";
import PlotRenderer from "../PlotRenderer/PlotRenderer";
import {AnomalyURLRenderer, dateTimeFormatter, EventURLRenderer, ReportURLRenderer} from "../CellHelpers/CellHelpers";
import MultiImageRenderer from "../MultiImageRenderer/MultiImageRenderer";
import TagRenderer from "../TagRenderer/TagRenderer";
import TagValues from "../TagValues/TagValues";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import DetailsCard from "../DetailsCard/DetailsCard";
import {useNavigate} from "react-router-dom";
import FilterForm from "../MapTool/FilterForm";

LicenseManager.setLicenseKey("CompanyName=carValoo GmbH,LicensedGroup=carValoo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-038534,SupportServicesEnd=21_February_2024_[v2]_MTcwODQ3MzYwMDAwMA==569a60f2ef339ea47d5835a5fc039c3f")

const {Search} = Input;
const {Text} = Typography;
const {Option} = Select;


const SimilarityTool = (props) => {

    const [inputNumber, setInputNumber] = useState('10');
    const [inputDatabase, setInputDatabase] = useState('anomaly-similarity-v2');
    const [loading, setLoading] = useState(false);
    const [alertLevel, setAlertLevel] = useState('prod');
    const [UAID, setUAID] = useState(null);
    const navigate = useNavigate();
    const [filterValues, setFilterValues] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [rescoreActive, setRescoreActive] = useState(false);
    const [selectedChannels, setSelectedChannels] = useState([]);

    const [selectedId, setSelectedId] = useState(null);
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const handleDetailsCardChange = useCallback((data) => setDetailCardOpen(data), []);
    const gridRef = useRef();

    let columnState = [];

    useEffect(() => {
        document.title = 'Similarity Tool';
        // extract UAID from URL path
        const path = window.location.pathname;

        if (path.includes("UAID")) {
            const parts = path.split("-");
            if (parts.length === 3 && parts[2].length === 12) {
                setUAID(path.substring(path.indexOf("UAID")));
                const payload = {
                    uaid: path.substring(path.indexOf("UAID")),
                    n_return: inputNumber,
                    database: inputDatabase,
                };

                // Manual user trigger
                getSimilarityData(payload, true)
            } else {
                notification['error']({
                    message: 'Not a valid UAID',
                    duration: 5
                });
            }
        }
    }, []);

    useEffect(() => {
        onSearchSimilarity(UAID)
    }, [alertLevel, inputDatabase]);


    // Function to update the column state
    function updateColumnState() {
        // Get the current column state
        let columnState = gridRef.current.columnApi.getColumnState();

        // Set the sort key to null for all columns
        columnState = columnState.map(column => ({
            ...column,
            sort: null,
            sortIndex: null // If you want to reset the sort index as well
        }));

        // Store the updated column state in the session storage
        localStorage.setItem("carvalooSimilarityToolColumns", JSON.stringify(columnState));
    }

    const onResetColumns = useCallback(() => {
        gridRef.current.columnApi.resetColumnState();
        console.log('column state reset');
    }, []);

    useEffect(() => {
        document.title = 'Similarity Tool';
    }, []);

    const handleRowSelection = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length) {
            setSelectedId(selectedNodes[0].data.id);
        } else {
            setSelectedId(null);
        }
    };

    const onCellValueChanged = (params) => {
        console.log(params);

        let id = params.data.id;
        let UAID = params.data.UAID

        let col = params.column.getColId();
        if (params.oldValue !== params.newValue) {
            let data = {};
            data['UAID'] = UAID
            data[col] = params.newValue;

            axiosInstance.patch('/api/damage_tool/' + id, data)
                .then(response => {
                    //params.success(response.values);
                    console.log('Update successful');
                    console.log(response);
                    console.log(params);
                    notification['success']({
                            message: 'Updated entry of Event ' + UAID,
                            duration: 2.5,
                            maxCount: 15
                        }
                    );
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error updating', description: error.message});
                })
        }
    }

    const onResetAllFilters = () => {
        gridRef.current.api.setFilterModel(null);
    }

    function getSimilarityData(payload, showLoading) {
        // Send request to python backend

        if (showLoading) {
            setLoading(true);
        }
        axiosInstance.post('/api/similarity', payload)
            .then(res => {
                if (showLoading) {
                    setLoading(false);
                }
                console.log(res.data.data)
                // Set row data based on JSON response
                gridRef.current.api.setRowData(res.data.data);

            })
            .catch(error => {
                if (showLoading) {
                    setLoading(false);
                }
                console.log(error);
                notification['error']({
                        message: error.message,
                        description: error.response.data.message,
                        duration: 10,
                    }
                );
            })
    }

    function getSimilarityChannelData(payload, showLoading) {
        // Send request to python backend

        if (showLoading) {
            setLoading(true);
        }
        axiosInstance.post('/api/similarity/channel', payload)
            .then(res => {
                if (showLoading) {
                    setLoading(false);
                }
                console.log(res.data.data)
                // Set row data based on JSON response
                gridRef.current.api.setRowData(res.data.data);

            })
            .catch(error => {
                if (showLoading) {
                    setLoading(false);
                }
                console.log(error);
                notification['error']({
                        message: error.message,
                        description: error.response.data.message,
                        duration: 10,
                    }
                );
            })
    }

    const onSearchSimilarity = (value) => {

        if (value) {
            const uaidValue = value || UAID
            const valueStr = uaidValue.trim()
            const parts = valueStr.split("-");

            if (valueStr.includes("UAID") && parts.length === 3 && parts[2].length === 12) {
                navigate(`/similarity_tool/${valueStr}`);
                const payload = {
                    uaid: uaidValue,
                    n_return: inputNumber,
                    database: inputDatabase,
                    alert_level: alertLevel,
                    filters: filterValues,
                    rescore_results: rescoreActive,
                    num_candidates_factor: 30,
                    channels: selectedChannels
                };

                // Manual user trigger
                console.log(selectedChannels)
                if (selectedChannels.length > 0 && inputDatabase === 'anomaly-similarity-v2' && alertLevel === 'prod') {
                    getSimilarityChannelData(payload, true)
                } else {
                    getSimilarityData(payload, true)
                }

            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        }

        if (value === "") {
            navigate('/similarity_tool');
            gridRef.current.api.setRowData(null);
        }
    }

    const onChangeInputNumber = (value) => {
        setInputNumber(value);
    }

    let emptyRowData = [
        {uaid: 'empty'},
    ];

    const getRowStyle = (params, highlightValues) => {
        if (params.context.UAID && params.data) {
            if (params.data.UAID === params.context.UAID) {
                return {
                    background: 'rgba(8,142,215,0.49)', // Set your desired background color for pinned row
                    // fontWeight: 'bold' // Set your desired font weight for pinned row
                };
            }
        }

        return null; // Return null for default styling
    };

    const gridOptions = {
        rowData: emptyRowData,
        rowSelection: 'multiple',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: [
            {
                field: 'id',
                hide: true,
                width: 75,
                headerName: 'Id',
                floatingFilter: false,
            },
            {
                field: 'UAID',
                headerTooltip: "Unique Anomaly ID",
                width: 240,
                headerName: 'UAID',
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                suppressFiltersToolPanel: true,
                cellRenderer: AnomalyURLRenderer,
                suppressMenu: false,
            },
            {
                field: 'similarity',
                width: 120,
                headerTooltip: "Cosine Distance Similarity",
                headerName: 'Similarity',
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'customer',
                headerName: 'Customer',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
            },
            {
                field: 'box_id',
                headerName: 'Box',
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'vehicle_type',
                headerName: 'Vehicle Type Installation',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
            },
            {
                field: 'date',
                width: 155,
                headerTooltip: "Filter by day and sort by datetime",
                hide: true,
                headerName: 'Date',
                floatingFilter: true,
                valueFormatter: dateTimeFormatter,
                filter: 'agDateColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                }
            },
            {
                field: 'velocity',
                headerName: 'Velocity',
                headerTooltip: "Velocity based on event GPS coordinates",
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'driving_situation',
                headerName: 'Model Situation',
                headerTooltip: "Model predicted driving situation",
                suppressFiltersToolPanel: true,
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
            },

            {
                field: 'wavenet_1',
                headerName: 'Wavenet Score',
                headerTooltip: "Model predicted anomaly score - wavenet_1 model",
                suppressFiltersToolPanel: true,
                width: 100,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'event_severity',
                width: 110,
                suppressFiltersToolPanel: true,
                headerName: 'Severity',
                headerTooltip: "Text severity level for event",
                floatingFilter: true,
                filter: 'agSetColumnFilter',
            },
            {
                field: 'severity',
                headerTooltip: "Model text severity level for anomaly",
                hide: true,
                suppressFiltersToolPanel: true,
                headerName: 'Model Severity',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
            },
            {
                field: 'event_direction_str',
                headerName: 'Model Direction',
                width: 125,
                hide: true,
                headerTooltip: "Model predicted event direction",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['vorne', 'vorne rechts', 'vorne links', 'hinten', 'hinten rechts', 'hinten links',
                        'rechts vorne', 'rechts', 'rechts hinten', 'links vorne', 'links', 'links hinten'],
                },
            },
            {
                field: 'damage_tag',
                suppressFiltersToolPanel: true,
                width: 140,
                hide: true,
                headerName: 'Damage',
                headerTooltip: "Description to tag the type of damage that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctDamageValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'pattern',
                suppressFiltersToolPanel: true,
                width: 140,
                hide: true,
                headerName: 'Pattern',
                headerTooltip: "Description to tag the type of signal that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctPatternValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'carPart_id',
                headerName: 'Car Part',
                width: 120,
                headerTooltip: "Visualized car part ids for damage",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                wrapText: true,
                cellRenderer: CarPartRenderer,
                autoHeight: true,
            },
            {
                field: 'comment',
                width: 150,
                suppressFiltersToolPanel: true,
                headerName: 'Comment',
                headerTooltip: "Free flowing comment - no structure",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                editable: true,
            },
            {
                field: 'matching_confidence',
                headerName: 'Confidence',
                headerTooltip: "Matching confidence: 1-4 low-high",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                editable: true,
            },
            {
                field: 'UAID_image',
                headerName: 'UAID Image',
                width: 200,
                cellRenderer: PlotRenderer,
                cellRendererParams: {'index': 0},
                autoHeight: true,
                wrapText: true,
                sortable: false,
            },
            {
                field: 'trip_image',
                headerName: 'Trip Image',
                hide: true,
                cellRenderer: PlotRenderer,
                cellRendererParams: {'index': 1},
                autoHeight: true,
                wrapText: true,
                sortable: false,
            },
            {
                field: 'event_image',
                headerName: 'Event Image',
                width: 200,
                cellRenderer: MultiImageRenderer,
                autoHeight: true,
                wrapText: true,
                sortable: false,
            },
            {
                field: 'modified_datetime',
                headerName: 'Modified UTC',
                width: 155,
                hide: true,
                headerTooltip: "Filter by day and sort by datetime",
                floatingFilter: true,
                valueFormatter: dateTimeFormatter,
                filter: 'agDateColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                }
            },
        ],
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        sideBar: {
            position: 'right',
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250
                }
            ],
        },
        onColumnVisible: (event) => {
            updateColumnState()
        },
        onColumnResized: (event) => {
            updateColumnState()
        },
        onColumnMoved: (event) => {
            updateColumnState()
        },
        onColumnPinned: (event) => {
            updateColumnState()
        },
        onRowSelected: (event) => {
            handleRowSelection()
        },
        onRowEditingStarted: (event) => {
            console.log('never called - not doing row editing');
        },
        onRowEditingStopped: (event) => {
            console.log('never called - not doing row editing');
        },
        onCellEditingStarted: (event) => {
            console.log('cellEditingStarted');
        },
        onCellEditingStopped: (event) => {
            console.log('cellEditingStopped');
        },
        onNewColumnsLoaded: (event) => {
            console.log('onNewColumnsLoaded');
            console.log(event);
        },
        onDisplayedColumnsChanged: (event) => {
            console.log('onDisplayedColumnsChanged ');
            console.log(event);
        },
        onCellValueChanged: onCellValueChanged,
        onGridReady: function () {
            // Step 1: Retrieve the column state JSON string from session storage
            const columnStateString = localStorage.getItem("carvalooSimilarityToolColumns");
            if (columnStateString) {
                // Step 2: Convert the JSON string back to an array
                const columnState = JSON.parse(columnStateString);

                // Step 3: Apply the column state to ag-Grid
                gridRef.current.columnApi.applyColumnState({state: columnState});
            }

            console.log('grid ready');
        },
    }

    const onClickMenu = ({key}) => {
        console.log(`Click on item ${key}`);
        setInputDatabase(key)
    };

    let menuItems = [
        {
            label: 'anomaly-similarity',
            key: 'anomaly-similarity',
        },
        {
            label: 'anomaly-similarity-v2',
            key: 'anomaly-similarity-v2',
        }
    ]

    const onChangeLevel = ({target: {value}}) => {
        setAlertLevel(value);
    };

    const optionsLevel = [
        {
            label: 'events',
            value: 'prod',
        },
        {
            label: 'both',
            value: 'both',
        },
        {
            label: 'dev',
            value: 'dev',
        },
    ];

    const filterSelectOptions = [
        {
            label: 'equals',
            value: 'equals',
            tooltipContent: 'Equals - include exact matches',
        },
        {
            label: 'not equals',
            value: 'not_equals',
            tooltipContent: 'Not Equals - exclude exact matches',
        },
        // {
        //     label: 'contains',
        //     value: 'contains',
        //     tooltipContent: 'Contains - include partial matches',
        // },
        // {
        //     label: 'not contains',
        //     value: 'not_contains',
        //     tooltipContent: 'Not Contains - exclude partial matches',
        // },
    ];

    const filterOptions = [
        {
            label: 'Customer',
            value: 'customer',
            example: 'example: sharenow'
        },
        {
            label: 'Box ID',
            value: 'box_id',
            example: 'example: 1243354'
        },
        {
            label: 'Vehicle Type Installation',
            value: 'vehicle_type_installation',
            example: 'example: Peugeot e208 (II)_SE [A]'
        },
        //                 {
        //     label: 'Alert Datetime',
        //     value: 'timestamp',
        //     example: 'example: 2024-08-05'
        // },
        {
            label: 'Matching Confidence',
            value: 'matching_confidence',
            example: 'example: 3'
        },
        {
            label: 'Assignment Status',
            value: 'assignment_status',
            example: 'example: proactive'
        },
        {
            label: 'Production Alert',
            value: 'alert',
            example: 'example: true'
        }
    ]

    const channels = ['a_x', 'a_y', 'a_z', 'g_x', 'g_y', 'g_z'];

    const handleFilterChange = (values) => {
        setFilterOpen(!filterOpen);

        if (values.filters.length > 0) {
            for (const filter of values.filters) {
                if (filter.field === "box") {
                    const oldValue = filter.value
                    filter.value = oldValue.replace(/^0+/, '');
                }
            }
            // setLoading(true);
            setSelectedId(null)
            setFilterActive(true)
            setFilterValues(values.filters)
        } else {
            // setLoading(true);
            setFilterActive(false)
            setFilterValues([]);
        }
    };

    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen);
    };

    const handleInputChange = (e) => {
        setUAID(e.target.value);
    };

    const handleChange = (value) => {
        if (value.length <= 3) {
            setSelectedChannels(value);
        } else {
            message.warning("You can only select up to 3 channels.");
        }
    };

    return (<>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                styles={{
                    body: {
                        backdropFilter: 'blur(3px)'
                    }
                }}
            >
                <Spin size="large"/>
            </Modal>
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                styles={{
                    body: {
                        padding: '5px',
                        display: 'flex',
                        flexDirection: "column",
                        flex: "1 1 auto"
                    }
                }}
            >
                <Row gutter={16} style={{marginBottom: "8px"}}>
                    <Col span={24}>
                        <Card styles={{body: {padding: "4px"}}}>
                            <Row gutter={[0, 0]}>
                                <Col>
                                    <Button onClick={onResetAllFilters}>Reset all filters</Button>
                                </Col>
                                <Col>
                                    <Button onClick={onResetColumns}>Reset columns</Button>
                                </Col>
                                <Divider type={'vertical'}></Divider>

                                <Button
                                    icon={<NumberOutlined/>}
                                    disabled={true}
                                >
                                </Button>
                                <span title="Return Count">
                                 <InputNumber min={1} max={99} defaultValue={10} style={{width: 60}}
                                              onChange={onChangeInputNumber}/>
                                </span>
                                <Dropdown
                                    menu={{items: menuItems, onClick: onClickMenu}}
                                    placement="bottomLeft"
                                    arrow={{pointAtCenter: true}}
                                >
                                    <Button
                                        icon={<DatabaseOutlined/>}
                                        style={{width: 190}}
                                    >
                                        {inputDatabase}
                                    </Button>
                                </Dropdown>

                                <Col>
                                    <Search
                                        value={UAID}
                                        onChange={handleInputChange}
                                        placeholder={"search for UAID similarity"}
                                        allowClear
                                        enterButton="Search"
                                        size="default"
                                        onSearch={onSearchSimilarity}
                                        style={{width: "360px"}}
                                    />
                                </Col>
                                <Divider type={'vertical'}></Divider>
                                <Radio.Group
                                    options={optionsLevel}
                                    onChange={onChangeLevel}
                                    value={alertLevel}
                                    optionType="button"
                                    style={{marginRight: 8}}
                                />
                                {/*<Select*/}
                                {/*    mode="multiple" // enables multi-selection*/}
                                {/*    style={{width: 200}}*/}
                                {/*    placeholder="Select channels"*/}
                                {/*    value={selectedChannels}*/}
                                {/*    onChange={handleChange}*/}
                                {/*    disabled={inputDatabase !== 'anomaly-similarity-v2' || alertLevel !== 'prod'}*/}
                                {/*>*/}
                                {/*    {channels.map((channel) => (*/}
                                {/*        <Option key={channel} value={channel}>*/}
                                {/*            {channel}*/}
                                {/*        </Option>*/}
                                {/*    ))}*/}
                                {/*</Select>*/}
                                <Popover
                                    trigger="click"
                                    placement="bottom"
                                    content={
                                        <FilterForm
                                            onFinish={handleFilterChange}
                                            fieldOptions={filterOptions}
                                            conditionOptions={filterSelectOptions}
                                            autoSubmit={true}
                                        >
                                        </FilterForm>}
                                    open={filterOpen}
                                >
                                    <Button
                                        onClick={handleFilterOpen}
                                        icon={<FilterOutlined/>}
                                        style={{backgroundColor: filterActive ? "lightcoral" : "white", marginTop: 0}}
                                    >
                                        Filter
                                    </Button>
                                </Popover>
                                <Switch
                                    style={{marginTop: 6, marginLeft: 8}}
                                    onChange={() => setRescoreActive(!rescoreActive)}  // Wrapping it in an arrow function
                                    checkedChildren="rescore"
                                    unCheckedChildren="rescore"
                                    checked={rescoreActive}
                                />
                                {/*<Col>*/}
                                {/*  <span title="Database">*/}
                                {/*    <Select*/}
                                {/*      defaultValue="anomaly-similarity"*/}
                                {/*      style={{ width: 120 }}*/}
                                {/*      onChange={onChangeDatabase}*/}
                                {/*      options={[*/}
                                {/*        {*/}
                                {/*          value: 'anomaly-similarity',*/}
                                {/*          label: 'default',*/}
                                {/*        },*/}
                                {/*        {*/}
                                {/*          value: 'anomaly-similarity-demo',*/}
                                {/*          label: 'demo',*/}
                                {/*        },*/}
                                {/*      ]}*/}
                                {/*    />*/}
                                {/*  </span>*/}
                                {/*</Col>*/}
                                <Col style={{marginLeft: 'auto'}}>
                                    <Switch
                                        style={{marginTop: '5px'}}
                                        onChange={handleDetailsCardChange}
                                        checkedChildren="details"
                                        unCheckedChildren="details"
                                        checked={detailCardOpen}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row className="ag-theme-alpine" style={{flex: "1 1 auto"}}>
                    <Col style={{flex: "1 1 auto"}}>
                        <AgGridReact
                            ref={gridRef}
                            getRowStyle={params => getRowStyle(params)}
                            gridOptions={gridOptions}
                            context={{UAID: UAID}}
                        />
                    </Col>
                    {(selectedId) && (detailCardOpen) &&
                        <Col>
                            <DetailsCard id={selectedId}/>
                        </Col>}
                </Row>
            </Card>
        </>

    );
}

export default SimilarityTool;
