import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Button, Card, InputNumber, Select, Col, Modal, notification, Row, Typography, Input, Spin, Switch
} from 'antd';

import Icon, {PlusCircleOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {FaInfoCircle} from 'react-icons/fa';
import AnomalyInfoCard from "./AnomalyInfoCard";
import AnomalyPredCard from "./AnomalyPredCard";
import AnomalyPlotCard from "./AnomalyPlotCard";
import AnomalyDataCard from "./AnomalyDataCard";
import AnomalyMapCard from "./AnomalyMapCard";
import AnomalyFeedbackCard from "./AnomalyFeedbackCard";
import axiosInstance from "../../services/axios";
import AnomalySimilarityCard from "./AnomalySimilarityCard";
import AnomalyIncidentCard from "./AnomalyIncidentCard";
import AnomalyReservationCard from "./AnomalyReservationCard";

const {Search} = Input;
const {Text} = Typography;


const AnomalyTool = (props) => {

    const [reload, setReload] = useState(0);
    const [UAID, setUAID] = useState(null);
    const [anomalyInfo, setAnomalyInfo] = useState(null);
    const [addAnomaly, setAddAnomaly] = useState(true);
    const [customer, setCustomer] = useState(null);
    const [anomalyType, setAnomalyType] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Anomaly Tool';
        // extract UAID from URL path
        const path = window.location.pathname;

        if (path.includes("UAID")) {
            const parts = path.split("-");
            if (parts.length === 3 && parts[2].length === 12) {
                setUAID(path.substring(path.indexOf("UAID")));
            } else {
                notification['error']({
                    message: 'Not a valid UAID',
                    duration: 5
                });
            }
        }
    }, []);

    const onSearch = (value) => {
        if (value) {
            const valueStr = value.trim()
            const parts = valueStr.split("-");
            if (valueStr.includes("UAID") && parts.length === 3 && parts[2].length === 12) {
                setUAID(valueStr)
                navigate(`/anomaly_tool/${valueStr}`);
            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        } else {
            setUAID(null)
            navigate('/anomaly_tool');
        }
    }

    const onLoad = () => {
        const payload = {
            uaid: UAID,
            customer: customer,
            anomaly_level: anomalyType
        }

        const notificationKey = 'load';

        notification['info']({
            key: notificationKey,
            message: 'Loading ' + UAID + '...',
            description: 'Please wait for this to finish...',
            duration: 0, // duration 0 means the notification will stay until manually closed
        });

        axiosInstance.post('/api/matching_tool/uaid', payload)
            .then(response => {
                notification.destroy(notificationKey);
                setReload(reload + 1)
                notification['success']({
                        message: 'Success loading ' + UAID,
                        duration: 2.5,
                        maxCount: 15
                    }
                );
            })
            .catch(error => {
                notification.destroy(notificationKey);
                console.log(error);
                notification['error']({message: 'Error loading ' + UAID, description: error.message});
            })
    };


    const onUpdate = () => {
        const payload = {
            uaid: UAID,
            customer: customer,
            anomaly_level: anomalyType
        }

        const notificationKey = 'update';

        notification['info']({
            key: notificationKey,
            message: 'Updating predictions for ' + UAID + '...',
            description: 'Please wait for this to finish...',
            duration: 0, // duration 0 means the notification will stay until manually closed
        });

        axiosInstance.post('/api/matching_tool/uaid_update', payload)
            .then(response => {
                notification.destroy(notificationKey);
                setReload(reload + 1)
                notification['success']({
                        message: 'Success loading ' + UAID,
                        duration: 2.5,
                        maxCount: 15
                    }
                );
            })
            .catch(error => {
                notification.destroy(notificationKey);
                console.log(error);
                notification['error']({message: 'Error updating ' + UAID, description: error.message});
            })
    };


    return (<>
            <Card
                style={{
                    width: '100%',
                    height: 58,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    marginBottom: 8
                }}
                styles={{
                    body: {
                        padding: '4px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                    }
                }}
            >
                <Col span={24}>
                    <Search
                        placeholder="search for UAID"
                        onSearch={onSearch}
                        allowClear={true}
                        size={'large'}
                        style={{
                            width: 426,
                            marginTop: 4
                        }}
                    />
                    {UAID && !addAnomaly &&
                        <>
                            <Button
                                icon={<ReloadOutlined/>}
                                size={'large'}
                                onClick={onLoad}
                                style={{
                                    width: 150,
                                    marginTop: 4
                                }}
                            >
                                Reload UAID
                            </Button>
                        </>
                    }
                    {UAID && addAnomaly &&
                        <Button
                            icon={<PlusOutlined/>}
                            size={'large'}
                            onClick={onLoad}
                            style={{
                                width: 270,
                                marginTop: 4
                            }}
                        >
                            Load UAID into Matching Tool
                        </Button>
                    }
                    <Button
                        icon={<ReloadOutlined/>}
                        size={'large'}
                        onClick={onUpdate}
                        style={{
                            width: 210,
                            marginTop: 4
                        }}
                    >
                        Run UAID Predictions
                    </Button>
                </Col>
            </Card>
            <Row gutter={8} style={{marginBottom: "8px", marginLeft: '-4px'}}>
                <Col span={8}>
                    <AnomalyInfoCard UAID={UAID} reload={reload} setCustomer={setCustomer}
                                     setAnomalyType={setAnomalyType} setAnomalyInfo={setAnomalyInfo}/>
                </Col>
                <Col span={8}>
                    <AnomalyPredCard UAID={UAID} reload={reload}/>
                </Col>
                <Col span={8}>
                    <AnomalyPlotCard UAID={UAID} reload={reload}/>
                </Col>
            </Row>
            <Row gutter={8} style={{marginBottom: "8px", marginLeft: '-4px'}}>
                <Col span={8}>
                    <AnomalyDataCard UAID={UAID} reload={reload} customer={customer} anomalyType={anomalyType}
                                     setReload={setReload}/>
                </Col>
                <Col span={8}>
                    <AnomalyMapCard customer={customer} UAID={UAID} reload={reload} anomalyInfo={anomalyInfo}/>
                </Col>
                <Col span={8}>
                    <AnomalyFeedbackCard UAID={UAID} reload={reload} setAddAnomaly={setAddAnomaly} customer={customer}
                                         anomalyInfo={anomalyInfo} setReload={setReload}/>
                </Col>
            </Row>
            <Row gutter={8} style={{marginBottom: "8px", marginLeft: '-4px'}}>
                <Col span={8}>
                    <AnomalySimilarityCard UAID={UAID} reload={reload} customer={customer} anomalyInfo={anomalyInfo}/>
                </Col>
                {customer === 'mobility' &&
                    <Col span={8}>
                        <AnomalyIncidentCard UAID={UAID} reload={reload} customer={customer} anomalyInfo={anomalyInfo}/>
                    </Col>
                }
                {/*{customer === 'mobility' &&*/}
                {/*    <Col span={8}>*/}
                {/*        <AnomalyReservationCard UAID={UAID} reload={reload} customer={customer}/>*/}
                {/*    </Col>*/}
                {/*}*/}

                {/*<Col span={8}>*/}
                {/*</Col>*/}
            </Row>
        </>
    );
}

export default AnomalyTool;
