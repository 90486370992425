import React, {useEffect, useState} from 'react';
import {Modal, Button, notification} from 'antd';
import {Form, Input, Select, DatePicker} from 'antd';
import axiosInstance from "services/axios";
import moment from 'moment';

const {Option} = Select;

const dateFormat = "YYYY-MM-DD";

function disabledEndDate(endDate, startDate) {
    if (!startDate) {
        // if the start date is not selected, disable all dates after today
        return endDate && endDate.isAfter(moment().endOf('day'));
    }
    // otherwise, disable all dates before the start date and after 1 year from the start date
    return endDate && (endDate.isBefore(startDate) || endDate.isAfter(moment().endOf('day')));
}

const MatchingToolAddModal = (props) => {

        const [startDate, setStartDate] = useState(null);
        const [endDate, setEndDate] = useState(null);

        const [form] = Form.useForm()
        const pattern = /^[0-9]*$/;

        const handleCancel = () => {
            props.hideModal && props.hideModal();
        };

        const onFinish = (data) => {
            let payload = Object.assign({}, data);
            Object.keys(payload).forEach(key => payload[key] === undefined ? delete payload[key] : {});

            payload.date_start = startDate
            payload.date_end = endDate

            console.log('Send request to create event with data', payload)
            axiosInstance.post('/api/matching_tool/add', payload)
                .then(res => {
                    const message = res.data.message
                    if (message.includes("WARNING")) {
                        notification['warning']({
                                message: res.data.message,
                                duration: 5,
                                maxCount: 15
                            }
                        );
                    } else {
                        notification['success']({
                                message: res.data.message,
                                duration: 3,
                                maxCount: 15
                            }
                        );
                    }

                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error adding events!',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                        duration: 10
                    });
                })

            form.resetFields();
            setStartDate(null)
            setEndDate(null)
            props.hideModal && props.hideModal();
        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
            setStartDate(null)
            setEndDate(null)
        };

        return (
            <>
                <Modal title="Add event to matching tool database"
                       open={props.visible}
                       onCancel={handleCancel}
                       getContainer={false}
                       forceRender={true}
                       width={"600px"}
                       footer={[
                           <Button key="cancel" onClick={handleCancel}>
                               Cancel
                           </Button>,
                           <Button
                               type="primary"
                               form="formEventData"
                               key="submit"
                               htmlType="submit">
                               Submit
                           </Button>,
                       ]}
                >
                    <Form
                        name="basic"
                        id="formEventData"
                        form={form}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 16}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Add UAID"
                            name="uaid_label"
                        >
                        </Form.Item>
                        <Form.Item
                            label="UAID(s)"
                            name="uaid"
                            rules={[
                                ({getFieldValue}) => ({
                                    validator: (_, value) => {
                                        const uaidList = value ? value.split(',').map(uaid => uaid.trim()) : [];
                                        let invalidUAIDs = [];

                                        uaidList.forEach(uaid => {
                                            const parts = uaid.split('-');
                                            if (parts.length !== 3 || parts[2].length !== 12) {
                                                invalidUAIDs.push(uaid);
                                            } else {
                                                const formattedDate = moment(parts[2], 'YYMMDDHHmmss');
                                                if (!formattedDate.isValid()) {
                                                    invalidUAIDs.push(uaid);
                                                }
                                            }
                                        });

                                        if (invalidUAIDs.length === 0) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(`Invalid UAID(s): ${invalidUAIDs.join(', ')}`);
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="UAID-1013128-221128150655"/>
                        </Form.Item>
                        <Form.Item
                            label="Box and Date"
                            name="box_label"
                        >
                        </Form.Item>
                        <Form.Item
                            label="Box"
                            name="box_id"
                            rules={[
                                {
                                    message: 'Please enter a number',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || pattern.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Please enter a number'));
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="123456"/>
                        </Form.Item>
                        <Form.Item
                            label="Start Date"
                            name="date_start"
                        >
                            <DatePicker
                                format={dateFormat}
                                disabledDate={(currentDate) =>
                                    currentDate && (currentDate > moment().endOf('day') ||
                                        (endDate && currentDate.isBefore(endDate.subtract(30, 'days')))) ||
                                    (endDate && currentDate.isAfter(endDate))
                                }
                                onChange={(selected) => {
                                    setStartDate(selected.add(12, 'hour'))
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="End Date"
                            name="date_end"
                        >
                            <DatePicker
                                format={dateFormat}
                                disabledDate={(currentDate) =>
                                    currentDate && (currentDate > moment().endOf('day') ||
                                        (startDate && currentDate.isAfter(startDate.add(30, 'days')))) ||
                                    (startDate && currentDate.isBefore(startDate.add(-13, 'hour')))
                                }
                                onChange={(selected) => {
                                    setEndDate(selected.add(12, 'hour'))
                                }}
                            />
                        </Form.Item>
                        <div style={{marginLeft: 50, fontSize: 12, marginBottom: 12}}>
                            *maximum selection range is 30 days
                        </div>
                        <Form.Item
                            label="Matching Data"
                            name="box_label"
                        >
                        </Form.Item>
                        <Form.Item
                            label="Event"
                            name="event_id"
                        >
                            <Input placeholder="anomalies will be tagged with this event"/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
            ;
    }
;

export default MatchingToolAddModal;