import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Dropdown,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Divider,
    Popover,
    Checkbox,
    Tag,
    InputNumber, Descriptions, Image, Timeline, Tooltip, Radio, Table, Tabs
} from 'antd';

const SeverityRenderer = (props) => {
    const severity = props.value;
    let color, text;

    switch (severity) {
        case 1:
            color = 'success';
            text = 'Low';
            break;
        case 2:
            color = 'warning';
            text = 'Medium';
            break;
        case 3:
            color = 'error';
            text = 'High';
            break;
        default:
            color = 'default';
            text = 'Unknown';
    }

    return <Tag color={color}>{text}</Tag>;
};

const dateFormatter = (params) => {
    if (!params.value) return '';
    // Convert to Date object in case it's a string
    const date = new Date(params.value);
    return date.toLocaleString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false  // Use 24-hour format
    }).replace(',', '');  // Remove comma between date and time
};

const customDateComparator = (date1, date2) => {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    if (parsedDate1 < parsedDate2) {
        return -1;
    } else if (parsedDate1 > parsedDate2) {
        return 1;
    } else {
        return 0;
    }
};

const customerStatusOptions = ["pending", "todo", "in_progress", "review", "done"];

function commentStyle(params) {
    return {
        'fontSize': '11px',
        'whiteSpace': 'pre-wrap',
        'lineHeight': '1.2',
    };
}

export function ImageToolRenderer(params) {
    const currentURL = window.location.href;
    let urlBase = "https://matching.carvaloo.com"
    if (currentURL.includes("localhost")) {
        urlBase = "http://localhost:3000";
    }

    return <a href={urlBase + `/image_tool/${params.data?.event_id}?customer=${params.data?.customer}`}
              target="_blank" rel="noopener noreferrer">
        {params.data?.event_id}
    </a>
}

export function ImageToolInspectionRenderer(params) {
    const currentURL = window.location.href;
    let urlBase = "https://matching.carvaloo.com"
    if (currentURL.includes("localhost")) {
        urlBase = "http://localhost:3000";
    }

    return <a href={urlBase + `/image_tool/${params.data?.inspection_id}?customer=${params.data?.customer}`}
              target="_blank" rel="noopener noreferrer">
        {params.data?.inspection_id}
    </a>
}

const CheckboxRenderer = ({value}) => {
    return (
        <Checkbox checked={value >= 1} disabled/>
    );
};

// Status cell style function
function getStatusCellStyle(params) {
    const styles = {
        in_progress: {backgroundColor: '#59c5ff'},
        review: {backgroundColor: '#eee0ac'},
        done: {backgroundColor: '#97cf88'},
        todo: {backgroundColor: '#ff9696'},
        pending: {backgroundColor: '#ffffff'},
    };
    return {
        ...styles[params.value],
        textAlign: 'center',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
}

const commonColumns = {
    customer: {
        field: 'customer',
        headerName: 'Customer',
        width: 150,
        floatingFilter: true,
        filter: 'agSetColumnFilter'
    },
    eventId: {
        field: 'event_id',
        headerName: 'Event ID',
        width: 250,
        cellRenderer: ImageToolRenderer,
        filter: 'agTextColumnFilter',
        floatingFilter: true
    },
    inspectionId: {
        field: 'inspection_id',
        headerName: 'Inspection ID',
        width: 250,
        cellRenderer: ImageToolInspectionRenderer,
        filter: 'agTextColumnFilter', floatingFilter: true
    },
    vehicleId: {
        field: 'vehicle_id',
        headerName: 'Vehicle',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        minWidth: 120,
    },
    severity: {
        field: 'intensity',
        headerName: 'Severity',
        floatingFilter: true,
        cellRenderer: SeverityRenderer,
        minWidth: 100,
        hide: true,
        cellStyle: {display: 'flex', alignItems: 'center'},
    },
    status: {
        field: 'status',
        headerName: 'Status',
        filter: 'agSetColumnFilter',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: {values: customerStatusOptions},
        cellStyle: getStatusCellStyle,
    },
    claims_ready: {
        field: 'claims_ready',
        headerName: 'Claims Ready',
        cellRenderer: CheckboxRenderer,
        floatingFilter: true,
        filter: 'agSetColumnFilter'
        // floatingFilter: true,
        // filter: 'agSetColumnFilter',
    },
    date: {
        field: 'event_ts_utc',
        headerName: 'Date',
        width: 150,
        valueFormatter: dateFormatter,
        comparator: customDateComparator,
    },
    feedbackDate: {
        field: 'latest_feedback',
        headerName: 'Last Feedback',
        width: 150,
        valueFormatter: dateFormatter,
        comparator: customDateComparator,
    },
    feedbackCount: {
        field: 'feedback_count', headerName: 'Feedbacks', width: 120, floatingFilter: true,
        filter: 'agNumberColumnFilter',
    },
    images: {
        field: 'doc_count', headerName: 'Images', width: 100, floatingFilter: true,
        filter: 'agNumberColumnFilter'
    },
    aiStatus: {
        field: 'analysis_count',
        headerName: 'AI',
        width: 60,
        hide: false,
        headerTooltip: 'AI Image Analysis processed at least once',
        cellRenderer: CheckboxRenderer,
        floatingFilter: true,
        filter: 'agSetColumnFilter'
    },
    prediction_count: {
        field: 'prediction_count',
        headerName: 'Predictions',
        width: 100,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
    },
    label_count: {
        field: 'label_count',
        headerName: 'Labels',
        width: 100,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
    },
    new_damage_count: {
        field: 'new_damage_count',
        headerName: 'AI New Damages',
        width: 100,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
    },
    new_damage_label_count: {
        field: 'new_damage_label_count',
        headerName: 'New Damages',
        width: 100,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
    },
    fn_count: {
        field: 'fn_count',
        headerName: 'Label FN',
        width: 75,
        hide: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        // floatingFilter: true,
        // filter: 'agSetColumnFilter',
    },
    editor: {
        field: 'editor',
        headerName: 'Editor',
        filter: 'agSetColumnFilter', floatingFilter: true
        // floatingFilter: true,
        // filter: 'agSetColumnFilter',
    },
    comment: {
        field: 'comment',
        headerName: 'Comment',
        width: 200,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {maxLength: 1000, rows: 10, cols: 30},
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        editable: true,
        cellStyle: commentStyle,
        floatingFilter: true
    },
};


// Exported functions for column definitions
export function getAllColumnDefs() {
    return [
        commonColumns.customer,
        commonColumns.eventId,
        commonColumns.inspectionId,
        commonColumns.vehicleId,
        commonColumns.severity,
        commonColumns.date,
        commonColumns.status,
        commonColumns.claims_ready,
        commonColumns.feedbackDate,
        commonColumns.feedbackCount,
        commonColumns.images,
        commonColumns.aiStatus,
        commonColumns.prediction_count,
        commonColumns.label_count,
        commonColumns.new_damage_count,
        commonColumns.new_damage_label_count,
        commonColumns.fn_count,
        commonColumns.editor,
        commonColumns.comment,
        // Add any additional columns specific to "All" column configuration
    ];
}

export function getProactiveColumnDefs() {
    return [
        commonColumns.customer,
        commonColumns.eventId,
        commonColumns.vehicleId,
        commonColumns.severity,
        commonColumns.date,
        commonColumns.status,
        commonColumns.claims_ready,
        commonColumns.feedbackDate,
        commonColumns.feedbackCount,
        commonColumns.images,
        commonColumns.aiStatus,
        commonColumns.prediction_count,
        commonColumns.label_count,
        commonColumns.new_damage_count,
        commonColumns.new_damage_label_count,
        commonColumns.fn_count,
        commonColumns.editor,
        commonColumns.comment,
        // Add any additional columns specific to "Proactive" column configuration
    ];
}

export function getReactiveColumnDefs() {
    return [
        commonColumns.customer,
        commonColumns.inspectionId,
        commonColumns.vehicleId,
        commonColumns.date,
        commonColumns.status,
        commonColumns.claims_ready,
        commonColumns.images,
        commonColumns.aiStatus,
        commonColumns.prediction_count,
        commonColumns.label_count,
        commonColumns.new_damage_count,
        commonColumns.new_damage_label_count,
        commonColumns.fn_count,
        commonColumns.editor,
        commonColumns.comment,
        // Add any additional columns specific to "Reactive" column configuration
    ];
}