import React from "react";
import "./CarTopViewVector.css";
import {carPartColorGetter, IconSeverity, RectSeverityArea} from "./CarPartHelpers";
import CarTopViewVector from "./CarTopViewVector";
import {carParts, carPartsGerman} from "./CarParts";

function CarPartRenderer(props) {

    const carPartCodes = props.value ? props.value.split(',') : []
    const currentParts = [];
    for (const code of carPartCodes) {
        const carPart = Object.entries(carParts).find(([key, value]) => value.codes.includes(code));
        if (carPart) {
            currentParts.push({carPart: carPart[0]});
        }
    }

    const tooltipCarParts = carPartCodes.map(code => carPartsGerman[code]);
    const tooltipCarPartsString = tooltipCarParts.join('\n');

    const severityIconHeight = "100";
    const severityIconWidth = "100";

    const hasEvent = (carPart) => {
        const data = currentParts.filter((i) => i["carPart"] === carPart);
        return data.length > 0;
    };

    return (
        <div style={{width: "90%"}}>

            {currentParts.length > 0 &&
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 816.72 360.38"
                    style={{overflow: "visible", padding: "1em 0"}}
                >
                    <title>{tooltipCarPartsString}</title>
                    {hasEvent("UNDERBODY_FRONT_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="UNDERBODY_FRONT_RIGHT"
                        >
                            <RectSeverityArea positionX={0} positionY={0}/>
                        </g>
                    )}

                    {hasEvent("UNDERBODY_FRONT_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="UNDERBODY_FRONT_LEFT"
                        >
                            <RectSeverityArea positionX={0} positionY={180}/>
                        </g>
                    )}

                    {hasEvent("UNDERBODY_MIDDLE_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="UNDERBODY_MIDDLE_RIGHT"
                        >
                            <RectSeverityArea positionX={270} positionY={0}/>
                        </g>
                    )}

                    {hasEvent("UNDERBODY_MIDDLE_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="UNDERBODY_MIDDLE_LEFT"
                        >
                            <RectSeverityArea positionX={270} positionY={180}/>
                        </g>
                    )}

                    {hasEvent("UNDERBODY_REAR_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="UNDERBODY_REAR_RIGHT"
                        >
                            <RectSeverityArea positionX={540} positionY={0}/>
                        </g>
                    )}

                    {hasEvent("UNDERBODY_REAR_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="UNDERBODY_REAR_LEFT"
                        >
                            <RectSeverityArea positionX={540} positionY={180}/>
                        </g>
                    )}

                    <CarTopViewVector/>

                    {hasEvent("TRUNK_INC_REAR_WINDSCREEN") && (
                        <g
                            className="selectableIcon"
                            id="TRUNK_INC_REAR_WINDSCREEN"
                        >
                            <path
                                className="carTopCls3"
                                d="M696.79,351.34s90,11,92,10,7.43.15,10.21-3.92c0,0-1.66,6.31,55.4,1.4a14.81,14.81,0,0,0,13.54-14.75L868,156.36a14.7,14.7,0,0,0-13.38-14.65c-18.81-1.7-51.22-3.93-55.17.45,0,0-4-8.23-102.29,7.45,0,0,33.64,99.74-.4,201.73"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="TRUNK_INC_REAR_WINDSCREEN"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "TRUNK_INC_REAR_WINDSCREEN"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "TRUNK_INC_REAR_WINDSCREEN"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="620"
                                    positionY="130"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("REAR_APRON_MID") && (
                        <g
                            className="selectableIcon"
                            id="REAR_APRON_MID"
                        >
                            <path
                                className="carTopCls3"
                                d="M869.86,199.41l33.38.39s7.66,63.91.11,96.27l-33.54.34Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="REAR-APRON"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(currentParts, "REAR_APRON_MID")}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_APRON_MID"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="720"
                                    positionY="130"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("REAR_APRON_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="REAR_APRON_RIGHT"
                        >
                            <path
                                className="carTopCls3"
                                d="M835.44,109l9.14,27.41a1.79,1.79,0,0,0,1.12,1.14c4.16,1.44,22.52,8.1,22.94,13.46s1.22,36.89,1.41,44.66a1.82,1.82,0,0,0,1.78,1.77l29.26.55a1.79,1.79,0,0,0,1.83-1.93c-.84-11.1-5.74-65.48-20.88-73.43-16.09-8.46-23.16-10.64-44.45-16A1.81,1.81,0,0,0,835.44,109Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="ROOF-2"
                                data-name="ROOF"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_APRON_RIGHT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_APRON_RIGHT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="730"
                                    positionY="0"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("REAR_APRON_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="REAR_APRON_LEFT"
                        >
                            <path
                                className="carTopCls3"
                                d="M834.31,392.6l9.17-27.4a1.81,1.81,0,0,1,1.12-1.14c4.16-1.44,22.53-8.08,23-13.44S868.81,313.73,869,306a1.8,1.8,0,0,1,1.77-1.76l29.26-.53a1.8,1.8,0,0,1,1.84,1.93c-.86,11.1-5.8,65.48-21,73.42-16.1,8.44-23.17,10.61-44.46,15.91A1.81,1.81,0,0,1,834.31,392.6Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="ROOF-3"
                                data-name="ROOF"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_APRON_LEFT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_APRON_LEFT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="730"
                                    positionY="250"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("WINDSCREEN") && (
                        <g
                            className="selectableIcon"
                            id="WINDSCREEN"
                        >
                            <g
                                id="WINDSCREEN-2"
                                data-name="WINDSCREEN"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(currentParts, "WINDSCREEN")}
                                    outSideColor={carPartColorGetter(currentParts, "WINDSCREEN")}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="230"
                                    positionY="130"
                                />
                            </g>
                            <path
                                className="carTopCls6"
                                d="M448.38,147.73s-63-20-69-19-13.67,1.4-19.34,11.2-16.68,29.78-19.72,110.78c0,0,1.19,85.95,20.07,110,0,0,6.88,12,18.88,10,0,0,46-10,69-18,0,0,5.18-.23,1.1-15.61s-10.56-84.89-9.56-87.89,4-80,12.05-96C451.88,153.23,450.88,149.23,448.38,147.73Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("SIDE_MIRROR_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="SIDE_MIRROR_RIGHT"
                        >
                            <g
                                id="SIDE-MIRROR-RIGHT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "SIDE_MIRROR_RIGHT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "SIDE_MIRROR_RIGHT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="270"
                                    positionY="-40"
                                />
                            </g>
                            <path
                                className="carTopCls6"
                                d="M365.39,113.15s29,0,25-10,16-36,25-32l4,1S407,104,412.21,111.59a56.35,56.35,0,0,1,7.18,14.54Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("FRONT_TIER_OR_WHEEL_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="FRONT_TIER_OR_WHEEL_RIGHT"
                        >
                            <path
                                className="carTopCls6"
                                d="M203.22,90.1s-12.82,12.52-26.82,18.51-31,26-31,26,30.6-9.34,96.8-15.14,122.21-7.77,122.21-7.77l-16-18s-19.5,2.49-49.5-2.52S203.22,90.1,203.22,90.1Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="FRONT_TIER_OR_WHEEL_RIGHT"
                                data-name="FRONT_TIER_OR_WHEEL_RIGHT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_TIER_OR_WHEEL_RIGHT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_TIER_OR_WHEEL_RIGHT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="100"
                                    positionY="-20"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("FRONT_TIER_OR_WHEEL_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="FRONT_TIER_OR_WHEEL_LEFT"
                        >
                            <path
                                className="carTopCls6"
                                d="M203.08,410.13s-12.81-12.54-26.81-18.54-31-26-31-26,30.59,9.37,96.79,15.22,122.2,7.88,122.2,7.88l-16,18s-19.5-2.51-49.51,2.48S203.08,410.13,203.08,410.13Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="FRONT_TIER_OR_WHEEL_LEFT"
                                data-name="FRONT_TIER_OR_WHEEL_LEFT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_TIER_OR_WHEEL_LEFT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_TIER_OR_WHEEL_LEFT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="100"
                                    positionY="290"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("REAR_TIER_OR_WHELL_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="REAR_TIER_OR_WHELL_RIGHT"
                        >
                            <path
                                className="carTopCls6"
                                d="M700.82,92l6.58,7.83s14,9,14,17c0,0,9,3,8,9s22,13,22,13l93-2-9-30s-41-13-63-14S700.82,92,700.82,92Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="REAR_TIER_OR_WHELL_RIGHT"
                                data-name="REAR_TIER_OR_WHELL_RIGHT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_TIER_OR_WHELL_RIGHT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_TIER_OR_WHELL_RIGHT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="615"
                                    positionY="-20"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("REAR_TIER_OR_WHEEL_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="REAR_TIER_OR_WHEEL_LEFT"
                        >
                            <path
                                className="carTopCls6"
                                d="M700.68,407.83l6.59-7.83s14-9,14-17c0,0,9-3,8-9s22-13,22-13l93,2-9,30s-41,13-63,14S700.68,407.83,700.68,407.83Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="REAR_TIER_OR_WHEEL_LEFT"
                                data-name="REAR_TIER_OR_WHEEL_LEFT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_TIER_OR_WHEEL_LEFT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "REAR_TIER_OR_WHEEL_LEFT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="615"
                                    positionY="280"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("SIDE_MIRROR_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="SIDE_MIRROR_LEFT"
                        >
                            <path
                                className="carTopCls6"
                                d="M364.27,387.69s29,0,25,10,16,36,25,32l4-1s-12.36-31.86-7.17-39.43a56,56,0,0,0,7.19-14.54Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="SIDE_MIRROR_LEFT"
                                data-name="SIDE_MIRROR_LEFT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "SIDE_MIRROR_LEFT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "SIDE_MIRROR_LEFT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="260"
                                    positionY="300"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("DOOR_OR_WINDOW_FRONT_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="DOOR_OR_WINDOW_FRONT_RIGHT"
                        >
                            <path
                                className="carTopCls6"
                                d="M419.39,125.72s58,13.08,83,15.06,58.82,2.27,58.82,2.27l-15.8-33.28-1-6s-7-6-7-11h-6l-2-4s-23-2-41,2-76.08,1.64-76.08,1.64-3.29,14.47-1.11,18.4S417.39,124.72,419.39,125.72Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="SIDE-MIRROR-LEFT-4"
                                data-name="SIDE-MIRROR-LEFT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_FRONT_RIGHT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_FRONT_RIGHT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="330"
                                    positionY="-20"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("DOOR_OR_WINDOW_FRONT_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="DOOR_OR_WINDOW_FRONT_LEFT"
                        >
                            <g
                                id="DOOR_OR_WINDOW_FRONT_LEFT"
                                data-name="DOOR_OR_WINDOW_FRONT_LEFT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_FRONT_LEFT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_FRONT_LEFT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="330"
                                    positionY="280"
                                />
                            </g>
                            <path
                                className="carTopCls6"
                                d="M419.28,374.22s58-13,83-15S561.1,357,561.1,357l-15.83,33.26-1,6s-7,6-7,11h-6l-2,4s-23,2-41-2-76.08-1.71-76.08-1.71-3.27-14.47-1.09-18.4S417.28,375.22,419.28,374.22Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("ENGINE_HOOD") && (
                        <g
                            className="selectableIcon"
                            id="ENGINE_HOOD"
                        >
                            <g
                                id="ENGINE-HOOD"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(currentParts, "ENGINE_HOOD")}
                                    outSideColor={carPartColorGetter(currentParts, "ENGINE_HOOD")}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="80"
                                    positionY="130"
                                />
                            </g>
                            <path
                                className="carTopCls6"
                                d="M349.41,123.36s-170,19.24-223,58.22l-.06,137.95s67,44.84,223,57.53C349.3,377.06,272.86,259.5,349.41,123.36Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("ROOF") && (
                        <g
                            className="selectableIcon"
                            id="ROOF"
                            data-name="ROOF"
                        >
                            <g
                                id="ROOF"
                                data-name="ROOF"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(currentParts, "ROOF")}
                                    outSideColor={carPartColorGetter(currentParts, "ROOF")}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="440"
                                    positionY="130"
                                />
                            </g>
                            <path
                                className="carTopCls6"
                                d="M461.88,147.24s114,18,236,1.1c0,0,31,96-1.09,203,0,0-122-15-235,1.9C461.79,353.24,429.83,252.22,461.88,147.24Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("DOOR_OR_WINDOW_REAR_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="DOOR_OR_WINDOW_REAR_RIGHT"
                        >
                            <g
                                id="DOOR_OR_WINDOW_REAR_RIGHT"
                                data-name="DOOR_OR_WINDOW_REAR_RIGHT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_REAR_RIGHT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_REAR_RIGHT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="477"
                                    positionY="-20"
                                />
                            </g>
                            <path
                                className="carTopCls6"
                                d="M536.82,91.94l163.58-.1,7.52,8.18s12.48,7.83,13.47,16.83c0,0,7.83,4.75,7.91,9.38s-27.92,8.61-27.92,8.61L674.8,138.4s-29.42,3.42-35.42,3.41-77,1-77,1-13.25-29.56-16.87-33.53l-1.61-5S535.74,94.62,536.82,91.94Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("DOOR_OR_WINDOW_REAR_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="DOOR_OR_WINDOW_REAR_LEFT"
                        >
                            <g
                                id="DOOR_OR_WINDOW_REAR_LEFT"
                                data-name="DOOR_OR_WINDOW_REAR_LEFT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_REAR_LEFT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "DOOR_OR_WINDOW_REAR_LEFT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="480"
                                    positionY="280"
                                />
                            </g>
                            <path
                                className="carTopCls6"
                                d="M536.68,407.6l163.59.24,7.52-8.17s12.48-7.82,13.49-16.82c0,0,7.83-4.74,7.92-9.37s-27.92-8.64-27.92-8.64l-26.58-3.58s-29.41-3.44-35.41-3.45-77-1-77-1S549,386.33,545.39,390.3l-1.62,5S535.6,404.92,536.68,407.6Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("FRONT_APRON") && (
                        <g
                            className="selectableIcon"
                            id="FRONT_APRON"
                        >
                            <g
                                id="FRONT-APRON"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(currentParts, "FRONT_APRON")}
                                    outSideColor={carPartColorGetter(currentParts, "FRONT_APRON")}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="-35"
                                    positionY="135"
                                />
                            </g>
                            <path
                                className="carTopCls3"
                                d="M103.58,183.93l16.8-7.34-.16,147-16.41-7.07s-8.43-8.08-11.7-51.13,8.37-78.28,8.37-78.28Z"
                                transform="translate(-91.04 -70.25)"
                            />
                        </g>
                    )}
                    {hasEvent("FRONT_HEADLIGHT_RIGHT") && (
                        <g
                            className="selectableIcon"
                            id="FRONT_HEADLIGHT_RIGHT"
                        >
                            <path
                                className="carTopCls3"
                                d="M101.47,183.05l18.92-15s8.42-10.74,11.45-14.35,17.88-30.86,47.47-46.72c0,0,18.59-9.87,23.59-16.87,0,0-28.56,1.31-38.28,6.65s-23.83,14.17-27.78,18.75S119.12,131.84,115,147C115,147,104.07,173,101.47,183.05Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="FRONT_HEADLIGHT_RIGHT"
                                data-name="FRONT_HEADLIGHT_RIGHT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_HEADLIGHT_RIGHT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_HEADLIGHT_RIGHT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="0"
                                    positionY="-10"
                                />
                            </g>
                        </g>
                    )}
                    {hasEvent("FRONT_HEADLIGHT_LEFT") && (
                        <g
                            className="selectableIcon"
                            id="FRONT_HEADLIGHT_LEFT"
                        >
                            <path
                                className="carTopCls3"
                                d="M102.41,317.08l18.91,15s8.41,10.75,11.44,14.36,17.85,30.88,47.43,46.76c0,0,18.58,9.89,23.57,16.89,0,0-28.56-1.34-38.28-6.68s-23.81-14.2-27.76-18.77S120,368.3,115.91,353.18C115.91,353.18,105,327.11,102.41,317.08Z"
                                transform="translate(-91.04 -70.25)"
                            />
                            <g
                                id="FRONT_HEADLIGHT_LEFT"
                                data-name="FRONT_HEADLIGHT_LEFT"
                            >
                                <IconSeverity
                                    inSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_HEADLIGHT_LEFT"
                                    )}
                                    outSideColor={carPartColorGetter(
                                        currentParts,
                                        "FRONT_HEADLIGHT_LEFT"
                                    )}
                                    height={+severityIconHeight}
                                    width={+severityIconWidth}
                                    positionX="0"
                                    positionY="260"
                                />
                            </g>
                        </g>
                    )}
                </svg>
            }
        </div>
    );
}

export default CarPartRenderer;
