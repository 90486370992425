import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal
} from 'antd';
import axiosInstance from "../../services/axios";
import TagRenderer from "../TagRendererV2/TagRendererV2";
import TagValues from "../TagValues/TagValues";
import CommentTags from "./CommentTags";
import {EditFilled} from "@ant-design/icons";
import {useAuth} from "react-oidc-context";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const carPartArray = [
    "antenna",
    "bonnet",
    "exhaust",
    "front_bumper",
    "front_bumper_left_arch_moulding",
    "front_bumper_left_moulding",
    "front_bumper_moulding",
    "front_bumper_right_arch_moulding",
    "front_bumper_right_moulding",
    "front_emblem",
    "front_left_door",
    "front_left_door_arch_moulding",
    "front_left_door_handle",
    "front_left_door_moulding",
    "front_left_fog_light",
    "front_left_fuel_flap",
    "front_left_light_cap",
    "front_left_rim",
    "front_left_towing_cap",
    "front_left_tyre",
    "front_left_window",
    "front_left_wing",
    "front_left_wing_arch_moulding",
    "front_mirror",
    "front_plate",
    "front_right_door",
    "front_right_door_arch_moulding",
    "front_right_door_handle",
    "front_right_door_moulding",
    "front_right_fog_light",
    "front_right_fuel_flap",
    "front_right_light_cap",
    "front_right_rim",
    "front_right_towing_cap",
    "front_right_tyre",
    "front_right_window",
    "front_right_wing",
    "front_right_wing_arch_moulding",
    "front_sensor",
    "front_spoiler",
    "front_spoiler_moulding",
    "grille",
    "headlamp_left_washer_cap",
    "headlamp_right_washer_cap",
    "inferior_grille",
    "left_exhaust",
    "left_headlamp",
    "left_inferior_grille",
    "left_mirror",
    "left_sill_panel",
    "lower_center_front_bumper",
    "lower_center_rear_bumper",
    "pillar",
    "rear_bumper",
    "rear_bumper_left_arch_moulding",
    "rear_bumper_left_moulding",
    "rear_bumper_moulding",
    "rear_bumper_right_arch_moulding",
    "rear_bumper_right_moulding",
    "rear_emblem",
    "rear_fog_light",
    "rear_left_door",
    "rear_left_door_arch_moulding",
    "rear_left_door_handle",
    "rear_left_door_moulding",
    "rear_left_fog_light",
    "rear_left_fuel_flap",
    "rear_left_light_cap",
    "rear_left_rim",
    "rear_left_towing_cap",
    "rear_left_tyre",
    "rear_left_wing",
    "rear_left_wing_arch_moulding",
    "rear_left_window",
    "rear_mirror",
    "rear_plate",
    "rear_right_door",
    "rear_right_door_arch_moulding",
    "rear_right_door_handle",
    "rear_right_door_moulding",
    "rear_right_fog_light",
    "rear_right_fuel_flap",
    "rear_right_light_cap",
    "rear_right_rim",
    "rear_right_towing_cap",
    "rear_right_tyre",
    "rear_right_wing",
    "rear_right_wing_arch_moulding",
    "rear_right_window",
    "rear_screen",
    "rear_spoiler",
    "rear_spoiler_moulding",
    "right_exhaust",
    "right_headlamp",
    "right_inferior_grille",
    "right_mirror",
    "right_sill_panel",
    "roof",
    "trunk",
    "upper_center_front_bumper",
    "upper_center_rear_bumper",
    "upper_left_front_bumper",
    "upper_left_rear_bumper",
    "upper_right_front_bumper",
    "upper_right_rear_bumper",
    "windscreen",
    "front_left_underbody",
    "front_right_underbody",
    "center_left_underbody",
    "center_right_underbody",
    "rear_left_underbody",
    "rear_right_underbody",
    "left_tail_lamp",
    "right_tail_lamp"
];

const CarPartLabels = (props) => {

    const [labelsExist, setLabelsExits] = useState(false)
    const [damages, setDamages] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [newDamage, setNewDamage] = useState({
        doc_id: null,
        carPart_id: null,
    });
    const [carPart, setCarPart] = useState(null);
    const [labelData, setLabelData] = useState({
        doc_id: null,
        car_part: null,
        severity: null,
        typology: null,
        image_quality: null,
        assignable: null,
        state: null,
        surface: null,
        new_damage: null,
        feedback_uuid: null,
        comment: null
    });
    const auth = useAuth();

    useEffect(() => {
        if (props.data) {

            if (carPart) {
                const carPartExists = props.data.some(item => item.carPart_id === carPart.carPart_id);
                if (!carPartExists) {
                    setCarPart(null)
                }
            }
            setDamages(props.data)
        }
    }, [props.data]);

    useEffect(() => {

        if (carPart && props.labelData && Array.isArray(props.labelData)) {

            const matchedLabel = props.labelData.find(
                label => label.car_part === carPart.carPart_id
                    && label.typology === carPart.typology
                    && label.feedback_uuid === carPart.feedback_uuid
            );

            if (matchedLabel) {
                setLabelsExits(true);
                setLabelData({
                    id: matchedLabel.id,
                    doc_id: matchedLabel.doc_id,
                    car_part: matchedLabel.car_part,
                    severity: matchedLabel.severity,
                    typology: matchedLabel.typology,
                    image_quality: matchedLabel.image_quality,
                    assignable: matchedLabel.assignable,
                    state: matchedLabel.state,
                    surface: matchedLabel.surface,
                    new_damage: matchedLabel.new_damage,
                    visible: matchedLabel.visible,
                    matching: matchedLabel.matching,
                    comment: matchedLabel.comment,
                    feedback_uuid: matchedLabel?.feedback_uuid,
                    position: matchedLabel?.position,
                });
            } else {

                setLabelsExits(false)

                if (carPart) {
                    // console.log(carPart)
                    setLabelData(prevState => ({
                        ...prevState,
                        doc_id: carPart.doc_id,
                        car_part: carPart.carPart_id,
                        severity: carPart?.severity,
                        typology: carPart?.typology,
                        surface: carPart?.surface,
                        new_damage: carPart?.new_damage,
                        image_quality: null,
                        assignable: true,
                        visible: true,
                        state: carPart?.state,
                        comment: {},
                        feedback_uuid: carPart?.userfeedback_uuid,
                        matching: null,
                        position: []
                    }));
                }
            }
        }
    }, [props.labelData, carPart, props.labelTarget]);

    const docIDOptions = props.imageData?.map(str => ({
        value: str,    // Assign the string to the value property
        label: str     // Assign the string to the label property
    }));

    const zoomDropdown = [
        {
            'value': 'both',
            'label': 'both',
        },
        {
            'value': 'distance',
            'label': 'distance',
        },
        {
            'value': 'close-up',
            'label': 'close-up',
        }
    ]

    const severityDropdown = [
        {
            'value': 'superficial',
            'label': 'superficial',
        },
        {
            'value': 'low',
            'label': 'low',
        },
        {
            'value': 'mid',
            'label': 'mid',
        },
        {
            'value': 'high',
            'label': 'high',
        }
    ]

    const typologyDropdown = [
        {
            'value': 'break_absence',
            'label': 'break_absence',
        },
        {
            'value': 'blowout',
            'label': 'blowout',
        },
        {
            'value': 'crack',
            'label': 'crack',
        },
        {
            'value': 'cutting_tear',
            'label': 'cutting_tear',
        },
        {
            'value': 'detached',
            'label': 'detached',
        },
        {
            'value': 'deformation',
            'label': 'deformation',
        },
        {
            'value': 'hail',
            'label': 'hail',
        },
        {
            'value': 'punctures',
            'label': 'punctures',
        },
        {
            'value': 'scratch',
            'label': 'scratch',
        },
    ]

    const qualityDropdown = [
        {
            'value': 'poor',
            'label': 'poor',
        },
        {
            'value': 'ok',
            'label': 'ok',
        },
        {
            'value': 'ideal',
            'label': 'ideal',
        }
    ]

    const stateDropdown = [
        {
            'value': 'tp',
            'label': 'TP',
        },
        {
            'value': 'fp',
            'label': 'FP',
        },
        {
            'value': 'fn',
            'label': 'FN',
        }
    ]

    const carPartPositions = {
        'front_bumper': ['left', 'center', 'right'],
        'rear_bumper': ['left', 'center', 'right'],
        'pillar': ['front_left', 'center_left', 'rear_left', 'front_right', 'center_right', 'rear_right'],
        'rear_right_wing': ['center', 'rear'],
        'rear_left_wing': ['center', 'rear'],
        'left_sill_panel': ['front', 'rear'],
        'right_sill_panel': ['front', 'rear'],
        'front_spoiler': ['left', 'center', 'right'],
        'front_spoiler_moulding': ['left', 'center', 'right'],
        'rear_spoiler': ['left', 'center', 'right'],
        'rear_spoiler_moulding': ['left', 'center', 'right'],
        'roof': ['front_left', 'center_left', 'rear_left', 'front_right', 'center_right', 'rear_right'],
    };

    const handleSelectCarPart = (value) => {
        setCarPart(damages?.find(item => item.key === value))
    };

    const handleAddDamage = (value, key) => {
        setNewDamage(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleUpdate = (value, key) => {
        setLabelData(prevState => {
            // If the key already exists, remove it to "deselect"
            if (prevState[key] === value) {
                const {[key]: _, ...rest} = prevState;  // Remove the key-value pair
                console.log(rest)
                return rest;
            }

            // Otherwise, update the key with the new value
            return {
                ...prevState,
                [key]: value,
            };
        });
    };

    const onUpdateComment = (newComment) => {
        setLabelData(prevState => ({
            ...prevState,
            comment: newComment,
        }));
    };

    const onAddDamage = () => {
        // console.log(newDamage)

        const requiredKeys = ["doc_id", "carPart_id", "typology"];

        // Check if any required key is missing in newDamage
        const missingKeys = requiredKeys.filter(key => !Object.hasOwn(newDamage, key));

        if (missingKeys.length > 0) {
            // Show notification if any required key is missing
            notification.warning({
                message: 'Missing Attributes',
                description: `The following attributes are missing: ${missingKeys.join(', ')}`,
                duration: 3,
            });
            return; // Exit the function if keys are missing
        }

        setLabelsExits(false)
        setDamages(prevItems => [...prevItems, {
            doc_id: newDamage.doc_id,
            carPart_id: newDamage.carPart_id,
            typology: newDamage.typology,
            id: 99999999
        }]);
        setCarPart({
            doc_id: newDamage.doc_id,
            carPart_id: newDamage.carPart_id,
            typology: newDamage.typology,
            added: true,
            state: 'fn',
        })
    };

    const onConfirmLabels = () => {
        console.log(labelData)
        const allNonNull = Object.entries(labelData)
            .filter(([key]) => key !== 'feedback_uuid')
            .filter(([key]) => key !== 'matching')// Exclude feedback_uuid from check
            .filter(([key]) => key !== 'position')// Exclude feedback_uuid from check
            // .filter(([key]) => key !== 'surface')// Exclude feedback_uuid from check
            .every(([, value]) => value !== null && value !== undefined);        // Check if all other values are non-null

        if (allNonNull) {
            axiosInstance.post(
                '/api/image_tool/labels/update',
                {
                    update: labelsExist,
                    customer: props.customer,
                    search_value: props.searchValue,
                    label_type: 'carparts',
                    search_type: props.searchType,
                    update_dict: labelData,
                    feedback: props.labelFeedback ? props.labelTarget : null,
                })
                .then(res => {
                    if (res.data.push_label.success) {
                        notification['success']({
                            message: 'CarPart Labels updated',
                        });
                        props.setUpdateCounter(prevCounter => prevCounter + 1);
                    } else {
                        notification['error']({
                            message: 'Error pushing CarPart Labels',
                            description: res.data.push_label.message,
                        });
                        console.log(res.data.push_label)
                    }
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in updateLabels',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        } else {
            notification['error']({
                message: 'Not all labels have a value',
            });
        }
    };

    const onClickDelete = () => {
        setShowDelete(true)
    }

    const handleCancel = () => {
        setShowDelete(false)
    };

    const onDeleteLabels = () => {
        console.log(labelData)

        axiosInstance.post(
            '/api/image_tool/labels/delete',
            {
                update: labelsExist,
                customer: props.customer,
                search_value: props.labelTarget,
                label_type: 'carparts',
                update_dict: labelData
            })
            .then(res => {
                // props.setLabelData(res.data)
                notification['success']({
                    message: 'CarPart Labels deleted',
                });
                props.setUpdateCounter(prevCounter => prevCounter + 1);
                setCarPart(null)
                setShowDelete(false)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in updateLabels',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    };

    const predefinedSizes = [
        {label: '2€ Coin (0.05 dm²)', value: 0.05},
        {label: 'Creditcard (0.5 dm²)', value: 0.5},
        {label: 'Hand (1.5 dm²)', value: 1.5},
        {label: 'A4 Paper (6 dm²)', value: 6},
        {label: 'A2 Poster (25 dm²)', value: 25},
    ];

    // console.log(carPart)
    // console.log(labelData)

    return (
        <div>
            <Modal
                title="Delete Labels"
                open={showDelete}
                onCancel={handleCancel}
                width={350}
                footer={null}
            >
                <Row justify="center" style={{marginBottom: 16}}>
                    <p>Are you sure you want to delete label for the carpart?</p>
                </Row>
                <Row justify="center">
                    <Button type="primary" onClick={onDeleteLabels} style={{marginRight: 8}}>
                        Yes
                    </Button>
                    <Button onClick={handleCancel}>
                        No
                    </Button>
                </Row>
            </Modal>
            <Row style={{marginTop: -8, marginBottom: 8}}>
                <Select
                    onChange={(value) => handleSelectCarPart(value)}
                    style={{width: 328}}
                    value={carPart ? `${carPart?.doc_id} - ${carPart?.carPart_id} - ${carPart?.typology}` : 'Select damaged car part'}
                >
                    {damages
                        ?.filter(option => option.doc_id !== null) // Filter out options where doc_id is null
                        .map(option => (
                            <Option key={`select-${option.id}-${option.id_label}`} value={option.key}>
                                {option.doc_id} - {option.carPart_id} - {option.typology}
                            </Option>
                        ))}
                </Select>
                <Popover
                    title={'New Damage Details'}
                    trigger="click"
                    content={
                        <div style={{width: 500}}>
                            <Select
                                defaultValue={'Image'}
                                onChange={(value) => handleAddDamage(value, 'doc_id')}
                                style={{width: 90}}
                            >
                                {docIDOptions?.map(option => (
                                    <Option key={`option-image-${option.value}`} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                defaultValue={'Car Part'}
                                onChange={(value) => handleAddDamage(value, 'carPart_id')}
                                style={{width: 210}}
                                showSearch
                                placeholder="Select a car part"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {carPartArray?.map(option => {
                                    return (
                                        <Option key={`option-carpart-${option}`} value={option}>
                                            {option}
                                        </Option>
                                    );
                                })}
                            </Select>
                            <Select
                                defaultValue={'Typology'}
                                onChange={(value) => handleAddDamage(value, 'typology')}
                                style={{width: 130}}
                            >
                                {typologyDropdown.map(option => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                            <Button onClick={onAddDamage} type={'primary'}>Add</Button>
                        </div>
                    }
                    placement={'left'}
                >
                    <Button icon={<EditFilled/>}
                            style={{marginLeft: 10}}>
                        Add Damage
                    </Button>
                </Popover>
            </Row>
            {/*<Divider style={{marginTop: 4, marginBottom: 4}}/>*/}
            <Row style={{marginTop: 4}}>
                <Descriptions bordered size={'small'} column={2}>
                    {/*<Descriptions.Item label="Car Part" style={{height: '10px', padding: 4, paddingLeft: 16}}>*/}
                    {/*    {zoomDropdown.map(option => (*/}
                    {/*        <Radio.Group key={option.value} optionType="button" size={'small'}>*/}
                    {/*            <Radio value={option.value}>{option.label}</Radio>*/}
                    {/*        </Radio.Group>*/}
                    {/*    ))}*/}
                    {/*</Descriptions.Item>*/}
                    <Descriptions.Item label="Image Quality" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {qualityDropdown.map(option => (
                            <Radio.Group
                                key={option.value} optionType="button" size={'small'} value={labelData?.image_quality}
                                disabled={!carPart}
                                onChange={(e) => handleUpdate(e.target.value, 'image_quality')}
                            >
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Severity" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {severityDropdown.map(option => (
                            <Radio.Group
                                key={option.value} optionType="button" size={'small'}
                                value={labelData?.severity} disabled={!carPart}
                                onChange={(e) => handleUpdate(e.target.value, 'severity')}
                            >
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Typology" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Select
                            disabled={labelData?.typology ? true : false}
                            size={'small'}
                            value={labelData?.typology}
                            onChange={(value) => handleUpdate(value, 'typology')}
                            style={{width: 130}}
                        >
                            {typologyDropdown.map(option => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="Area" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Select
                            style={{width: 200}}
                            value={labelData?.surface}
                            onChange={(value) => handleUpdate(value, 'surface')}
                            disabled={!carPart}
                            size={'small'}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <div style={{display: 'flex', alignItems: 'center', padding: 8}}>
                                        <InputNumber
                                            min={0}
                                            max={300}
                                            step={0.1}
                                            style={{width: '100%'}}
                                            placeholder="(dm²)"
                                            onChange={(value) => handleUpdate(value, 'surface')}
                                        />
                                    </div>
                                </>
                            )}
                        >
                            {predefinedSizes.map(size => (
                                <Option key={size.value} value={size.value}>
                                    {size.label}
                                </Option>
                            ))}
                        </Select>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered size={'small'} column={3} style={{marginBottom: 0, marginTop: 4}}>
                    <Descriptions.Item label="New"
                                       style={{height: '10px', padding: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.new_damage}
                                     disabled={!carPart} onChange={(e) => handleUpdate(e.target.value, 'new_damage')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Assignable"
                                       style={{height: '10px', padding: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Radio.Group optionType="button" size={'small'}
                                     value={labelData?.assignable}
                                     disabled={!carPart}
                                     onChange={(e) => handleUpdate(e.target.value, 'assignable')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Visible"
                                       style={{height: '10px', padding: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Radio.Group optionType="button" size={'small'}
                                     value={labelData?.visible}
                                     disabled={!carPart}
                                     onChange={(e) => handleUpdate(e.target.value, 'visible')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Matching" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group
                            value={labelData?.matching}
                            optionType="button"
                            size="small"
                            disabled={!carPart}
                        >
                            {[
                                {value: true, label: 'True'},
                                {value: false, label: 'False'}
                            ].map(option => (
                                <Radio
                                    key={option.value}
                                    value={option.value}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleUpdate(option.value, 'matching');
                                    }}
                                >
                                    {option.label}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="State" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {stateDropdown.map(option => (
                            <Radio.Group key={option.value} value={labelData?.state}
                                         optionType="button" size={'small'} disabled={!carPart || (option.value === 'fn' && carPart.state !== 'fn') || (option.value !== 'fn' && carPart.state === 'fn')}
                                         onChange={(e) => handleUpdate(e.target.value, 'state')}>
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))
                        }
                    </Descriptions.Item>
                    {
                        labelData && carPartPositions[labelData.car_part] && (
                            <Descriptions.Item label="Position" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                                <Select
                                    mode="multiple"
                                    value={labelData?.position || []}
                                    size="small"
                                    style={{width: 200}}  // Increased width to accommodate multiple selections
                                    onChange={(values) => handleUpdate(values, 'position')}
                                    placeholder="Select"
                                    maxTagCount={3}  // Shows first 2 tags, then "+X"
                                    maxTagTextLength={8}  // Truncates long position names in tags
                                >
                                    {carPartPositions[labelData.car_part].map(pos => (
                                        <Select.Option key={pos} value={pos}>
                                            {pos}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Descriptions.Item>
                        )
                    }
                </Descriptions>
                {/*<Descriptions.Item label="New Damage Prediction"*/}
                {/*                   style={{height: '10px', padding: 4, paddingLeft: 16}}>*/}
                {/*    <Radio.Group optionType="button" size={'small'} disabled={!carPart}>*/}
                {/*        <Radio value={true}>True</Radio>*/}
                {/*        <Radio value={false}>False</Radio>*/}
                {/*    </Radio.Group>*/}
                {/*</Descriptions.Item>*/}
            </Row>
            <Row style={{marginTop: 12}}>
                <Button onClick={onConfirmLabels} type={'primary'}>
                    {labelsExist ? 'Update' : 'Submit'}
                </Button>
                <Button onClick={onConfirmLabels} type={'primary'} disabled={true}>
                    Confirm
                </Button>
                {carPart &&
                    <Button onClick={onClickDelete}
                            style={{color: carPart?.AI_model !== null ? null : 'red'}}
                            disabled={carPart?.AI_model !== null && !['ba0d8c42-c5b8-451f-aa81-3958df81db3b', '8dc9b7b8-e9bd-49f4-82e1-21c632b63539', '8791cdd3-cc28-4f28-8dff-be9e99254dec'].includes(auth?.user.profile.sub)}>
                        Delete
                    </Button>
                }
                {labelsExist ? (
                    <div style={{marginLeft: 16}}>
                        <CommentTags
                            data={labelData?.comment}
                            id={labelData?.id}
                            customer={props.customer}
                            labelType={'carparts'}
                            labelTarget={props.labelTarget}
                            onUpdateComment={onUpdateComment}
                        />
                    </div>

                ) : (
                    <Tooltip title="Submit a label to comment">
                        <div style={{opacity: 0.5, width: 150, pointerEvents: 'none', marginLeft: 16}}>
                            <CommentTags/>
                        </div>
                    </Tooltip>
                )}
            </Row>
        </div>
    );
};

export default CarPartLabels;