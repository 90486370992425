import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Timeline, Tag, Checkbox
} from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditFilled,
    PictureOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {FaFile, FaInfo} from "react-icons/fa";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const FeedbackInfo = (props) => {

    const feedbackData = props.feedbackData
    const labelTarget = props.labelTarget
    const onSelectFeedback = props.onSelectFeedback
    const selectedFeedbacks = props.selectedFeedbacks

    const getColorForIntensity = (intensity) => {
        switch (intensity) {
            case 3:
                return 'red';
            case 2:
                return 'orange';
            case 1:
                return 'yellow';
            case 0:
            default:
                return 'gray'; // Also handles None (null/undefined)
        }
    };

    return (
        <Card
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: 8,
                paddingBottom: 10,
                minHeight: 536,
                maxHeight: 536
            }}
            styles={{
                body: {
                    padding: '16px',
                    display: 'flex',
                    flexDirection: "column",
                    flex: "1 1 auto"
                }
            }}
            title={'Feedbacks'}
        >
            <div style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                minHeight: 100,
                maxHeight: '440px',
            }}>
                {feedbackData &&
                    <>
                        {/*<Row>*/}
                        {/*    <Text style={{fontWeight: 500, fontSize: 16}}>*/}
                        {/*        Feedbacks*/}
                        {/*    </Text>*/}
                        {/*</Row>*/}
                        <Row>
                            <Alert message={`Label Reference - ${labelTarget}`} type="info"
                                   style={{height: 25, marginTop: 8}}/>
                        </Row>
                        {feedbackData.length > 0 &&
                            <Timeline style={{marginTop: 20, marginLeft: 20, minHeight: 298}}>
                                {feedbackData.map((item) => (
                                    <Timeline.Item
                                        key={item.uuid}
                                    >
                                        <Row>
                                            <Col span={14}>
                                                <Row style={{marginBottom: 4}}>
                                                    {item.created_timestamp.replace('T', ' - ').slice(0, 21)}
                                                    <Text style={{fontWeight: 500, marginLeft: 16}}>
                                                        {item.uuid.substring(0, 8)}
                                                    </Text>
                                                </Row>
                                                <Row>
                                                    {
                                                        item.matching ?
                                                            <Tag color={'red'}>
                                                                <CheckCircleOutlined/>
                                                            </Tag>

                                                            :
                                                            <Tag color={'gray'}>
                                                                <CloseCircleOutlined/>
                                                            </Tag>
                                                    }
                                                    <Tag color={getColorForIntensity(item.intensity)}>
                                                        <WarningOutlined/>
                                                    </Tag>
                                                    <Tag color="blue">
                                                        <PictureOutlined/> {item?.doc_ids ? item?.doc_ids.length : 0}
                                                    </Tag>
                                                </Row>
                                                <Row style={{paddingRight: 24, marginTop: 4}}>
                                                    <Tooltip
                                                        title={item?.comments}
                                                        mouseEnterDelay={0.5}  // Show after 0.5 seconds
                                                        mouseLeaveDelay={0}    // Hide immediately>
                                                    >
                                                        <Text>
                                                            {'Comment: ' + (item?.comments ? (item?.comments.length > 100 ? item?.comments.substring(0, 100) + '...' : item?.comments) : 'None')}
                                                        </Text>
                                                    </Tooltip>
                                                </Row>
                                            </Col>
                                            <Col span={6} style={{marginTop: 32}}>
                                                <CarPartRenderer value={item?.carPart_id}/>
                                            </Col>
                                            <Col span={4} style={{marginTop: 54}}>
                                                <Checkbox
                                                    style={{marginLeft: 20, marginRight: 16}}
                                                    onChange={(e) => onSelectFeedback(item.uuid, e.target.checked)} // Pass item.id and checked status
                                                    checked={selectedFeedbacks?.includes(item.uuid)}
                                                />
                                            </Col>
                                        </Row>
                                        <Divider style={{marginTop: 4, marginBottom: 4}}></Divider>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        }
                        {feedbackData.length === 0 &&
                            <Row style={{
                                marginTop: 100,
                                marginBottom: 196,
                                marginLeft: 150
                            }}>
                                No data available
                            </Row>
                        }
                    </>
                }
            </div>
        </Card>
    );
};

export default FeedbackInfo;