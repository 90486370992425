import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    List,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Table,
    Divider,
    Popover,
    Tooltip
} from 'antd';

import Icon, {BorderInnerOutlined} from "@ant-design/icons";
import axiosInstance from "services/axios";
import ChartToolModal from "../ChartTool/ChartToolModal";
import ReactJson from "react-json-view";
import {FaCog, FaFile, FaList, FaRedo} from "react-icons/fa";
import TagValues from "../TagValues/TagValues.js"
import HistoryModal from "../DetailsCard/HistoryModal";
import ImageRenderer from "../ImageRenderer/ImageRenderer";

const {Title} = Typography;
const {Text} = Typography;


const AnomalySimilarityCard = (props) => {

    const UAID = props.UAID
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inputNumber, setInputNumber] = useState('9');
    const [inputDatabase, setInputDatabase] = useState('anomaly-similarity-v2');

    function getSimilarityData(payload, showLoading) {
        // Send request to python backend

        if (showLoading) {
            setLoading(true);
        }
        axiosInstance.post('/api/similarity', payload)
            .then(res => {
                if (showLoading) {
                    setLoading(false);
                }
                // Set row data based on JSON response
                console.log(res.data.data)
                setData(res.data.data.slice(1, 10))

            })
            .catch(error => {
                if (showLoading) {
                    setLoading(false);
                }
                console.log(error);
                notification['error']({
                        message: error.message,
                        description: error.response.data.message,
                        duration: 10,
                    }
                );
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            const payload = {
                uaid: UAID,
                n_return: inputNumber,
                database: inputDatabase,
                alert_level: 'prod',
                rescore_results: true,
                num_candidates_factor: 30,
            };

            getSimilarityData(payload, true);
        } else {
            setData(null)
        }
    }, [UAID, props.reload]);

    const currentURL = window.location.href;
    let urlBase = "https://matching.carvaloo.com"
    if (currentURL.includes("localhost")) {
        urlBase = "http://localhost:3000";
    }

    return (
        <>
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                title={
                <a
                    href={urlBase + '/similarity_tool/' + UAID}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{color: 'black'}}
                >
                    {'Similarity'}
                </a>
                }
                styles={{body: {padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}}
            >
                {data &&
                    <Row gutter={[16, 8]} style={{marginTop: 6, marginBottom: 8}}>
                        {data.map((item, index) => {
                            // const labelIndex = nameArray.indexOf(item.label);
                            const fillBox = true
                            const borderColor = item.event_id === null ? 'white' : item.event_id === item.UAID ? 'red' : 'blue';
                            // const backgroundColor = item.event_id === null ? 'white' : item.event_id === item.UAID ? 'red' : 'blue';
                            const tooltipText = item.event_id === null ? 'dev' : item.event_id === item.UAID ? 'proactive' : 'reactive';

                            return (
                                <Col span={8} key={index}>
                                    <div>
                                        <ImageRenderer value={item?.UAID_image} name={'uaid_image'}/>
                                    </div>
                                    <div style={{
                                        marginLeft: 16,
                                        marginRight: 16,
                                        fontSize: 8,
                                        border: `1px solid ${borderColor}`,
                                        background: "white",
                                        paddingLeft: 8,
                                        borderRadius: 10
                                    }}>
                                        <Tooltip title={tooltipText}>
                                            <a
                                                href={urlBase + '/anomaly_tool/' + item.UAID}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.UAID || ''}
                                            </a>
                                        </Tooltip>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                }
                {loading &&
                    <div className="example" style={{marginLeft: 8}}>
                        <Spin/>
                    </div>
                }
            </Card>
        </>
    );
}

export default AnomalySimilarityCard;
