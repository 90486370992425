import React, {useState, useRef, useEffect, useCallback} from "react";
import {GoogleMap, useJsApiLoader, InfoWindow, MarkerF, MarkerClusterer, Autocomplete, Polygon, DrawingManager} from "@react-google-maps/api";
import axiosInstance from "services/axios";
import {notification, List, Button, message, Typography, Input} from 'antd';
import {debounce} from 'lodash';
import InfoMarker from "./InfoMarker";
import AnomalyViewer from "./AnomalyViewer"
import markerIcon from '../../assets/icons/pin_normal.svg';
import markerIconHighlight from '../../assets/icons/pin_filled.svg';
import LoggingInfoModal from "../LoggingInfo/LoggingInfoModal";

const {Text} = Typography;

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 48.137154,
  lng: 11.576124
};

const libraries = ['places', 'drawing'];

const MapComponent = (props) => {

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBZoGSnnZux0DvKt8qvhJenQL_OxnyqMjk",
    libraries: libraries
  })

  let mapHeight = window.innerHeight - 130;
  if (props.mapHeight) {
    mapHeight = props.mapHeight
  }

  const highlightValues = props.highlightValues
  const highlightLength = props.highlightValues?.length ?? 0

  const drawingManagerRef = useRef(null);
  const [viewAnomaliesModalOpen, setViewAnomaliesModalOpen] = useState(false)
  const [currentViewAnomalies, setCurrentViewAnomalies] = useState([])
  const [searchResult, setSearchResult] = useState('')
  const [isMapMoving, setIsMapMoving] = useState(false);
  const [map, setMap] = useState(null)
  const [anomalyMarkers, setAnomalyMarkers] = useState([{id: 'test', position: {lat: 48.137154, lng: 11.576124}}])
  const [clusterPolygons, setClusterPolygons] = useState(null)
  const [clusterData, setClusterData] = useState(null)
  const [activeMarker, setActiveMarker] = useState(null);
  const [clustererKey, setClustererKey] = useState(0);
  const [isMapIdle, setIsMapIdle] = useState(false);
  const [activeCluster, setActiveCluster] = useState(null);
  const [infoPosition, setInfoPosition] = useState(null);
  const [activeClusterData, setActiveClusterData] = useState(null);
  const [activeClusterLabel, setActiveClusterLabel] = useState('');

  const clustererOptions = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  const clustererRef = useRef(null);

  useEffect(() => {
    // Update the key whenever the prop changes
    setClustererKey(prevKey => prevKey + 1);
  }, [props.isClustererActive]);

  useEffect(() => {
    if (props.anomalySearch !== null && map) {
      const bounds = new window.google.maps.LatLngBounds(props.anomalySearch.position);
      map.fitBounds(bounds);
      map.setZoom(18)
      handleActiveMarker(props.anomalySearch.id)
    }
  }, [props.anomalySearch, map]);

  const onLoad = useCallback(function callback(map) {
    if (props.anomalySearch) {
      const bounds = new window.google.maps.LatLngBounds(props.anomalySearch.position);
      map.fitBounds(bounds);
      map.setZoom(18)
      handleActiveMarker(props.anomalySearch.id)
    } else {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      map.setZoom(10)
    }
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  // const handleMapDragStart = () => {
  //   setIsMapMoving(true);
  // };
  //
  // const handleMapDragEnd = () => {
  //   setIsMapMoving(false);
  // };
  //
  // const handleMapZoomStart = () => {
  //   setIsMapMoving(true);
  // };
  //
  // const handleMapZoomEnd = () => {
  //   setIsMapMoving(false);
  // };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const updateMarkers = useCallback(() => {
    if (!map) {
      return;
    }
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast(); // LatLng of the north-east corner
    const sw = bounds.getSouthWest(); // LatLng of the south-west corner

    const request = {
      filters: props.filterValues,
      topLeft: {
        lat: ne.lat(),
        lon: sw.lng(),
      },
      bottomRight: {
        lat: sw.lat(),
        lon: ne.lng(),
      },
    };

    if (props.isAnomaliesActive) {
      // console.log("Request data with params: " + JSON.stringify(request, null, 1));
      axiosInstance.post('/api/map_tool/query', request)
        .then(res => {
          // console.log(res.data.total)
          // console.log(res.data)
          setAnomalyMarkers(res.data.positions)
          if (res.data.total >= 10000) {
            notification['warning']({
                message: "Limit reached - only showing 10000 anomalies",
                duration: 10,
                maxCount: 1
              }
            );
          }
        })
        .catch(error => {
          console.log(error);
          notification['error']({message: 'Error in get AnomaliesMap', description: error.message});
        })
    }

    if (props.isGeosimilarityActive) {
      console.log("Request data with params: " + JSON.stringify(request, null, 1));
      axiosInstance.post('/api/map_tool/geosimilarity', request)
        .then(res => {
          // console.log(res.data)
          setClusterData(res.data.clusters)
          setClusterPolygons(res.data.clusters.map(data => {
            return [
              {lat: data.point_0.lat, lng: data.point_0.lon},
              {lat: data.point_1.lat, lng: data.point_1.lon},
              {lat: data.point_2.lat, lng: data.point_2.lon},
              {lat: data.point_3.lat, lng: data.point_3.lon},
              {lat: data.point_4.lat, lng: data.point_4.lon},
              {lat: data.point_5.lat, lng: data.point_5.lon}
            ];
          }))
        })
        .catch(error => {
          console.log(error);
          notification['error']({message: 'Error in get ClustersMap', description: error.message});
        })
    }

  }, [map, props.filterValues, props.isAnomaliesActive, props.isGeosimilarityActive]);

  const debouncedUpdateMarkers = debounce(updateMarkers, 250);

  useEffect(() => {
    if (!props.isAnomaliesActive) {
      setAnomalyMarkers([])
    }
    if (!props.isGeosimilarityActive) {
      setClusterPolygons(null)
      setClusterData(null)
    }

    debouncedUpdateMarkers();
  }, [props.filterValues, props.isAnomaliesActive, props.isGeosimilarityActive]);

  const onBoundsChanged = useCallback(() => {
    debouncedUpdateMarkers();
  }, [debouncedUpdateMarkers]);

  function onLoadAutocomplete(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult.getPlace();
      console.log(place)
      if (place.geometry) {
        const newCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        map.setCenter(newCenter);
        map.setZoom(18);
      }
    } else {
      // alert("Please enter text");
    }
  }

  const handleMapIdle = () => {
    if (!isMapMoving) {
      setIsMapIdle(true);
    } else {
      setIsMapIdle(false);
    }
  };

  const handleCloseClick = useCallback(
    () => setActiveMarker(null),
    [setActiveMarker]
  );

  useEffect(() => {
    // Update the key whenever the prop changes
    if (map) {
      map.setOptions({
        styles: !props.isPOIActive
          ? [{featureType: "poi", stylers: [{visibility: "off"}]}]
          : [],
      });
    }
  }, [props.isPOIActive]);

  // if (map) {
  //   console.log(map.getZoom())
  // }

  const handlePolygonClick = (index) => {
    const centroid = {
      lat: (clusterData[index].point_0.lat + clusterData[index].point_3.lat) / 2,
      lng: (clusterData[index].point_0.lon + clusterData[index].point_3.lon) / 2
    };
    setInfoPosition(centroid);
    setActiveCluster(clusterData[index]);
    setActiveClusterLabel('')

    const request = {
      'geohash': clusterData[index].geohash
    }
    console.log("Request data with params: " + JSON.stringify(request, null, 1));
    axiosInstance.post('/api/map_tool/geosimilarity/cluster', request)
      .then(res => {
        setActiveClusterData(res.data.uaid_cluster)
        if (res.data.label) {
          setActiveClusterLabel(res.data.label.content)
        }
      })
      .catch(error => {
        console.log(error);
        notification['error']({message: 'Error in get ClusterData', description: error.message});
      })
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Copied to clipboard!');
    } catch (err) {
      message.error('Failed to copy text.');
    }
  };

  const uniqueClusters = activeClusterData
    ? [...new Set(activeClusterData.map(item => item.cluster))]
    : [];

  const handleCommentSubmit = (event) => {
    event.preventDefault();

    const request = {
      'label': event.target[0].value,
      'geohash': activeCluster.geohash
    }
    axiosInstance.post('/api/map_tool/geosimilarity/cluster/update', request)
      .then(res => {
        notification['success']({message: 'Updated comment for geohash'});
      })
      .catch(error => {
        console.log(error);
        notification['error']({message: 'Error in get ClusterData', description: error.message});
      })
  };

  const handleViewAnomalies = (uaidsForCluster) => {
    setCurrentViewAnomalies(uaidsForCluster);
    setViewAnomaliesModalOpen(true);
  };

  const handleOverlayComplete = (event) => {
    const bounds = event.overlay.getBounds();
    const ne = bounds.getNorthEast(); // LatLng of the north-east corner
    const sw = bounds.getSouthWest(); // LatLng of the south-west corner

    const selected = anomalyMarkers.filter(marker =>
      marker.position.lat >= sw.lat() &&
      marker.position.lat <= ne.lat() &&
      marker.position.lng >= sw.lng() &&
      marker.position.lng <= ne.lng()
    );

    const viewAnomalies = selected.map(item => item.id);
    handleViewAnomalies(viewAnomalies);
    // Optionally clear the rectangle after selection
    event.overlay.setMap(null);
  };

  return isLoaded ? (
    <div style={{height: mapHeight, width: '100%', marginRight: "16px"}}>
      <AnomalyViewer
        showModal={() => setViewAnomaliesModalOpen(true)}
        hideModal={() => setViewAnomaliesModalOpen(false)}
        open={viewAnomaliesModalOpen}
        uaids={currentViewAnomalies}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onBoundsChanged={onBoundsChanged}
        // onDragStart={handleMapDragStart}
        // onDragEnd={handleMapDragEnd}
        // onZoomStart={handleMapZoomStart}
        // onZoomEnd={handleMapZoomEnd}
        onIdle={handleMapIdle}
        options={{
          styles: !props.isPOIActive
            ? [{featureType: "poi", stylers: [{visibility: "off"}]}]
            : [],
        }}
      >
        <DrawingManager
          ref={drawingManagerRef}
          onOverlayComplete={handleOverlayComplete}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              drawingModes: ['rectangle']
            },
            rectangleOptions: {
              fillOpacity: 0.3,
              strokeWeight: 1,
              clickable: false,
              editable: true,
              zIndex: 1
            }
          }}
        />
        {(!props.noAutocomplete) && (<Autocomplete
          onPlaceChanged={(place) => onPlaceChanged(place)}
          onLoad={onLoadAutocomplete}
        >
          <input
            type="text"
            placeholder="Search for address"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px"
            }}
          />
        </Autocomplete>)}
        {activeCluster && (
          <InfoWindow
            position={infoPosition}
            onCloseClick={() => setActiveCluster(null)}
          >
            <div>
              {/* Render your cluster details here */}
              <Text style={{ marginTop: '4px' }} strong={true}>
                Geohash: {activeCluster.geohash}
              </Text>
              <p>Anomaly Count: {activeCluster.anomaly_count}</p>
              <p>Cluster Count: {activeCluster.cluster_count}</p>
              <p>Cluster Percentage: {Math.round(activeCluster.cluster_percentage)}%</p>

              <div style={{ marginTop: '10px', marginBottom: '10px'}}>
                <form onSubmit={handleCommentSubmit}>
                  <Input
                    type="text"
                    value={activeClusterLabel ? activeClusterLabel : ''}
                    placeholder={!activeClusterLabel ? 'Add a comment' : ''}
                    style={{ width: '74%' }}
                    onChange={e => {
                      setActiveClusterLabel(e.target.value);
                    }}
                  />
                  <Button type="primary" htmlType="submit" style={{ marginLeft: '5px' }}>
                    Submit
                  </Button>
                </form>
              </div>

              {activeClusterData && (
                <List
                  size="small"
                  header={<div>UAID List with Cluster</div>}
                  bordered
                  dataSource={activeClusterData}
                  renderItem={item => (
                    <List.Item>
                      {item.uaid} --> Cluster: {item.cluster}
                    </List.Item>
                  )}
                  style={{ height: '200px', overflowY: 'scroll' }}  // This makes it scrollable
                />)}
              {activeClusterData && (
                <div>
                  {uniqueClusters.map(cluster => (
                    <Button
                      key={cluster}
                      style={{ marginTop: '5px' }}
                      onClick={() => {
                        const uaidsForCluster = activeClusterData
                          .filter(item => item.cluster === cluster)
                          .map(item => item.uaid)
                          .join(', ');
                        copyToClipboard(uaidsForCluster);
                      }}
                    >
                      Copy UAIDs for Cluster {cluster}
                    </Button>
                  ))}
                </div>
              )}
              {activeClusterData && (
                <div>
                  {uniqueClusters.map(cluster => (
                    <Button
                      key={cluster}
                      style={{ marginTop: '5px' }}
                      onClick={() => {
                        const uaidsForCluster = activeClusterData
                          .filter(item => item.cluster === cluster)
                          .map(item => item.uaid);
                        handleViewAnomalies(uaidsForCluster);
                      }}
                    >
                      View UAIDs for Cluster {cluster}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </InfoWindow>
        )}
        {isMapIdle && clusterPolygons && (clusterPolygons.map((polygon, index) => (
          <Polygon
            path={polygon}
            key={index}
            options={{
              fillColor: "#FF0000",
              fillOpacity: 0.35,
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              clickable: true,
              zIndex: 1
            }}
            onClick={() => handlePolygonClick(index)}
          />
        )))}

        {isMapIdle && (
          <MarkerClusterer
            gridSize={120}
            maxZoom={18}
            minimumClusterSize={5}
            options={clustererOptions}
            key={clustererKey}
            ref={clustererRef}
          >
            {(clusterer) =>
              anomalyMarkers.map(({
                                    id, position, customer, box,
                                    vehicle_category, vehicle_class, vehicle_type, proactive, matching
                                  }) => {

                let iconUrl = markerIcon
                let opacity = 0.9
                let shouldHighlight = false
                if (highlightLength > 0) {
                  // Check if any of the criteria match
                  shouldHighlight = highlightValues.some((criterion) => {
                    const fieldValue = eval(criterion.field);

                    switch (criterion.condition) {
                      case "equals":
                        return fieldValue === criterion.value;
                      case "not_equals":
                        return fieldValue !== criterion.value;
                      // Add more conditions as needed

                      default:
                        return false; // Invalid condition, should not highlight
                    }
                  });

                  // Determine the icon and opacity based on the highlight condition
                  iconUrl = shouldHighlight ? markerIconHighlight : markerIcon;
                  opacity = shouldHighlight ? 0.9 : 0.5;
                }

                return (
                  <MarkerF
                    icon={{
                      url: iconUrl,
                      anchor: new window.google.maps.Point(16, 32),
                      scaledSize: new window.google.maps.Size(32, 32)
                    }}
                    opacity={opacity}
                    key={`${id}-${shouldHighlight.toString()}-${highlightLength.toString()}`}
                    position={position}
                    onClick={() => handleActiveMarker(id)}
                    clusterer={props.isClustererActive ? clusterer : null}
                  >
                    {!props.hideMarker ? (
                      activeMarker === id ? (
                        <InfoMarker
                          anchor={activeMarker}
                          id={id}
                          onCloseClick={handleCloseClick}
                          miniTooltip={props.miniTooltip || false}
                        />
                      ) : null
                    ) : null}
                  </MarkerF>
                );
              })
            }
          </MarkerClusterer>
        )}
      </GoogleMap>
    </div>
  ) : <></>
}

export default React.memo(MapComponent)