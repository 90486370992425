import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Timeline, Tag, Checkbox
} from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditFilled,
    PictureOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {FaFile, FaInfo} from "react-icons/fa";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const InspectionInfo = (props) => {

    const inspectionData = props.inspectionData
    const labelTarget = props.labelTarget

    return (
        <Card
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: 8,
                paddingBottom: 10,
                minHeight: 536
            }}
            styles={{
                body: {
                    padding: '16px',
                    display: 'flex',
                    flexDirection: "column",
                    flex: "1 1 auto"
                }
            }}
            title={'Inspection'}
        >
            {inspectionData &&
                <>
                    <Row>
                        <Alert
                            message={'Label Reference - ' + (labelTarget ? (
                                labelTarget.length > 36 ?
                                    labelTarget.substring(0, 36) + '...' :
                                    labelTarget) : 'None')
                            }
                            type="info"
                            style={{height: 25, marginTop: 8}}/>
                    </Row>
                    <Row style={{marginTop: 16}}>
                        {inspectionData.inspection_datetime?.replace('T', ' - ').slice(0, 21)}
                    </Row>
                    <Row style={{marginTop: 0}}>
                        <Text style={{fontWeight: 500, marginLeft: 0}}>
                            {inspectionData.inspection_uuid}
                        </Text>
                    </Row>
                    <Text style={{marginLeft: 0}}>
                        Reservation ID: {inspectionData.reservation_id}
                    </Text>
                    <Divider style={{marginTop: 16, marginBottom: 16}}></Divider>
                    <Text style={{marginLeft: 0}}>
                        Car Part: {inspectionData.carpart}
                    </Text>
                    <Text style={{marginLeft: 0, marginTop: 12}}>
                        Damage: {inspectionData.damage_type}
                    </Text>
                    <Row style={{paddingRight: 24, marginTop: 12}}>
                        {inspectionData?.inspection_result?.length > 150 ?
                            <Tooltip
                                title={inspectionData?.inspection_result}
                                mouseEnterDelay={0.5}  // Show after 0.5 seconds
                                mouseLeaveDelay={0}    // Hide immediately>
                            >
                                <Text>
                                    {'Comment: ' + (inspectionData?.inspection_result ? (
                                        inspectionData?.inspection_result.length > 150 ?
                                            inspectionData?.inspection_result.substring(0, 150) + '...' :
                                            inspectionData?.inspection_result) : 'None')}
                                </Text>
                            </Tooltip>
                            :
                            <Text>
                                {'Comment: ' + inspectionData?.inspection_result}
                            </Text>
                        }
                    </Row>
                    <Row style={{paddingLeft: 100, paddingRight: 100, marginTop: 16}}>
                        <CarPartRenderer value={inspectionData?.carPart_id}/>
                    </Row>
                </>
            }
            {!inspectionData === 0 &&
                <Row style={{
                    marginTop: 100,
                    marginBottom: 196,
                    marginLeft: 150
                }}>
                    No data available
                </Row>
            }
        </Card>
    )
};

export default InspectionInfo;